import { Grid, Box, Typography } from "@mui/material";
import * as React from "react";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import { LightTooltip } from "../tooltips/ToolTip";

const SMSBlacklist = (props) => {
  const { closeBlackListDialog } = props;

  const resendSMS = () => {
    closeBlackListDialog();
  };

  return (
    <Grid>
      <Stack spacing={2} mb={2} direction="row">
        <Box m={0} pt={0}>
          <Typography variant="body2" gutterBottom sx={{ mt: 1, mb: 0 }}>
            <strong>User Did Not Receive SMS?</strong>
            <br />
            <br />
            They might be in Black List. This occurs if the user has opted out
            of receiving messages either from the particular sender ID or by
            blocking all messages when they dial the opt out code.
            <br />
            <br />
            To be removed from the Black List, tell the user to follow the steps
            below:
            <br />
            <ul>
              <li>
                Dial <strong>*456*9#</strong>
              </li>
              <li>
                Then choose option <strong>5 (Marketing messages)</strong>
              </li>
              <li>
                Then choose option{" "}
                <strong>5 (Activate all promo messages)</strong>
              </li>
            </ul>
            <br />
            Then resend them the SMS.
            <br />
          </Typography>
        </Box>
      </Stack>

      <Stack spacing={2} direction="row" justifyContent="flex-end">
        <LightTooltip title="Resend default password SMS">
          <Button
            sx={{
              width: 120,
            }}
            variant="solid"
            onClick={() => {
              resendSMS();
            }}
          >
            Resend SMS
          </Button>
        </LightTooltip>
      </Stack>
    </Grid>
  );
};

export default SMSBlacklist;
