import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, IconButton } from '@mui/material'
import React from 'react'
import { createTheme } from '@mui/material/styles';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation'

const theme = createTheme();

export default function ConfirmDialog(props) {

    const { confirmDialog, setConfirmDialog } = props

    return (
        <Dialog
            open={confirmDialog.isOpen}
            PaperProps={{
                style: {
                    padding: theme.spacing(2),
                    position: 'absolute',
                    top: theme.spacing(5)
                },
            }}>
            <DialogTitle style={{ textAlign: 'center' }}>
                <IconButton disableRipple sx={{
                    bgcolor: '#ffcdd2',
                    color: '#b71c1c',
                    ':hover': {
                        bgcolor: theme.palette.secondary.light,
                        cursor: 'default',
                    },
                    '.MuiSvgIcon-root': {
                        fontSize: '4rem'
                    }
                }}>
                    <NotListedLocationIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <Typography variant="h6">
                    {confirmDialog.title}
                </Typography>
                <Typography variant="subtitle2">
                    {confirmDialog.subTitle}
                </Typography>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button variant="contained" sx={{
                    borderRadius: 2,
                    backgroundColor: "#808080",
                    padding: "7px 14px",
                    fontSize: "14px",
                    color: 'white',
                    ':hover': {
                        bgcolor: '#696969',
                        color: 'white',
                    }
                }} onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}>Cancel</Button>
                <Button color='success' sx={{
                    backgroundColor: "#43B02A",
                    color: 'white',
                    ':hover': {
                        bgcolor: '#2e7d32',
                        color: 'white',
                    }
                }} variant="contained" onClick={confirmDialog.onConfirm}>{confirmDialog.action}</Button>
            </DialogActions>
        </Dialog>
    )
}
