import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Notification from "../components/Notification";
import ConfirmDialog from "../components/ConfirmDialog";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { LightTooltip } from "../tooltips/ToolTip";
import Box from "@mui/material/Box";

const AddStation = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const { closeDialog, editStation, selectedStation } = props;

  var stationInfo = null;
  var name = null;
  var latitude = null;
  var longitude = null;
  var stationId = 0;
  var route = null;
  if (editStation) {
    stationInfo = JSON.parse(selectedStation);
    name = stationInfo.name;
    latitude = stationInfo.latitude;
    longitude = stationInfo.longitude;
    stationId = stationInfo.id;
    route = stationInfo.route;
  }

  const resetForm = (props) => {
    // clear input fields
    props.resetForm();
    setRouteKey(new Date());
  };

  const closeForm = (message) => {
    closeDialog(message);
  };

  // Routes
  const [routeOpen, setRouteOpen] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(
    editStation ? route.id : 0
  );
  const [routesLoading, setRoutesLoading] = useState(
    routeOpen && routes.length === 0
  );
  const [routeEmpty, setRouteEmpty] = useState(false);
  const [routeKey, setRouteKey] = useState("routeKey");
  const ROUTES_URL = "/api/v1/routes";
  const handleRouteChange = (event, value) => {
    setSelectedRoute(value.id);
    setRouteEmpty(false);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!routesLoading) {
      return undefined;
    }

    const getRoutes = async () => {
      try {
        const response = await axiosPrivate.get(ROUTES_URL, {
          signal: controller.signal,
        });
        isMounted && setRoutes(response.data.data);
        setRoutesLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setRoutesLoading(false);
      }
    };

    getRoutes();

    return () => {
      isMounted = false;
      controller.abort();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [routesLoading]);

  React.useEffect(() => {
    if (!routeOpen) {
      setRoutes([]);
    }
  }, [routeOpen]);

  const initialValues = editStation
    ? {
        name: name,
        routeId: selectedRoute,
        latitude: latitude,
        longitude: longitude,
      }
    : {
        name: "",
        routeId: 0,
        latitude: "",
        longitude: "",
      };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    latitude: Yup.string()
      .required("Required")
      .test("is-decimal", "Invalid latitude", (value) =>
        (value + "").match(/^\d*[.{1}\d*]\d*$/)
      ),
    longitude: Yup.string()
      .required("Required")
      .test("is-decimal", "Invalid longitude", (value) =>
        (value + "").match(/^\d*[.{1}\d*]\d*$/)
      ),
  });

  const CREATE_STATION = "/api/v1/stations/save";
  const UPDATE_STATION = "/api/v1/stations/";
  const onSubmit = async (values, props) => {
    if (selectedRoute === 0) {
      // No station picked
      setRouteEmpty(selectedRoute === 0);
      return;
    }

    props.setSubmitting(true);

    // Common
    const data = {
      name: values.name,
      routeId: selectedRoute,
      latitude: values.latitude,
      longitude: values.longitude,
    };

    try {
      const response = editStation
        ? await axiosPrivate.put(UPDATE_STATION + stationId, data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
        : await axiosPrivate.post(CREATE_STATION, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });

      console.log(response.data.message);
      props.setSubmitting(false);
      resetForm(props);
      closeForm(response.data.message);
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      const errorTitle = editStation
        ? "Failed To Update Station"
        : "Failed To Create Station";
      setConfirmDialog({
        isOpen: true,
        title: errorTitle,
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  return (
    <Grid>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Stack spacing={2} mt={3} direction="column">
              <Field
                as={TextField}
                label="Station Name"
                name="name"
                placeholder="Enter station name"
                fullWidth
                required
                helperText={<ErrorMessage name="name" />}
              />
              <Field
                as={TextField}
                label="Station Latitude"
                name="latitude"
                placeholder="Enter station latitude"
                fullWidth
                required
                helperText={<ErrorMessage name="latitude" />}
              />
              <Field
                as={TextField}
                label="Station Longitude"
                name="longitude"
                placeholder="Enter station longitude"
                fullWidth
                required
                helperText={<ErrorMessage name="longitude" />}
              />
              <Autocomplete
                id="routeId"
                fullWidth
                placeholder="Select route"
                defaultValue={route}
                onChange={handleRouteChange}
                key={routeKey}
                open={routeOpen}
                onOpen={() => {
                  setRoutesLoading(true);
                  setRouteOpen(true);
                }}
                onClose={() => {
                  setRoutesLoading(false);
                  setRouteOpen(false);
                }}
                isOptionEqualToValue={(route, value) =>
                  route.name === value.name
                }
                getOptionLabel={(route) => route.name}
                options={routes}
                loading={routesLoading}
                loadingText="Loading routes..."
                noOptionsText="No routes"
                renderOption={(props, route) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {route.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Route"
                    name="routeId"
                    placeholder="Select route"
                    error={routeEmpty}
                    helperText={routeEmpty ? "Route required" : ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {routesLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Stack>

            <Stack spacing={2} mt={3} direction="row" justifyContent="flex-end">
              <LightTooltip title="Clear form">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 8,
                    width: 120,
                    backgroundColor: "#808080",
                    padding: "7px 14px",
                    fontSize: "14px",
                    color: "white",
                    ":hover": {
                      bgcolor: "#696969",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    resetForm(props);
                  }}
                >
                  Reset
                </Button>
              </LightTooltip>

              <LightTooltip title="Submit details">
                <Button
                  loading={props.isSubmitting}
                  loadingPosition="start"
                  disabled={props.isSubmitting}
                  type="submit"
                  sx={{
                    width: 120,
                  }}
                  variant="solid"
                >
                  {props.isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </LightTooltip>
            </Stack>
          </Form>
        )}
      </Formik>
      {/* </Paper> */}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
};

export default AddStation;
