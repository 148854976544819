import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Notification from "../components/Notification";
import ConfirmDialog from "../components/ConfirmDialog";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { LightTooltip } from "../tooltips/ToolTip";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CreateSMS = (props) => {
  const { closeDialog } = props;
  const [guardians, setGuardians] = useState([]);

  const axiosPrivate = useAxiosPrivate();

  const resetForm = (props) => {
    // clear input fields
    props.resetForm();
    setGuardianKey(new Date());
    setSelectedGuardians([]);
    setGuardianEmpty(false);
  };

  const closeForm = (message) => {
    closeDialog(message);
  };

  // Guardians
  const [guardianOpen, setGuardianOpen] = useState(false);
  const [selectedGuardians, setSelectedGuardians] = useState([]);

  const [guardianLoading, setGuardianLoading] = useState(
    guardianOpen && guardians.length === 0
  );

  const [guardianEmpty, setGuardianEmpty] = useState(false);
  const [guardianKey, setGuardianKey] = useState("guardianKey");
  const GUARDIANS_URL = "/api/v1/users/role/3";
  const onGuardianChange = (event, value) => {
    setSelectedGuardians(value);
    setGuardianEmpty(value.length < 1);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!guardianLoading) {
      return undefined;
    }

    const getGuardians = async () => {
      try {
        const response = await axiosPrivate.get(GUARDIANS_URL, {
          signal: controller.signal,
        });
        isMounted && setGuardians(response.data.data);
        setGuardianLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setGuardianLoading(false);
      }
    };

    getGuardians();

    return () => {
      isMounted = false;
      controller.abort();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [guardianLoading]);

  React.useEffect(() => {
    if (!guardianOpen) {
      setGuardians([]);
    }
  }, [guardianOpen]);

  // Common
  const initialValues = {
    message: "",
    guardianIds: [],
  };

  const validationSchema = Yup.object().shape({
    message: Yup.string().required("Required"),
  });

  const CREATE_SMS = "/api/v1/sms/send";
  const onSubmit = async (values, props) => {
    // No guardian picked
    if (selectedGuardians.length < 1) {
      setGuardianEmpty(true);
      return;
    }

    props.setSubmitting(true);

    var guardianIds = [];
    const length = selectedGuardians.length;

    for (let i = 0; i < length; i++) {
      guardianIds.push(selectedGuardians[i].id);
    }

    let smsData = {
      usersIds: guardianIds,
      message: values.message,
    };

    console.log(smsData);

    try {
      const response = await axiosPrivate.post(CREATE_SMS, smsData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response.data.message);
      props.setSubmitting(false);
      resetForm(props);
      closeForm(response.data.message);
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      const errorTitle = "Failed To Send Notification";
      setConfirmDialog({
        isOpen: true,
        title: errorTitle,
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  return (
    <Grid>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Stack spacing={2} mt={3} direction="column">
              <Field
                as={TextField}
                label="Message"
                name="message"
                multiline
                inputProps={{ maxLength: 400 }}
                rows={5}
                placeholder="Enter the message"
                style={{ width: 500 }}
                required
                helperText={<ErrorMessage name="message" />}
              />

              <Autocomplete
                id="guardianIds"
                multiple
                limitTags={2}
                style={{ width: 500 }}
                label="Guardian(s)"
                placeholder="Select guardian(s)"
                onChange={onGuardianChange}
                key={guardianKey}
                open={guardianOpen}
                onOpen={() => {
                  setGuardianLoading(true);
                  setGuardianOpen(true);
                }}
                onClose={() => {
                  setGuardianLoading(false);
                  setGuardianOpen(false);
                }}
                isOptionEqualToValue={(guardian, value) =>
                  `${guardian.firstName} ${guardian.lastName} - ${guardian.phone}` ===
                  `${value.firstName} ${value.lastName} - ${value.phone}`
                }
                getOptionLabel={(guardian) =>
                  `${guardian.firstName} ${guardian.lastName}`
                }
                options={guardians}
                loading={guardianLoading}
                loadingText="Loading guardians..."
                noOptionsText="No guardians"
                disableCloseOnSelect
                renderOption={(props, guardian, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {guardian.firstName} {guardian.lastName} - {guardian.phone}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Guardian(s)"
                    placeholder="Select guardian(s)"
                    name="guardianIds"
                    error={guardianEmpty}
                    helperText={guardianEmpty ? "Guardian(s) required" : ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {guardianLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Stack>

            <Stack spacing={2} mt={3} direction="row" justifyContent="flex-end">
              <LightTooltip title="Clear form">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 8,
                    width: 120,
                    backgroundColor: "#808080",
                    padding: "7px 14px",
                    fontSize: "14px",
                    color: "white",
                    ":hover": {
                      bgcolor: "#696969",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    resetForm(props);
                  }}
                >
                  Reset
                </Button>
              </LightTooltip>

              <LightTooltip title="Send SMS">
                <Button
                  loading={props.isSubmitting}
                  loadingPosition="start"
                  disabled={props.isSubmitting}
                  type="submit"
                  sx={{
                    width: 120,
                  }}
                  variant="solid"
                >
                  {props.isSubmitting ? "Sending" : "Send"}
                </Button>
              </LightTooltip>
            </Stack>
          </Form>
        )}
      </Formik>
      {/* </Paper> */}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
};

export default CreateSMS;
