import { React, useState, useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import RDataGrid from "./reusables/RDataGrid";
import PageLoader from "./reusables/PageLoader";
import Add from "@mui/icons-material/Add";
import Button from "@mui/joy/Button";
import moment from "moment";
import { date_format, time_format } from "../constants/AppConstants";
import CreateSMS from "../forms/CreateSMS";
import ConfirmDialog from "./ConfirmDialog";
import Notification from "./Notification";
import AddPopup from "../popups/AddPopup";

const SentSMS = () => {
  const [isFetchingTrips, setIsFetchingtrips] = useState(true);
  const [trips, setTrips] = useState([]);
  const TRIP_URL = "api/v1/sent_sms";
  const axiosPrivate = useAxiosPrivate();
  const [openPopup, setOpenPopup] = useState(false);
  const [refreshSMS, setSetRefreshSMS] = useState(null);

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    if (!isFetchingTrips) {
      setIsFetchingtrips(true);
    }

    let isMounted = true;
    const controller = new AbortController();

    const getTrips = async () => {
      try {
        const response = await axiosPrivate.get(TRIP_URL, {
          signal: controller.signal,
        });
        isMounted && setTrips(response.data.data);
        setIsFetchingtrips(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setIsFetchingtrips(false);
      }
    };

    getTrips();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [refreshSMS]);

  const columns = useMemo(
    () => [
      { field: "id", headerName: "Id", width: 60 },
      { field: "number", headerName: "Phone", width: 150 },
      {
        field: "date",
        headerName: "Date",
        width: 170,
        renderCell: function (params) {
          const timestamp = params.row.date;
          var date = moment(timestamp).format(date_format);
          return date;
        },
      },
      {
        field: "time",
        headerName: "Time",
        width: 120,
        renderCell: function (params) {
          const timestamp = params.row.date;
          var time = moment(timestamp).format(time_format);
          return time;
        },
      },
      {
        field: "cost",
        headerName: "Cost",
        width: 130,
        renderCell: function (params) {
          const cost = params.row.cost;
          return cost == null ? "-" : cost;
        },
      },
      {
        field: "status",
        headerName: "Status",
        width: 200,
      },
      {
        field: "message",
        headerName: "Message",
        width: 300,
      },
      {
        field: "messageId",
        headerName: "Message Id",
        width: 350,
      },
      {
        field: "type",
        headerName: "Type",
        width: 180,
      },
    ],
    []
  );

  // Close dialog upon successful SMS creation
  const closeDialog = (message) => {
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: message,
      type: "success",
    });
    setSetRefreshSMS(new Date());
  };

  return isFetchingTrips ? (
    <PageLoader title="Loading sent SMS..." />
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box height={40} />

      {trips?.length ? (
        <Box
          sx={{
            height: 560,
            width: "100%",
          }}
        >
          <div style={divStyle}>
            <Typography
              variant="h4"
              component="h4"
              sx={{ textAlign: "left", mt: 3, mb: 3 }}
            >
              Sent SMS
            </Typography>

            <Button
              size="lg"
              variant="solid"
              color="primary"
              onClick={function () {
                setOpenPopup(true);
              }}
              startDecorator={<Add />}
            >
              New SMS
            </Button>
          </div>

          <Box sx={{ width: 1218, margin: "0 auto 16px", height: 560 }}>
            <RDataGrid columns={columns} rows={trips} rowHeight={63} />
          </Box>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <AddPopup
            width="md"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            title="Create SMS"
          >
            <CreateSMS closeDialog={closeDialog} />
          </AddPopup>
        </Box>
      ) : (
        <div style={divStyle}>
          <Typography
            variant="h3"
            component="h3"
            sx={{ textAlign: "center", mt: 3, mb: 3 }}
          >
            No SMS to display
          </Typography>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <AddPopup
            width="md"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            title="Create SMS"
          >
            <CreateSMS closeDialog={closeDialog} />
          </AddPopup>
        </div>
      )}
    </Box>
  );
};

export default SentSMS;
