import React from "react";
import { Typography, Grid, Box, CircularProgress } from "@mui/material";

export default function PageLoader(props) {
  const { title } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Grid justifyContent="center" alignItems="center">
        <CircularProgress sx={{ color: "#43B02A" }} />
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "center", mt: 2, mb: 2 }}
        >
          {title}
        </Typography>
      </Grid>
    </Box>
  );
}
