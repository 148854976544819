import { Grid, TextField } from "@mui/material";
import Button from "@mui/joy/Button";
import { React, useState } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import Notification from "../components/Notification";
import ConfirmDialog from "../components/ConfirmDialog";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import * as Yup from "yup";
import YupPassword from "yup-password";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useAuth from "../hooks/useAuth";
import Stack from "@mui/joy/Stack";
import { LightTooltip } from "../tooltips/ToolTip";

YupPassword(Yup);
const ChangePassword = (props) => {
  const { closeDialog } = props;
  const { auth } = useAuth();

  const axiosPrivate = useAxiosPrivate();
  const resetForm = (props) => {
    // clear input fields
    props.resetForm();
  };

  const closeForm = (message) => {
    closeDialog(message);
  };

  //const buttonStyle = { margin: "16px 0" };
  const passwordStyle = { margin: "20px 0" };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //let username = location.state.username;
  //let oldPassword = location.state.password;

  const initialValues = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };
  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required("Required"),
    new_password: Yup.string()
      .required("Required")
      .min(
        8,
        "New password must contain 8 or more characters with at least one of each of these: Uppercase, Lowercase, Number and Special character"
      )
      .minLowercase(1, "Password must contain at least 1 Lower case letter")
      .minUppercase(1, "Password must contain at least 1 Upper case letter")
      .minNumbers(1, "Password must contain at least 1 Number")
      .minSymbols(1, "Password must contain at least 1 Special character"),
    confirm_password: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("new_password")], "Passwords do not match"),
  });

  const CHANGE_PASSWORD_URL = "/api/v1/users/change/password";
  const onSubmit = async (values, props) => {
    props.setSubmitting(true);

    let data = {
      id: auth.id,
      oldPassword: values.current_password,
      newPassword: values.new_password,
    };

    try {
      const response = await axiosPrivate.post(CHANGE_PASSWORD_URL, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      //console.log(response.data.message);
      props.setSubmitting(false);
      resetForm(props);
      closeForm(response.data.message);
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      setConfirmDialog({
        isOpen: true,
        title: "Password Change Failed",
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  return (
    <Grid>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Stack spacing={2} mt={3} direction="column">
              <Field
                as={TextField}
                label="Current Password"
                name="current_password"
                placeholder="Enter Current password"
                style={passwordStyle}
                type={showPassword ? "text" : "password"}
                fullWidth
                required
                sx={{ width: 300 }}
                helperText={<ErrorMessage name="current_password" />}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Field
                as={TextField}
                label="New Password"
                name="new_password"
                placeholder="Enter New password"
                style={passwordStyle}
                type={showPassword ? "text" : "password"}
                fullWidth
                required
                sx={{ width: 300 }}
                helperText={<ErrorMessage name="new_password" />}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Field
                as={TextField}
                label="Confirm New Password"
                name="confirm_password"
                placeholder="Enter password"
                style={passwordStyle}
                type={showPassword ? "text" : "password"}
                fullWidth
                required
                sx={{ width: 300 }}
                helperText={<ErrorMessage name="confirm_password" />}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            {/* <Button
              type="submit"
              color="primary"
              fullWidth
              loading={props.isSubmitting}
              loadingPosition="start"
              variant="solid"
              style={buttonStyle}
            >
              {props.isSubmitting ? "Processing" : "Submit"}
            </Button> */}

            <Stack spacing={2} mt={3} direction="row" justifyContent="flex-end">
              <LightTooltip title="Clear form">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 8,
                    width: 120,
                    backgroundColor: "#808080",
                    padding: "7px 14px",
                    fontSize: "14px",
                    color: "white",
                    ":hover": {
                      bgcolor: "#696969",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    resetForm(props);
                  }}
                >
                  Reset
                </Button>
              </LightTooltip>

              <LightTooltip title="Submit details">
                <Button
                  loading={props.isSubmitting}
                  loadingPosition="start"
                  disabled={props.isSubmitting}
                  type="submit"
                  sx={{
                    width: 120,
                  }}
                  variant="solid"
                >
                  {props.isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </LightTooltip>
            </Stack>
          </Form>
        )}
      </Formik>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
};

export default ChangePassword;
