import { React, useState, useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Add from "@mui/icons-material/Add";
import Button from "@mui/joy/Button";
import ConfirmDialog from "./ConfirmDialog";
import Notification from "./Notification";
import AddPopup from "../popups/AddPopup";
import AddRoute from "../forms/AddRoute";
import AddTripTypeToRoute from "../forms/AddTripTypeToRoute";
import RDataGrid from "./reusables/RDataGrid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { LightTooltip } from "../tooltips/ToolTip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PageLoader from "./reusables/PageLoader";

const MRoutes = () => {
  const [isFetchingRoutes, setIsFetchingRoutes] = useState(true);
  const [routes, setRoutes] = useState([]);
  const ROUTES_URL = "api/v1/routes";
  const axiosPrivate = useAxiosPrivate();
  const [openPopup, setOpenPopup] = useState(false);
  const [openAddTripType, setOpenAddTripType] = useState(false);
  const [refreshRoutes, setSetRefreshRoutes] = useState(null);

  // Edit route
  const [editRoute, setEditRoute] = useState(false);
  const [removeTripType, setRemoveTripType] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const DELETE_URL = "/api/v1/routes/";

  const onDelete = async (id) => {
    const controller = new AbortController();
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    try {
      const response = await axiosPrivate.delete(DELETE_URL + id, {
        signal: controller.signal,
      });
      var message = response.data.message;
      setNotify({
        isOpen: true,
        message: message,
        type: "success",
      });
      setSetRefreshRoutes(new Date());
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      setConfirmDialog({
        isOpen: true,
        title: "Failed To Delete Route",
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: () => {
          onDelete(id);
        },
      });
    }
  };

  useEffect(() => {
    if (!isFetchingRoutes) {
      setIsFetchingRoutes(true);
    }

    let isMounted = true;
    const controller = new AbortController();

    const getRoutes = async () => {
      try {
        const response = await axiosPrivate.get(ROUTES_URL, {
          signal: controller.signal,
        });
        isMounted && setRoutes(response.data.data);
        setIsFetchingRoutes(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setIsFetchingRoutes(false);
      }
    };

    getRoutes();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [refreshRoutes]);

  const columns = useMemo(
    () => [
      { field: "id", headerName: "Id", width: 60 },
      { field: "name", headerName: "Name", width: 410 },
      {
        field: "tripTypes",
        headerName: "Trip-Types",
        width: 410,
        renderCell: function (params) {
          const tripTypes = params.row.tripTypes;
          return tripTypes?.map((elem) => elem.name).join(",");
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        align: "center",
        headerAlign: "center",
        width: 100,
        sortable: false,
        disableClickEventBubbling: true,
        cellClassName: "actions",
        getActions: (params) => {
          return [
            <LightTooltip title="Edit route">
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={function () {
                  const currentRow = params.row;
                  const selectedRoute = JSON.stringify(currentRow, null, 4);
                  setEditRoute(true);
                  setSelectedRoute(selectedRoute);
                  return setOpenPopup(true);
                }}
                color="success"
              />
            </LightTooltip>,
            <LightTooltip title="Delete route">
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                color="error"
                onClick={function () {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure you want to delete this route?",
                    subTitle: "You can't undo this operation",
                    action: "Delete",
                    onConfirm: () => {
                      onDelete(params.row.id);
                    },
                  });
                }}
              />
            </LightTooltip>,
            <GridActionsCellItem
              icon={<ControlPointIcon />}
              label="Add Trip-Type"
              onClick={function () {
                const currentRow = params.row;
                const selectedRoute = JSON.stringify(currentRow, null, 4);
                setRemoveTripType(false);
                setSelectedRoute(selectedRoute);
                return setOpenAddTripType(true);
              }}
              color="inherit"
              showInMenu
            />,
            <GridActionsCellItem
              icon={<HighlightOffIcon />}
              label="Remove Trip-Type"
              onClick={function () {
                const currentRow = params.row;
                const selectedRoute = JSON.stringify(currentRow, null, 4);
                setRemoveTripType(true);
                setSelectedRoute(selectedRoute);
                return setOpenAddTripType(true);
              }}
              color="inherit"
              showInMenu
            />,
          ];
        },
      },
    ],
    []
  );

  // Close dialog upon successful user add/update
  const closeDialog = (message) => {
    setOpenPopup(false);
    setOpenAddTripType(false);
    setNotify({
      isOpen: true,
      message: message,
      type: "success",
    });
    setSetRefreshRoutes(new Date());
  };

  return isFetchingRoutes ? (
    <PageLoader title="Loading routes..." />
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box height={40} />

      {routes?.length ? (
        <Box
          sx={{
            height: 560,
            width: "100%",
          }}
        >
          <div style={divStyle}>
            <Typography
              variant="h4"
              component="h4"
              sx={{ textAlign: "left", mt: 3, mb: 3 }}
            >
              Manage Routes
            </Typography>
            <Button
              size="lg"
              variant="solid"
              color="primary"
              onClick={function () {
                setEditRoute(false);
                setSelectedRoute(null);
                setOpenPopup(true);
              }}
              startDecorator={<Add />}
            >
              Add Route
            </Button>
          </div>
          <Box sx={{ width: 1218, margin: "0 auto 16px", height: 560 }}>
            <RDataGrid columns={columns} rows={routes} rowHeight={63} />
          </Box>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <AddPopup
            width="md"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            title={editRoute ? "Edit Route" : "Add Route"}
          >
            <AddRoute
              closeDialog={closeDialog}
              editRoute={editRoute}
              selectedRoute={selectedRoute}
            />
          </AddPopup>
          <AddPopup
            width="md"
            openPopup={openAddTripType}
            setOpenPopup={setOpenAddTripType}
            title={removeTripType ? "Remove Trip-Type" : "Add Trip-Type"}
          >
            <AddTripTypeToRoute
              closeDialog={closeDialog}
              removeTripType={removeTripType}
              selectedRoute={selectedRoute}
            />
          </AddPopup>
        </Box>
      ) : (
        <div style={divStyle}>
          <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: "center", mt: 3, mb: 3 }}
          >
            No routes to display
          </Typography>
          <Button
            size="lg"
            variant="solid"
            color="primary"
            onClick={function () {
              setEditRoute(false);
              setSelectedRoute(null);
              setOpenPopup(true);
            }}
            startDecorator={<Add />}
          >
            Add Route
          </Button>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <AddPopup
            width="md"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            title={editRoute ? "Edit Route" : "Add Route"}
          >
            <AddRoute
              closeDialog={closeDialog}
              editRoute={editRoute}
              selectedRoute={selectedRoute}
            />
          </AddPopup>
          <AddPopup
            width="md"
            openPopup={openAddTripType}
            setOpenPopup={setOpenAddTripType}
            title={removeTripType ? "Remove Trip-Type" : "Add Trip-Type"}
          >
            <AddTripTypeToRoute
              closeDialog={closeDialog}
              removeTripType={removeTripType}
              selectedRoute={selectedRoute}
            />
          </AddPopup>
        </div>
      )}
    </Box>
  );
};

export default MRoutes;
