import { Avatar, Grid, Paper, TextField, Typography } from "@mui/material";
import Button from "@mui/joy/Button";
import { React, useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import Notification from "./Notification";
import ConfirmDialog from "./ConfirmDialog";
import Axios from "../api/Axios";
import background from "../login_bg.jpg";
import * as Yup from "yup";
import YupPassword from "yup-password";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

YupPassword(Yup);
const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let phoneNumber = location.state.phoneNumber;

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [requestingOtp, setRequestingOtp] = useState(false);
  const imgStyle = {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
  };
  const paperStyle = {
    padding: 20,
    height: "90vh",
    width: 350,
    margin: "20px auto",
  };
  const avatarStyle = { backgroundColor: "green" };
  const buttonStyle = { margin: "16px 0" };
  const otpStyle = { margin: "10px 0" };
  const passwordStyle = { margin: "20px 0" };
  const titleStyle = { color: "#8d4004" };
  const initialValues = {
    otp: "",
    password: "",
    confirm_password: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required("Required")
      .matches(/^[0-9]\d{3}$/, {
        message: "Please enter valid OTP",
        excludeEmptyString: false,
      }),
    password: Yup.string()
      .required("Required")
      .min(
        8,
        "Password must contain 8 or more characters with at least one of each: Uppercase, Lowercase, Number and Special character"
      )
      .minLowercase(1, "Password must contain at least 1 Lower case letter")
      .minUppercase(1, "Password must contain at least 1 Upper case letter")
      .minNumbers(1, "Password must contain at least 1 Number")
      .minSymbols(1, "Password must contain at least 1 Special character"),
    confirm_password: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });

  const PASSWORD_RESET_URL = "/api/v1/users/resetpassword";
  const onSubmit = async (values, props) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    const otp = values.otp;
    const password = values.password;
    const confirmPassword = values.confirm_password;

    console.log(
      `otp --> ${otp}; otp --> ${otp}; phoneNumber --> ${phoneNumber}; password --> ${password}; confirmPassword --> ${confirmPassword};`
    );

    try {
      const response = await Axios.post(
        PASSWORD_RESET_URL,
        { otp, phoneNumber, password, confirmPassword },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data.message);

      // clear input fields
      props.resetForm();
      props.setSubmitting(false);

      setNotify({
        isOpen: true,
        message: response.data.message,
        type: "success",
      });

      setTimeout(() => {
        navigate("/login", { replace: true });
      }, 2000);
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      setConfirmDialog({
        isOpen: true,
        title: "Password Change Failed",
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  const OTP_URL = "/api/v1/generate/otp";
  const onResendOtp = async (values, props) => {
    setRequestingOtp(true);

    const isExistingUser = true;

    try {
      const response = await Axios.post(
        OTP_URL,
        { phoneNumber, isExistingUser },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data.message);
      setRequestingOtp(false);

      setNotify({
        isOpen: true,
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      setConfirmDialog({
        isOpen: true,
        title: "Password Change Failed",
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  return (
    <>
      <div style={imgStyle}>
        <Grid container justify="center">
          <Paper elevation={10} style={paperStyle}>
            <Grid align="center">
              <Avatar style={avatarStyle}>
                <LockOutlinedIcon></LockOutlinedIcon>
              </Avatar>
              <h3 style={titleStyle}>School Transport System</h3>
              <h2>Reset Password</h2>
              <h5>Enter the OTP, new password and confirm password</h5>
            </Grid>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(props) => (
                <Form>
                  <Field
                    as={TextField}
                    label="OTP"
                    name="otp"
                    placeholder="Enter OTP"
                    style={otpStyle}
                    fullWidth
                    required
                    helperText={<ErrorMessage name="otp" />}
                  />
                  <Typography align="right">
                    <Button
                      size="sm"
                      loading={requestingOtp}
                      loadingPosition="start"
                      variant="plain"
                      style={{
                        textDecorationLine: "underline",
                      }}
                      onClick={onResendOtp}
                    >
                      Resend OTP
                    </Button>
                  </Typography>

                  <Field
                    as={TextField}
                    label="Password"
                    name="password"
                    placeholder="Enter password"
                    style={passwordStyle}
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    required
                    helperText={<ErrorMessage name="password" />}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    as={TextField}
                    label="Confirm Password"
                    name="confirm_password"
                    placeholder="Enter password"
                    style={passwordStyle}
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    required
                    helperText={<ErrorMessage name="confirm_password" />}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    type="submit"
                    color="primary"
                    fullWidth
                    loading={props.isSubmitting}
                    loadingPosition="start"
                    variant="solid"
                    style={buttonStyle}
                  >
                    {props.isSubmitting ? "Processing" : "Submit"}
                  </Button>
                </Form>
              )}
            </Formik>
          </Paper>

          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </Grid>
      </div>
    </>
  );
};

export default ResetPassword;
