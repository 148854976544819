import { Avatar, Grid, Paper, TextField, Typography } from "@mui/material";
import Button from "@mui/joy/Button";
import { React, useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Form, Formik, Field, ErrorMessage } from "formik";
import Notification from "./Notification";
import ConfirmDialog from "./ConfirmDialog";
import Axios from "../api/Axios";
import { useNavigate, useLocation } from "react-router-dom";
import background from "../login_bg.jpg";
import * as Yup from "yup";
import YupPassword from "yup-password";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

YupPassword(Yup);
const NewPassword = () => {
  const location = useLocation();
  //const from = location.state?.from?.pathname || "/";
  const navigate = useNavigate();
  const paperStyle = {
    padding: 20,
    height: "90vh",
    width: 350,
    margin: "20px auto",
  };
  const avatarStyle = { backgroundColor: "green" };
  const buttonStyle = { margin: "16px 0" };
  const passwordStyle = { margin: "20px 0" };
  const titleStyle = { color: "#8d4004" };
  const imgStyle = {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [acceptedTnCs, setAcceptedTnCs] = useState(false);
  const [invalidTandCs, setInvalidTandCs] = useState(false);
  const onCheckboxChange = (event, value) => {
    setAcceptedTnCs(value);
    setInvalidTandCs(false);
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let username = location.state.username;
  let oldPassword = location.state.password;

  const initialValues = {
    password: "",
    confirm_password: "",
    acceptTnCs: false,
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Required")
      .min(
        8,
        "Password must contain 8 or more characters with at least one of each: Uppercase, Lowercase, Number and Special character"
      )
      .minLowercase(1, "Password must contain at least 1 Lower case letter")
      .minUppercase(1, "Password must contain at least 1 Upper case letter")
      .minNumbers(1, "Password must contain at least 1 Number")
      .minSymbols(1, "Password must contain at least 1 Special character"),
    confirm_password: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });

  const NEW_PASSWORD_URL = "/api/v1/users/change/password/username";
  const onSubmit = async (values, props) => {
    if (acceptedTnCs === false) {
      setInvalidTandCs(true);
      return;
    }

    setConfirmDialog({ ...confirmDialog, isOpen: false });
    const newPassword = values.password;

    try {
      const response = await Axios.post(
        NEW_PASSWORD_URL,
        { username, oldPassword, newPassword, agreedToTerms: acceptedTnCs },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // clear input fields
      props.resetForm();
      props.setSubmitting(false);

      setNotify({
        isOpen: true,
        message: response.data.message,
        type: "success",
      });

      setTimeout(() => {
        navigate("/login", { replace: true });
      }, 2000);
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      setConfirmDialog({
        isOpen: true,
        title: "Password Change Failed",
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  return (
    <>
      <div style={imgStyle}>
        <Grid container justify="center">
          <Paper elevation={10} style={paperStyle}>
            <Grid align="center">
              <Avatar style={avatarStyle}>
                <LockOutlinedIcon></LockOutlinedIcon>
              </Avatar>
              <h3 style={titleStyle}>School Transport System</h3>
              <h2>Change Password</h2>
              <h5>Enter and confirm new password</h5>
            </Grid>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(props) => (
                <Form>
                  <Field
                    as={TextField}
                    label="New Password"
                    name="password"
                    placeholder="Enter New password"
                    style={passwordStyle}
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    required
                    helperText={<ErrorMessage name="password" />}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    as={TextField}
                    label="Confirm Password"
                    name="confirm_password"
                    placeholder="Enter password"
                    style={passwordStyle}
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    required
                    helperText={<ErrorMessage name="confirm_password" />}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <FormControlLabel
                    control={<Checkbox name="acceptTnCs" />}
                    name="acceptTnCs"
                    onChange={onCheckboxChange}
                    label={
                      <p>
                        {"I Agree To "}
                        <a
                          href="https://policies.google.com/terms?hl=en-US"
                          rel="noreferrer"
                          target="_blank"
                        >
                          Terms and Conditions
                        </a>
                      </p>
                    }
                  />

                  {invalidTandCs && (
                    <Typography
                      color="error"
                      sx={{ textAlign: "left", mt: 0, mb: 2 }}
                    >
                      It is essential to accept our T&Cs to login
                    </Typography>
                  )}

                  <Button
                    type="submit"
                    color="primary"
                    fullWidth
                    loading={props.isSubmitting}
                    loadingPosition="start"
                    variant="solid"
                    style={buttonStyle}
                  >
                    {props.isSubmitting ? "Processing" : "Submit"}
                  </Button>
                </Form>
              )}
            </Formik>
          </Paper>

          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </Grid>
      </div>
    </>
  );
};

export default NewPassword;
