import React, { useState } from "react";
import "../styles.css";
import Header from "./faqs/Header";
import FAQ from "./faqs/RawFAQs";
import Box from "@mui/material/Box";

export default function Faqs() {
  const [faqs, setFaqs] = useState([
    {
      question: "What is a School Transport System?",
      answer:
        "The School Transport System is a computer application aimed at revolutionising transportation of students to and from the school.",
      open: true,
    },
    {
      question: "How does the School Transport System work?",
      answer:
        "The system has 2 mobile applications, driver and guardian's. The driver logs into their app, then starts a trip. Guardian whose student(s) use that trip get notification alert and are eble to even see drivers location and movement on their app. Once the student gets picked, they are able to monitor them until they get to school, and in case of breakdonw, they are automatically notfied. Same applies to when students are being dropped",
      open: false,
    },
    {
      question: "How does one get onboarded onto the system?",
      answer:
        "As a guardian the school creates your account then send you an alert with the login credentials. You then go the playstore, download the app and login. Upon successful first time login, you will be prompted to change your password. \nThis also applies to the driver.",
      open: false,
    },
    {
      question: "What if I'm not a guardian?",
      answer:
        "Only parents (guardians) and drivers for the school can access the application",
      open: false,
    },
    {
      question: "Does the application track my location?",
      answer:
        "As a Driver yes, simply because parents would like to get real-time updates on where they students are. As a guardian, No!",
      open: false,
    },
    {
      question: "What happens if I don't receive the account created alert?",
      answer:
        "Just ask the system admin at the school, they will be able to assist",
      open: false,
    },
    {
      question: "Can I opt out of the application?",
      answer: "Yes, upon request to the school",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <>
      <Box height={60} />
      <div className="App">
        <Header />
        <div className="faqs">
          {faqs.map((faq, index) => (
            <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
          ))}
        </div>
      </div>
    </>
  );
}
