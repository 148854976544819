import { Box, Grid, Paper } from "@mui/material";
import { React, useState, useEffect } from "react";
import "../styles.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Users from "./Users";
import {
  ZAxis,
  ScatterChart,
  Scatter,
  ReferenceLine,
  RadialBarChart,
  RadialBar,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  PieChart,
  Pie,
  Sector,
  BarChart,
  Bar,
  Cell,
  AreaChart,
  Area,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import StatisticsCard from "./reusables/StatisticsCard";
import SingleBedIcon from "@mui/icons-material/SingleBed";

function Home() {
  const axiosPrivate = useAxiosPrivate();

  // Users
  const [totalUsers, setUsers] = useState(0);
  const USERS_URL = "api/v1/users";
  const usersClicked = () => {
    <Users />;
    //console.log("users clicked");
  };

  // Routes
  const [totalRoutes, setRoutes] = useState(0);
  const ROUTES_URL = "api/v1/routes";
  const routesClicked = () => {
    console.log("routes clicked");
  };

  // Stations
  const [totalStations, setStations] = useState(0);
  const STATIONS_URL = "api/v1/stations";
  const stationsClicked = () => {
    console.log("stations clicked");
  };

  // Cars
  const [totalCars, setCars] = useState([]);
  const CARS_URL = "api/v1/cars";
  const carsClicked = () => {
    console.log("cars clicked");
  };

  const graph1PaperStyle = {
    padding: 20,
    height: "40vh",
    width: 280,
    margin: "20px auto",
  };

  const paperStyle = {
    padding: 20,
    height: "40vh",
    width: 280,
    margin: "20px auto",
  };

  const paper3Style = {
    padding: 20,
    height: "50vh",
    width: 385,
    margin: "20px auto",
  };

  const lastPaperStyle = {
    padding: 20,
    height: "60vh",
    width: 1220,
    margin: "20px auto",
  };

  const titleStyle = { color: "#8d4004" };

  const data = [
    {
      name: "Route A",
      uv: 40,
      pv: 39,
      amt: 0,
    },
    {
      name: "Route B",
      uv: 41,
      pv: 41,
      amt: 0,
    },
    {
      name: "Route C",
      uv: 38,
      pv: 38,
      amt: 0,
    },
    {
      name: "Route D",
      uv: 44,
      pv: 43,
      amt: 0,
    },
    {
      name: "Route E",
      uv: 42,
      pv: 43,
      amt: 0,
    },
    {
      name: "Route F",
      uv: 40,
      pv: 30,
      amt: 0,
    },
    {
      name: "Route G",
      uv: 45,
      pv: 44,
      amt: 0,
    },
  ];

  const data01 = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
  ];

  const data02 = [
    { name: "A1", value: 100 },
    { name: "A2", value: 300 },
    { name: "B1", value: 100 },
    { name: "B2", value: 80 },
    { name: "B3", value: 40 },
    { name: "B4", value: 30 },
    { name: "B5", value: 50 },
    { name: "C1", value: 100 },
    { name: "C2", value: 200 },
    { name: "D1", value: 150 },
    { name: "D2", value: 50 },
  ];

  const radarData = [
    {
      subject: "Wanginge",
      A: 120,
      B: 110,
      fullMark: 150,
    },
    {
      subject: "Mwimuto",
      A: 98,
      B: 130,
      fullMark: 150,
    },
    {
      subject: "Gikuni",
      A: 86,
      B: 130,
      fullMark: 150,
    },
    {
      subject: "Kiahuria",
      A: 99,
      B: 100,
      fullMark: 150,
    },
    {
      subject: "Kitusuru",
      A: 85,
      B: 90,
      fullMark: 150,
    },
    {
      subject: "Westlands",
      A: 65,
      B: 85,
      fullMark: 150,
    },
  ];

  const radialData = [
    {
      name: "18-24",
      uv: 31.47,
      pv: 2400,
      fill: "#8884d8",
    },
    {
      name: "25-29",
      uv: 26.69,
      pv: 4567,
      fill: "#83a6ed",
    },
    {
      name: "30-34",
      uv: 15.69,
      pv: 1398,
      fill: "#8dd1e1",
    },
    {
      name: "35-39",
      uv: 8.22,
      pv: 9800,
      fill: "#82ca9d",
    },
    {
      name: "40-49",
      uv: 8.63,
      pv: 3908,
      fill: "#a4de6c",
    },
    {
      name: "50+",
      uv: 2.63,
      pv: 4800,
      fill: "#d0ed57",
    },
    {
      name: "unknow",
      uv: 6.67,
      pv: 4800,
      fill: "#ffc658",
    },
  ];

  const style = {
    top: "50%",
    right: 0,
    transform: "translate(0, -50%)",
    lineHeight: "24px",
  };

  const data03 = [
    { x: 100, y: 200, z: 200 },
    { x: 120, y: 100, z: 260 },
    { x: 170, y: 300, z: 400 },
    { x: 140, y: 250, z: 280 },
    { x: 150, y: 400, z: 500 },
    { x: 110, y: 280, z: 200 },
  ];
  const data04 = [
    { x: 200, y: 260, z: 240 },
    { x: 240, y: 290, z: 220 },
    { x: 190, y: 290, z: 250 },
    { x: 198, y: 250, z: 210 },
    { x: 180, y: 280, z: 260 },
    { x: 210, y: 220, z: 230 },
  ];

  // Get users
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(USERS_URL, {
          signal: controller.signal,
        });
        isMounted && setUsers(response.data.data.length);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
      }
    };

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  // Get routes
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getRoutes = async () => {
      try {
        const response = await axiosPrivate.get(ROUTES_URL, {
          signal: controller.signal,
        });
        isMounted && setRoutes(response.data.data.length);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
      }
    };

    getRoutes();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  // Get stations
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getStations = async () => {
      try {
        const response = await axiosPrivate.get(STATIONS_URL, {
          signal: controller.signal,
        });
        isMounted && setStations(response.data.data.length);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
      }
    };

    getStations();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  // Get cars
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCars = async () => {
      try {
        const response = await axiosPrivate.get(CARS_URL, {
          signal: controller.signal,
        });
        isMounted && setCars(response.data.data.length);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
      }
    };

    getCars();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <Grid>
      <Box height={80} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <Box mb={1.5}>
            <StatisticsCard
              color="black"
              icon="people"
              title="Users"
              count={totalUsers}
              percentageColor="success.main"
              percentageAmount="+55%"
              percentageLabel="than lask week"
              mOnClick={usersClicked}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <StatisticsCard
            color="warning.main"
            icon="route"
            title="Routes"
            count={totalRoutes}
            percentageColor="success.main"
            percentageAmount="+3%"
            percentageLabel="than lask month"
            mOnClick={routesClicked}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <StatisticsCard
            color="success.main"
            icon="airline_stops"
            title="Stations"
            count={totalStations}
            percentageColor="success.main"
            percentageAmount="+1%"
            percentageLabel="than yesterday"
            mOnClick={stationsClicked}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <StatisticsCard
            color="primary.main"
            icon="directions_bus"
            title="Cars"
            count={totalCars}
            percentageColor="success.main"
            percentageAmount=""
            percentageLabel="Just updated"
            mOnClick={carsClicked}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <Paper elevation={10} style={lastPaperStyle}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" fill="#8884d8" />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={2} md={2} lg={4}>
          <Paper elevation={10} style={paper3Style}>
            <ResponsiveContainer width="100%" height="100%">
              <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarData}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis />
                <Radar
                  name="Mike"
                  dataKey="A"
                  stroke="#8884d8"
                  fill="#8884d8"
                  fillOpacity={0.6}
                />
              </RadarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={2} md={2} lg={4}>
          <Paper elevation={10} style={paper3Style}>
            <ResponsiveContainer width="100%" height="100%">
              <RadialBarChart
                cx="50%"
                cy="50%"
                innerRadius="10%"
                outerRadius="80%"
                barSize={10}
                data={radialData}
              >
                <RadialBar
                  minAngle={15}
                  label={{ position: "insideStart", fill: "#fff" }}
                  background
                  clockWise
                  dataKey="uv"
                />
                <Legend
                  iconSize={10}
                  layout="vertical"
                  verticalAlign="middle"
                  wrapperStyle={style}
                />
              </RadialBarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={2} md={2} lg={4}>
          <Paper elevation={10} style={paper3Style}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={data}
                stackOffset="sign"
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <ReferenceLine y={0} stroke="#000" />
                <Bar dataKey="pv" fill="#8884d8" stackId="stack" />
                <Bar dataKey="uv" fill="#82ca9d" stackId="stack" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <Paper elevation={10} style={graph1PaperStyle}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Paper elevation={10} style={paperStyle}>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="#8884d8"
                  fill="#8884d8"
                />
              </AreaChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Paper elevation={10} style={paperStyle}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" fill="#8884d8" />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Paper elevation={10} style={paperStyle}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={400} height={400}>
                <Pie
                  data={data01}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  outerRadius={60}
                  fill="#8884d8"
                />
                <Pie
                  data={data02}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={70}
                  outerRadius={90}
                  fill="#82ca9d"
                  label
                />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Home;
