import { React, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Button from "@mui/joy/Button";
import AddPopup from "../popups/AddPopup";
import AddUser from "../forms/AddUser";
import Notification from "./Notification";
import EditIcon from "@mui/icons-material/Edit";
import PageLoader from "./reusables/PageLoader";

function Profile(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [locked, setLocked] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [defaultPasswordUpdated, setDefaultPasswordUpdated] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);
  const [accountExpired, setAccountExpired] = useState(false);
  const [credentialsExpired, setCredentialsExpired] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [refreshProfile, setSetRefreshProfile] = useState(null);
  const [isFetchingProfile, setIsFetchingProfile] = useState(true);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const USER_URL = "api/v1/users/";
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  useEffect(() => {
    if (!isFetchingProfile) {
      setIsFetchingProfile(true);
    }
    //let isMounted = true;
    const controller = new AbortController();

    const getUserProfile = async () => {
      try {
        const response = await axiosPrivate.get(`${USER_URL}${auth.id}`, {
          signal: controller.signal,
        });
        const cUser = JSON.stringify(response.data.data, null, 4);
        setSelectedUser(cUser);
        const mUser = JSON.parse(cUser);
        //isMounted &&
        setFirstName(mUser.firstName);
        setLastName(mUser.lastName);
        setEmail(mUser.email);
        setPhone(mUser.phone);
        setRole(mUser.roles[0].name);
        setLocked(mUser.locked);
        setEnabled(mUser.enabled);
        setEmailVerified(mUser.emailVerified);
        setDefaultPasswordUpdated(mUser.defaultPasswordUpdated);
        setAgreedToTerms(mUser.agreedToTerms);
        setLoggedOut(mUser.loggedOut);
        setAccountExpired(mUser.accountExpired);
        setCredentialsExpired(mUser.credentialsExpired);
        setIsFetchingProfile(false);
        //isMounted && setUser(cUser);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setIsFetchingProfile(false);
      }
    };

    getUserProfile();

    return () => {
      //isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [refreshProfile]);

  // Close dialog upon successful profile update
  const closeDialog = (message) => {
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: message,
      type: "success",
    });
    setSetRefreshProfile(new Date());
  };

  return isFetchingProfile ? (
    <PageLoader title="Loading profile..." />
  ) : (
    <Grid>
      <Box height={60} />
      <Paper
        sx={{
          p: 3,
          margin: "auto",
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
      >
        <Box m={2} pt={0}>
          <div style={divStyle}>
            <Typography
              variant="h4"
              component="h4"
              sx={{ textAlign: "left", mt: 3, mb: 3 }}
            >
              My Profile
            </Typography>
            <Button
              size="lg"
              variant="solid"
              color="primary"
              onClick={function () {
                //setSelectedUser(null);
                //setOpenPopup(true);

                setSelectedUser(selectedUser);
                setOpenPopup(true);
                //return setOpenPopup(true);
              }}
              startDecorator={<EditIcon />}
            >
              Edit
            </Button>
          </div>
          {/* <Typography
            variant="h3"
            component="h3"
            sx={{ textAlign: "left", mt: 2, mb: 1 }}
          >
            Profile
          </Typography> */}
        </Box>
        <Box style={{ borderRadius: 10 }} m={0} pt={0}>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={6} md={6} lg={6}>
              <Card style={{ borderRadius: 10 }}>
                <Grid
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  m={2}
                  pt={0}
                >
                  <Avatar
                    sx={{
                      height: "100px",
                      width: "100px",
                      backgroundColor: "#80400B",
                    }}
                  >
                    {firstName.substring(0, 1)}
                    {lastName.substring(0, 1)}
                  </Avatar>
                  <Typography variant="h4" gutterBottom sx={{ mt: 1, mb: 0 }}>
                    <strong>
                      {firstName} {lastName}
                    </strong>
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ mt: 1, mb: 0 }}
                  >
                    {role}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ mt: 1, mb: 0 }}
                  >
                    {phone}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ mt: 1, mb: 0 }}
                  >
                    {email}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ mt: 11, mb: 0 }}
                  >
                    <strong>Note:</strong> Managers/Admins don't have Routes,
                    Stations or Cars
                  </Typography>
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={6} md={6} lg={6}>
              <Card style={{ borderRadius: 10 }}>
                <Box style={{ borderRadius: 10 }} m={2} pt={0}>
                  <Typography
                    sx={{ textAlign: "left", mt: 3, mb: 3 }}
                    gutterBottom
                    variant="body2"
                  >
                    <strong>Account locked:</strong> {locked ? `True` : `False`}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ textAlign: "left", mt: 3, mb: 3 }}
                  >
                    <strong>Account enabled:</strong>{" "}
                    {enabled ? `True` : `False`}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ textAlign: "left", mt: 3, mb: 3 }}
                  >
                    <strong>Email verified:</strong>{" "}
                    {emailVerified ? `True` : `False`}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ textAlign: "left", mt: 3, mb: 3 }}
                  >
                    <strong>Default password updated:</strong>{" "}
                    {defaultPasswordUpdated ? `True` : `False`}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ textAlign: "left", mt: 3, mb: 3 }}
                  >
                    <strong>Agreed to terms:</strong>{" "}
                    {agreedToTerms ? `True` : `False`}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ textAlign: "left", mt: 3, mb: 3 }}
                  >
                    <strong>Logged out:</strong> {loggedOut ? `True` : `False`}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ textAlign: "left", mt: 3, mb: 3 }}
                  >
                    <strong>Account expired:</strong>{" "}
                    {accountExpired ? `True` : `False`}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ textAlign: "left", mt: 3, mb: 3 }}
                  >
                    <strong>Credentials expired:</strong>{" "}
                    {credentialsExpired ? `True` : `False`}
                  </Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <AddPopup
        width="md"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Edit User"
      >
        <AddUser
          closeDialog={closeDialog}
          editUser={true}
          selectedUser={selectedUser}
        />
      </AddPopup>
    </Grid>
  );
}

export default Profile;
