import { Avatar, Grid, Paper, TextField } from "@mui/material";
import Button from "@mui/joy/Button";
import { React, useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ConfirmDialog from "./ConfirmDialog";
import Axios from "../api/Axios";
import { useNavigate } from "react-router-dom";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import background from "../login_bg.jpg";

const OTP = () => {
  //const from = location.state?.from?.pathname || "/";
  const navigate = useNavigate();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const paperStyle = {
    padding: 20,
    height: "70vh",
    width: 280,
    margin: "20px auto",
  };
  //const [submitting, setSubmitting] = useState(false);
  const imgStyle = {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
  };
  const avatarStyle = { backgroundColor: "green" };
  const buttonStyle = { margin: "16px 0" };
  const usernameStyle = { margin: "10px 0" };
  const titleStyle = { color: "#8d4004" };
  const initialValues = {
    phone_number: "",
  };
  const validationSchema = Yup.object().shape({
    phone_number: Yup.string()
      .required("Required")
      .matches(/^[0-9]\d{9}$/, {
        message: "Please enter valid phone number",
        excludeEmptyString: false,
      }),
  });

  const OTP_URL = "/api/v1/generate/otp";
  const onSubmit = async (values, props) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    //setSubmitting(true)

    const phoneNumber = values.phone_number;
    const isExistingUser = true;

    try {
      const response = await Axios.post(
        OTP_URL,
        { phoneNumber, isExistingUser },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data.message);

      // clear input fields
      props.resetForm();
      props.setSubmitting(false);
      //setSubmitting(true);

      navigate("/reset-password", {
        replace: true,
        state: {
          phoneNumber: phoneNumber,
        },
      });
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      setConfirmDialog({
        isOpen: true,
        title: "Password Change Failed",
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  return (
    <>
      <div style={imgStyle}>
        <Grid container justify="center">
          <Paper elevation={10} style={paperStyle}>
            <Grid align="center">
              <Avatar style={avatarStyle}>
                <LockOutlinedIcon></LockOutlinedIcon>
              </Avatar>
              <h3 style={titleStyle}>School Transport System</h3>
              <h2>Forgot Password?</h2>
              <h5>Please enter the phone number you used to register</h5>
            </Grid>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(props) => (
                <Form>
                  <Field
                    as={TextField}
                    label="Phone number"
                    name="phone_number"
                    placeholder="E.g., 0733000000"
                    style={usernameStyle}
                    fullWidth
                    required
                    helperText={<ErrorMessage name="phone_number" />}
                  />
                  <Button
                    type="submit"
                    color="primary"
                    fullWidth
                    loading={props.isSubmitting}
                    loadingPosition="start"
                    variant="solid"
                    style={buttonStyle}
                  >
                    {props.isSubmitting ? "Processing" : "Continue"}
                  </Button>
                </Form>
              )}
            </Formik>
          </Paper>

          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </Grid>
      </div>
    </>
  );
};

export default OTP;
