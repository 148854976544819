import { React, useState, useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Add from "@mui/icons-material/Add";
import Button from "@mui/joy/Button";
import ConfirmDialog from "./ConfirmDialog";
import Notification from "./Notification";
import AddPickUpTime from "../forms/AddPickUpTime";
import AddPopup from "../popups/AddPopup";
import RDataGrid from "./reusables/RDataGrid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { LightTooltip } from "../tooltips/ToolTip";
import PageLoader from "./reusables/PageLoader";

const PickupTimes = () => {
  const [isFetchingPickUpTimes, setIsFetchingPickUpTimes] = useState(true);
  const [pickupTimes, setPickUpTimes] = useState([]);
  const PICK_UPS_URL = "api/v1/pickuptimes";
  const DELETE_URL = "api/v1/pickuptimes/";
  const axiosPrivate = useAxiosPrivate();
  const [openPopup, setOpenPopup] = useState(false);
  const [refreshPickUps, setSetRefreshPickUps] = useState(null);

  // Edit pick-up time
  const [editPickUp, setEditPickUpTime] = useState(false);
  const [selectedPickUpTime, setSelectedPickUpTime] = useState(null);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const onDelete = async (id) => {
    const controller = new AbortController();
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    try {
      const response = await axiosPrivate.delete(DELETE_URL + id, {
        signal: controller.signal,
      });
      var message = response.data.message;
      setNotify({
        isOpen: true,
        message: message,
        type: "success",
      });
      setSetRefreshPickUps(new Date());
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      setConfirmDialog({
        isOpen: true,
        title: "Failed To Delete Pick-Up Time",
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: () => {
          onDelete(id);
        },
      });
    }
  };

  useEffect(() => {
    if (!isFetchingPickUpTimes) {
      setIsFetchingPickUpTimes(true);
    }

    let isMounted = true;
    const controller = new AbortController();

    const getPickUpTimes = async () => {
      try {
        const response = await axiosPrivate.get(PICK_UPS_URL, {
          signal: controller.signal,
        });
        isMounted && setPickUpTimes(response.data.data);
        setIsFetchingPickUpTimes(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setIsFetchingPickUpTimes(false);
      }
    };

    getPickUpTimes();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [refreshPickUps]);

  const columns = useMemo(
    () => [
      { field: "id", headerName: "Id", width: 60 },
      {
        field: "route",
        headerName: "Route",
        width: 250,
        renderCell: function (params) {
          const route = params.row.station.route;
          return route.name;
        },
      },
      {
        field: "station",
        headerName: "Station",
        width: 150,
        renderCell: function (params) {
          const station = params.row.station;
          return station.name;
        },
      },
      {
        field: "trip_type",
        headerName: "Trip-Type",
        width: 150,
        renderCell: function (params) {
          const tripType = params.row.tripType;
          return tripType.name;
        },
      },
      {
        field: "isPickUp",
        headerName: "Trip-Nature",
        width: 200,
        type: "boolean",
        editable: false,
        renderCell: function (params) {
          const isPickUp = params.row.tripType.isPickUp;
          return isPickUp ? "Pick-up" : "Drop-off";
        },
      },
      { field: "time", headerName: "Time", width: 150 },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        align: "center",
        headerAlign: "center",
        width: 100,
        sortable: false,
        disableClickEventBubbling: true,
        cellClassName: "actions",
        getActions: (params) => {
          return [
            <LightTooltip title="Edit pick-up time">
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={function () {
                  const currentRow = params.row;
                  const selectedPickUpTime = JSON.stringify(
                    currentRow,
                    null,
                    4
                  );
                  setEditPickUpTime(true);
                  setSelectedPickUpTime(selectedPickUpTime);
                  return setOpenPopup(true);
                }}
                color="success"
              />
            </LightTooltip>,
            <LightTooltip title="Delete pick-up time">
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                color="error"
                onClick={function () {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure you want to delete this record?",
                    subTitle: "You can't undo this operation",
                    action: "Delete",
                    onConfirm: () => {
                      onDelete(params.row.id);
                    },
                  });
                }}
              />
            </LightTooltip>,
          ];
        },
      },
    ],
    []
  );

  // Close dialog upon successful day-time add/update
  const closeDialog = (message) => {
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: message,
      type: "success",
    });
    setSetRefreshPickUps(new Date());
  };

  return isFetchingPickUpTimes ? (
    <PageLoader title="Loading pick-up/drop-off times..." />
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box height={40} />

      {pickupTimes?.length ? (
        <Box
          sx={{
            height: 560,
            width: "100%",
          }}
        >
          <div style={divStyle}>
            <Typography
              variant="h4"
              component="h4"
              sx={{ textAlign: "left", mt: 3, mb: 3 }}
            >
              Manage Pick-Up/Drop-off Times
            </Typography>
            <Button
              size="lg"
              variant="solid"
              color="primary"
              onClick={function () {
                setEditPickUpTime(false);
                setSelectedPickUpTime(null);
                setOpenPopup(true);
              }}
              startDecorator={<Add />}
            >
              Add Trip Time
            </Button>
          </div>
          <Box sx={{ width: 1218, margin: "0 auto 16px", height: 560 }}>
            <RDataGrid columns={columns} rows={pickupTimes} rowHeight={63} />
          </Box>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <AddPopup
            width="md"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            title={editPickUp ? "Edit Trip Time" : "Add Trip Time"}
          >
            <AddPickUpTime
              closeDialog={closeDialog}
              editPickUp={editPickUp}
              selectedPickUpTime={selectedPickUpTime}
            />
          </AddPopup>
        </Box>
      ) : (
        <div style={divStyle}>
          <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: "center", mt: 3, mb: 3 }}
          >
            {"No trip (pick-up/drop-off) times to display"}
          </Typography>
          <Button
            size="lg"
            variant="solid"
            color="primary"
            onClick={function () {
              setEditPickUpTime(false);
              setSelectedPickUpTime(null);
              setOpenPopup(true);
            }}
            startDecorator={<Add />}
          >
            Add Trip Time
          </Button>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <AddPopup
            width="md"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            title={editPickUp ? "Edit Trip Time" : "Add Trip Time"}
          >
            <AddPickUpTime
              closeDialog={closeDialog}
              editPickUp={editPickUp}
              selectedPickUpTime={selectedPickUpTime}
            />
          </AddPopup>
        </div>
      )}
    </Box>
  );
};

export default PickupTimes;
