import "./App.css";
import Login from "./components/Login";
import NewPassword from "./components/NewPassword";
import OTP from "./components/OTP";
import ResetPassword from "./components/ResetPasword";
import Sidenav from "./Sidenav";
import RequireAuth from "./components/RequireAuth";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Missing from "./components/Missing";
import Unauthorised from "./components/Unauthorised";
import StationLocation from "./map/StationLocation";
import PersistLogin from "./components/PersistLogin";
import Home from "./components/Home";
import Users from "./components/Users";
import DayTimes from "./components/DayTimes";
import TripTypes from "./components/TripTypes";
import MRoutes from "./components/MRoutes";
import Stations from "./components/Stations";
import PickupTimes from "./components/PickupTimes";
import Cars from "./components/Cars";
import Students from "./components/Students";
import Trips from "./components/Trips";
import TripStates from "./components/TripStates";
import TripStudents from "./components/TripStudents";
import Roles from "./components/Roles";
import States from "./components/States";
import Transfers from "./components/Transfers";
import Profile from "./components/Profile";
import Faqs from "./components/Faqs";
import SentSMS from "./components/SentSMS";
import Notification from "./components/Notification";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="otp" element={<OTP />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="new-password" element={<NewPassword />} />
        <Route path="unauthorised" element={<Unauthorised />} />

        {/* Add Linkage route */}
        {/* Protected routes: Only Managers(1) & Admins(2) */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[1, 2]} />}>
            <Route element={<Sidenav />}>
              <Route path="/" element={<Home />} />
              <Route path="users" element={<Users />} />
              <Route path="day-times" element={<DayTimes />} />
              <Route path="trip-types" element={<TripTypes />} />
              <Route path="routes" element={<MRoutes />} />
              <Route path="stations" element={<Stations />} />
              <Route path="pick-up-drop-off-times" element={<PickupTimes />} />
              <Route path="cars" element={<Cars />} />
              <Route path="students" element={<Students />} />
              <Route path="trips" element={<Trips />} />
              <Route path="trip-states" element={<TripStates />} />
              <Route path="trip-students" element={<TripStudents />} />
              <Route path="roles" element={<Roles />} />
              <Route path="states" element={<States />} />
              <Route path="sent-sms" element={<SentSMS />} />
              <Route path="notifications" element={<Notification />} />
              <Route path="transfers" element={<Transfers />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/faqs" element={<Faqs />} />
              <Route path="pick_location" element={<StationLocation />} />
            </Route>
          </Route>
        </Route>

        {/* Catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>

    /* <>
      <Routes>
        <Route path='/' element={<Login />}/>
        <Route path='sidenav' element={<Sidenav />}/>
        <Route path='reset-password' element={<ResetPassword />}/>
        <Route path='otp' element={<OTP />}/>
      </Routes>
    </> */
  );
}

export default App;
