import { Grid, TextField } from '@mui/material';
import { useState } from "react";
import * as React from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Notification from '../components/Notification';
import ConfirmDialog from '../components/ConfirmDialog';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { LightTooltip } from '../tooltips/ToolTip';

const AddCar = (props) => {

    const { closeDialog, editCar, selectedCar } = props;
    console.log("selected car --> " + selectedCar);

    var carInfo = null
    var name = '';
    var regNo = '';
    var carId = 0;
    var sittingCapacity = 0;
    if (editCar) {
        carInfo = JSON.parse(selectedCar);
        carId = carInfo.id
        name = carInfo.name;
        regNo = carInfo.regNo;
        sittingCapacity = carInfo.sittingCapacity;
    }

    const axiosPrivate = useAxiosPrivate();

    const resetForm = (props) => {
        // clear input fields
        props.resetForm()
    }

    const closeForm = (message) => {
        closeDialog(message);
    }

    // Common
    const initialValues = editCar ? {
        name: name,
        regNo: regNo,
        sittingCapacity: sittingCapacity,
    } : {
        name: '',
        regNo: '',
        sittingCapacity: 0
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        regNo: Yup.string().required('Required'),
        sittingCapacity: Yup.string().required("Required").matches(/[1-9][0-9]?[0-9]?$/, {message: "Please enter valid sitting capacity, between 1 to 1000", excludeEmptyString: false}),
    })

    const CREATE_CAR_URL = '/api/v1/cars/save';
    const UPDATE_CAR_URL = '/api/v1/cars/';
    const onSubmit = async (values, props) => {
        props.setSubmitting(true)

        let carData = {
            name: values.name,
            regNo: values.regNo,
            sittingCapacity: values.sittingCapacity
        }

        try {
            const response = editCar
                ? await axiosPrivate.put(
                    UPDATE_CAR_URL + carId,
                    carData,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }) : await axiosPrivate.post(
                        CREATE_CAR_URL,
                        carData,
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });

            console.log(response.data.message);
            props.setSubmitting(false)
            resetForm(props)
            closeForm(response.data.message)
        } catch (error) {
            var errorMessage = ''
            if (error.response) {
                // The client was given an error response (5xx, 4xx)
                const errorData = error.response.data;
                errorMessage = errorData.message
            } else if (error.request) {
                // The client never received a response, and the request was never left
                errorMessage = 'No server response'
            } else {
                // Anything else
                errorMessage = 'Unexpected error occurred'
            }
            const errorTitle = editCar ? 'Failed To Update Car' : 'Failed To Create Car'
            setConfirmDialog({
                isOpen: true,
                title: errorTitle,
                subTitle: errorMessage,
                action: 'Retry',
                onConfirm: { onSubmit }
            })
        }
    }

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })

    return (
        <Grid>
            <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                {(props) => (
                    <Form>
                        <Stack spacing={2} mt={3} direction="column">
                            <Field as={TextField} label='Name' name='name'
                                placeholder='Enter car name'
                                fullWidth required helperText={<ErrorMessage name='name' />} />
                            <Field as={TextField} label='Reg Number' name='regNo'
                                placeholder='Enter registration number'
                                fullWidth required helperText={<ErrorMessage name='regNo' />} />
                            <Field as={TextField} label='Sitting Capacity' name='sittingCapacity'
                                placeholder='Enter sitting capacity'
                                fullWidth required helperText={<ErrorMessage name='sittingCapacity' />} />
                        </Stack>

                        <Stack spacing={2} mt={3} direction="row" justifyContent="flex-end">
                            <LightTooltip title="Clear form">
                                <Button variant="contained" sx={{
                                    borderRadius: 8,
                                    width: 120,
                                    backgroundColor: "#808080",
                                    padding: "7px 14px",
                                    fontSize: "14px",
                                    color: 'white',
                                    ':hover': {
                                        bgcolor: '#696969',
                                        color: 'white',
                                    }
                                }} onClick={() => {
                                    resetForm(props)
                                }}>
                                    Reset
                                </Button>
                            </LightTooltip>

                            <LightTooltip title="Submit details">
                                <Button
                                    loading={props.isSubmitting}
                                    loadingPosition="start"
                                    disabled={props.isSubmitting}
                                    type="submit"
                                    sx={{
                                        width: 120
                                    }}
                                    variant="solid">
                                    {props.isSubmitting ? 'Submitting' : 'Submit'}
                                </Button>
                            </LightTooltip>
                        </Stack>
                    </Form>
                )}
            </Formik>
            {/* </Paper> */}

            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </Grid>
    );
}

export default AddCar