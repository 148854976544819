import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Notification from "../components/Notification";
import ConfirmDialog from "../components/ConfirmDialog";
import { Typography } from "@mui/material";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { LightTooltip } from "../tooltips/ToolTip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import dayjs from "dayjs";

const AddDayTime = (props) => {
  const { closeDialog, editDayTime, selectedDayTime } = props;
  const [time, setTime] = useState("");
  const TIME_FORMAT = "hh:mm A";
  var yetTofire = true;

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [isInvalidTime, setIsInvalidTime] = useState(false);
  const [selectedTimeKey, setSelectedTimeKey] = useState("timeKey");

  var dayTimeInfo = null;
  var existingTime = null;
  var existingName = null;
  var existingId = null;
  var defaultTime = "";
  if (editDayTime) {
    dayTimeInfo = JSON.parse(selectedDayTime);
    existingId = dayTimeInfo.id;
    existingTime = dayTimeInfo.time;
    existingName = dayTimeInfo.name;

    // Parse time
    var todayDate = moment(new Date()).format("YYYY-MM-DD");
    defaultTime = todayDate + " " + existingTime;
  }

  const initialValues = editDayTime
    ? {
        name: existingName,
        time: existingTime,
      }
    : {
        name: "",
        time: "",
      };

  const axiosPrivate = useAxiosPrivate();

  const resetForm = (props) => {
    // clear input fields
    props.resetForm();
    setTime("");
    setSelectedTimeKey(new Date() + "a");
    setIsInvalidTime(false);
  };

  const closeForm = (message) => {
    closeDialog(message);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const CREATE_DAY_TIME_URL = "/api/v1/daytimes/save";
  const UPDATE_DAY_TIME_URL = "/api/v1/daytimes/" + existingId;
  const onSubmit = async (values, props) => {
    props.setSubmitting(true);

    const isValidTime = moment(time, TIME_FORMAT, true).isValid();
    if (!isValidTime) {
      setIsInvalidTime(true);
      return;
    }

    const dayTimeData = {
      name: values.name,
      time: time,
    };

    try {
      const response = editDayTime
        ? await axiosPrivate.put(UPDATE_DAY_TIME_URL, dayTimeData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
        : await axiosPrivate.post(CREATE_DAY_TIME_URL, dayTimeData, {
            headers: {
              "Content-Type": "application/json",
            },
          });

      console.log(response.data.message);

      props.setSubmitting(false);
      resetForm(props);
      closeForm(response.data.message);
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      const errorTitle = editDayTime
        ? "Failed To Update Day-Tine"
        : "Failed To Create Day-Tine";
      setConfirmDialog({
        isOpen: true,
        title: errorTitle,
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  return (
    <Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Stack spacing={2} mt={3} direction="column">
              <Field
                as={TextField}
                label="Day-Time Name"
                name="name"
                placeholder="Enter day-time name"
                value={props.values.name}
                onChange={(event) => {
                  props.setFieldValue("name", event.target.value);
                  if (editDayTime && yetTofire) {
                    yetTofire = false;
                    setTime(existingTime);
                  }
                }}
                fullWidth
                required
                sx={{ width: 300 }}
                helperText={<ErrorMessage name="name" />}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Start time"
                  name="time"
                  id="time"
                  defaultValue={editDayTime ? dayjs(defaultTime) : null}
                  key={selectedTimeKey}
                  onChange={(date) => {
                    var time = date?.format(TIME_FORMAT);
                    setTime(time);
                    setIsInvalidTime(false);
                  }}
                  {...(isInvalidTime ? { error: true } : {})}
                />
              </LocalizationProvider>
            </Stack>

            {isInvalidTime && (
              <Typography
                color="error"
                sx={{ textAlign: "left", mt: 0, mb: 2 }}
              >
                Select a valid time
              </Typography>
            )}

            <Stack spacing={2} mt={3} direction="row" justifyContent="flex-end">
              <LightTooltip title="Clear form">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 8,
                    width: 120,
                    backgroundColor: "#808080",
                    padding: "7px 14px",
                    fontSize: "14px",
                    color: "white",
                    ":hover": {
                      bgcolor: "#696969",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    resetForm(props);
                  }}
                >
                  Reset
                </Button>
              </LightTooltip>

              <LightTooltip title="Submit details">
                <span>
                  <Button
                    loading={props.isSubmitting}
                    loadingPosition="start"
                    disabled={time === "" || props.isSubmitting}
                    type="submit"
                    sx={{
                      width: 120,
                    }}
                    variant="solid"
                  >
                    {props.isSubmitting ? "Submitting" : "Submit"}
                  </Button>
                </span>
              </LightTooltip>
            </Stack>
          </Form>
        )}
      </Formik>

      <Notification notify={notify} setNotify={setNotify} />

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
};

export default AddDayTime;
