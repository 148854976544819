import { React, useState, useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Add from "@mui/icons-material/Add";
import Button from "@mui/joy/Button";
import ConfirmDialog from "./ConfirmDialog";
import Notification from "./Notification";
import AddDayTime from "../forms/AddDayTime";
import AddPopup from "../popups/AddPopup";
import RDataGrid from "./reusables/RDataGrid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { LightTooltip } from "../tooltips/ToolTip";
import PageLoader from "./reusables/PageLoader";

const DayTimes = () => {
  const [isFetchingDayTimes, setIsFetchingDayTimes] = useState(true);
  const [dayTimes, setDayTimes] = useState([]);
  const DAY_TIMES_URL = "api/v1/daytimes";
  const DELETE_URL = "api/v1/daytimes/";
  const axiosPrivate = useAxiosPrivate();
  const [openPopup, setOpenPopup] = useState(false);
  const [refreshDayTimes, setSetRefreshDayTimes] = useState(null);

  // Edit day-time
  const [editDayTime, setEditDayTime] = useState(false);
  const [selectedDayTime, setSelectedDayTime] = useState(null);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const onDelete = async (id) => {
    const controller = new AbortController();
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    try {
      const response = await axiosPrivate.delete(DELETE_URL + id, {
        signal: controller.signal,
      });
      var message = response.data.message;
      setNotify({
        isOpen: true,
        message: message,
        type: "success",
      });
      setSetRefreshDayTimes(new Date());
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      setConfirmDialog({
        isOpen: true,
        title: "Failed To Delete Day-Time",
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: () => {
          onDelete(id);
        },
      });
    }
  };

  useEffect(() => {
    if (!isFetchingDayTimes) {
      setIsFetchingDayTimes(true);
    }

    let isMounted = true;
    const controller = new AbortController();

    const getDayTimes = async () => {
      try {
        const response = await axiosPrivate.get(DAY_TIMES_URL, {
          signal: controller.signal,
        });
        isMounted && setDayTimes(response.data.data);
        setIsFetchingDayTimes(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setIsFetchingDayTimes(false);
      }
    };

    getDayTimes();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [refreshDayTimes]);

  const columns = useMemo(
    () => [
      { field: "id", headerName: "Id", width: 60 },
      { field: "name", headerName: "Name", width: 225 },
      { field: "time", headerName: "Time", width: 225 },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        align: "center",
        headerAlign: "center",
        width: 100,
        sortable: false,
        disableClickEventBubbling: true,
        cellClassName: "actions",
        getActions: (params) => {
          return [
            <LightTooltip title="Edit trip-type">
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={function () {
                  const currentRow = params.row;
                  const selectedDayTime = JSON.stringify(currentRow, null, 4);
                  setEditDayTime(true);
                  setSelectedDayTime(selectedDayTime);
                  return setOpenPopup(true);
                }}
                color="success"
              />
            </LightTooltip>,
            <LightTooltip title="Delete trip-type">
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                color="error"
                onClick={function () {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure you want to delete this day-time?",
                    subTitle: "You can't undo this operation",
                    action: "Delete",
                    onConfirm: () => {
                      onDelete(params.row.id);
                    },
                  });
                }}
              />
            </LightTooltip>,
          ];
        },
      },
    ],
    []
  );

  // Close dialog upon successful day-time add/update
  const closeDialog = (message) => {
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: message,
      type: "success",
    });
    setSetRefreshDayTimes(new Date());
  };

  return isFetchingDayTimes ? (
    <PageLoader title="Loading day-times..." />
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box height={40} />

      {dayTimes?.length ? (
        <Box
          sx={{
            height: 560,
            width: "100%",
          }}
        >
          <div style={divStyle}>
            <Typography
              variant="h4"
              component="h4"
              sx={{ textAlign: "left", mt: 3, mb: 3 }}
            >
              Manage Day-Times
            </Typography>
            <Button
              size="lg"
              variant="solid"
              color="primary"
              onClick={function () {
                setEditDayTime(false);
                setSelectedDayTime(null);
                setOpenPopup(true);
              }}
              startDecorator={<Add />}
            >
              Add Day-Time
            </Button>
          </div>

          <Box sx={{ width: 1218, margin: "0 auto 16px", height: 560 }}>
            <RDataGrid columns={columns} rows={dayTimes} rowHeight={63} />
          </Box>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <AddPopup
            width="md"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            title={editDayTime ? "Edit Day-Time" : "Add Day-Time"}
          >
            <AddDayTime
              closeDialog={closeDialog}
              editDayTime={editDayTime}
              selectedDayTime={selectedDayTime}
            />
          </AddPopup>
        </Box>
      ) : (
        /*  <Typography variant='h4' component='h4' sx={{ textAlign: 'center', mt: 3, mb: 3 }}>
                         No day-times to display
                     </Typography> */

        <div style={divStyle}>
          <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: "center", mt: 3, mb: 3 }}
          >
            No day-times to display
          </Typography>
          <Button
            size="lg"
            variant="solid"
            color="primary"
            onClick={function () {
              setEditDayTime(false);
              setSelectedDayTime(null);
              setOpenPopup(true);
            }}
            startDecorator={<Add />}
          >
            Add Day-Time
          </Button>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <AddPopup
            width="md"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            title={editDayTime ? "Edit Day-Time" : "Add Day-Time"}
          >
            <AddDayTime
              closeDialog={closeDialog}
              editDayTime={editDayTime}
              selectedDayTime={selectedDayTime}
            />
          </AddPopup>
        </div>
      )}
    </Box>
  );
};

export default DayTimes;
