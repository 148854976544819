import { React, useState, useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import RDataGrid from "./reusables/RDataGrid";
import PageLoader from "./reusables/PageLoader";
import moment from "moment";
import { date_format, time_format } from "../constants/AppConstants";

const TripStates = (props) => {
  const [isFetchingTripStates, setIsFetchingTripStates] = useState(true);
  const [tripStates, setTripStates] = useState([]);

  const { selectedTrip } = props;
  var userInfo = JSON.parse(selectedTrip);

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  useEffect(() => {
    if (!isFetchingTripStates) {
      setIsFetchingTripStates(true);
    }

    let isMounted = true;
    const controller = new AbortController();

    const getTripStates = async () => {
      try {
        isMounted && setTripStates(userInfo.tripStates);
        setIsFetchingTripStates(false);
      } catch (error) {
        var errorMessage = "Unable to render trip states";
        console.log(errorMessage);
        setIsFetchingTripStates(false);
      }
    };

    getTripStates();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const columns = useMemo(
    () => [
      { field: "stateId", headerName: "Id", width: 60 },
      {
        field: "statusName",
        headerName: "Status",
        width: 150,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "reason",
        headerName: "Reason",
        width: 200,
        align: "left",
        headerAlign: "left",
        renderCell: function (params) {
          const reason = params.row.reason;
          return reason == null ? "-" : reason.reason;
        },
      },
      {
        field: "dateTime",
        headerName: "Date",
        width: 200,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const timestamp = params.row.dateTime;
          var date = moment(timestamp).format(date_format);
          return date;
        },
      },
      {
        field: "time",
        headerName: "Time",
        width: 130,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const timestamp = params.row.dateTime;
          var time = moment(timestamp).format(time_format);
          return time;
        },
      },
    ],
    []
  );

  return isFetchingTripStates ? (
    <PageLoader title="Loading trip-states..." />
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box height={16} />

      {tripStates?.length ? (
        <Box
          sx={{
            height: 500,
            width: "100%",
          }}
        >
          <Box sx={{ width: 800, margin: "0 auto 16px", height: 500 }}>
            <RDataGrid columns={columns} rows={tripStates} rowHeight={63} />
          </Box>
        </Box>
      ) : (
        <div style={divStyle}>
          <Typography
            variant="h3"
            component="h3"
            sx={{ textAlign: "center", mt: 3, mb: 3 }}
          >
            No trip-states to display
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default TripStates;
