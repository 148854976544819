import { React, useState, useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import RDataGrid from "./reusables/RDataGrid";
import PageLoader from "./reusables/PageLoader";
import moment from "moment";
import { date_format, time_format } from "../constants/AppConstants";
import AddPopup from "../popups/AddPopup";
import TripStates from "./TripStates";
import ReorderIcon from "@mui/icons-material/Reorder";
import { GridActionsCellItem } from "@mui/x-data-grid";

const Trips = () => {
  const [isFetchingTrips, setIsFetchingtrips] = useState(true);
  const [openTripStates, setOpenTripStates] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [selectedTripTitle, setSelectedTripTitle] = useState(null);
  const [trips, setTrips] = useState([]);
  const TRIP_URL = "api/v1/trips";
  const axiosPrivate = useAxiosPrivate();

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  useEffect(() => {
    if (!isFetchingTrips) {
      setIsFetchingtrips(true);
    }

    let isMounted = true;
    const controller = new AbortController();

    const getTrips = async () => {
      try {
        const response = await axiosPrivate.get(TRIP_URL, {
          signal: controller.signal,
        });
        isMounted && setTrips(response.data.data);
        setIsFetchingtrips(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setIsFetchingtrips(false);
      }
    };

    getTrips();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const columns = useMemo(
    () => [
      { field: "id", headerName: "Id", width: 60 },
      {
        field: "routeName",
        headerName: "Route",
        width: 300,
        align: "left",
        headerAlign: "left",
        renderCell: function (params) {
          const routeName = params.row.routeName;
          return routeName == null ? "-" : routeName;
        },
      },
      { field: "tripName", headerName: "Name", width: 200 },
      {
        field: "date",
        headerName: "Date",
        width: 200,
        align: "left",
        headerAlign: "left",
        renderCell: function (params) {
          const timestamp = params.row.date;
          var date = moment(timestamp).format(date_format);
          return date;
        },
      },
      {
        field: "startTime",
        headerName: "Start Time",
        width: 160,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const timestamp = params.row.startTime;
          var time = moment(timestamp).format(time_format);
          return time;
        },
      },
      {
        field: "endTime",
        headerName: "End Time",
        width: 160,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const timestamp = params.row.endTime;
          var time =
            timestamp == null
              ? "Ongoing"
              : moment(timestamp).format(time_format);
          return time;
        },
      },
      {
        field: "tripStatusName",
        headerName: "Status",
        width: 160,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const tripStatusName = params.row.tripStatusName;
          return tripStatusName == null ? "-" : tripStatusName;
        },
      },
      {
        field: "driverName",
        headerName: "Driver",
        width: 200,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const driverName = params.row.driverName;
          return driverName == null ? "-" : driverName;
        },
      },
      {
        field: "isPickUp",
        headerName: "Trip-Nature",
        width: 140,
        type: "boolean",
        align: "center",
        headerAlign: "center",
        editable: false,
        renderCell: function (params) {
          const isPickUp = params.row.isPickUp;
          return isPickUp ? "Pick-up" : "Drop-off";
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        align: "center",
        headerAlign: "center",
        width: 100,
        sortable: false,
        disableClickEventBubbling: true,
        cellClassName: "actions",
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<ReorderIcon />}
              label={"View Trip States"}
              onClick={function () {
                const currentRow = params.row;
                const selectedTrip = JSON.stringify(currentRow, null, 4);
                setSelectedTripTitle(`'${currentRow.tripName}' States`);
                setSelectedTrip(selectedTrip);
                return setOpenTripStates(true);
              }}
              color="inherit"
              showInMenu
            />,
          ];
        },
      },
    ],
    []
  );

  return isFetchingTrips ? (
    <PageLoader title="Loading trips..." />
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box height={40} />

      {trips?.length ? (
        <Box
          sx={{
            height: 560,
            width: "100%",
          }}
        >
          <div style={divStyle}>
            <Typography
              variant="h4"
              component="h4"
              sx={{ textAlign: "left", mt: 3, mb: 3 }}
            >
              Trips
            </Typography>
          </div>
          <Box sx={{ width: 1218, margin: "0 auto 16px", height: 560 }}>
            <RDataGrid columns={columns} rows={trips} rowHeight={63} />
          </Box>
          <AddPopup
            width="md"
            openPopup={openTripStates}
            setOpenPopup={setOpenTripStates}
            title={selectedTripTitle}
          >
            <TripStates selectedTrip={selectedTrip} />
          </AddPopup>
        </Box>
      ) : (
        <div style={divStyle}>
          <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: "center", mt: 3, mb: 3 }}
          >
            No trips to display
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default Trips;
