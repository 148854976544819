import { useState } from "react";
import * as React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import TransferGuardianToStation from "../transfers/TransferGuardianToStation";
import TransferStudentToGuardian from "../transfers/TransferStudentToGuardian";

const Transfers = () => {
  const [typeOfTransfer, setTypeOfTransfer] = useState(null);
  const onOptionChange = (event, value) => {
    const selected = value === null ? null : value.label;
    setTypeOfTransfer(selected);
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box height={40} />

      <Box
        sx={{
          height: 560,
          width: "100%",
        }}
      >
        <div>
          <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: "left", mt: 3, mb: 3 }}
          >
            Transfers
          </Typography>
          <Typography
            variant="h5"
            component="h5"
            sx={{ textAlign: "left", mt: 3, mb: 3 }}
          >
            Select Type Of Transfer
          </Typography>

          <Autocomplete
            disablePortal
            id="type"
            label="Type Of Transfer"
            placeholder="Select type of transfer"
            onChange={onOptionChange}
            fullWidth
            options={options}
            sx={{ width: 400 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type Of Transfer"
                name="type"
                placeholder="Select type of transfer"
              />
            )}
          />

          <Box component="main" sx={{ flexGrow: 1, pt: 0 }}>
            {typeOfTransfer === "Transfer guardian to another station" && (
              <TransferGuardianToStation />
            )}
            {typeOfTransfer === "Transfer student to another guardian" && (
              <TransferStudentToGuardian />
            )}
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default Transfers;

const options = [
  { label: "Transfer guardian to another station" },
  { label: "Transfer student to another guardian" },
];
