import { React, useState, useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Add from "@mui/icons-material/Add";
import Button from "@mui/joy/Button";
import ConfirmDialog from "./ConfirmDialog";
import Notification from "./Notification";
import AddPopup from "../popups/AddPopup";
import AddUser from "../forms/AddUser";
import RDataGrid from "./reusables/RDataGrid";
import PageLoader from "./reusables/PageLoader";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PersonIcon from "@mui/icons-material/Person";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import KeyIcon from "@mui/icons-material/Key";
import { LightTooltip } from "../tooltips/ToolTip";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SMSBlacklist from "../information/SMSBlacklist";
import PeopleIcon from "@mui/icons-material/People";
import TripStudentsDetails from "./TripStudentsDetails";
import DriverCar from "./DriverCar";

const Users = () => {
  const [isFetchingUsers, setIsFetchingUsers] = useState(true);
  const [users, setUsers] = useState([]);
  const USERS_URL = "api/v1/users";
  const axiosPrivate = useAxiosPrivate();
  const [openPopup, setOpenPopup] = useState(false);
  const [openSMSBlacklistPopup, setOpenSMSBlacklistPopup] = useState(false);
  const [openGuardianStudents, setOpenGuardianStudents] = useState(false);
  const [openDriverCar, setOpenDriverCar] = useState(false);
  const [carId, setCarId] = useState(false);
  const [driverId, setDriverId] = useState(false);
  const [refreshUsers, setSetRefreshUsers] = useState(null);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [guardianId, setGuardianId] = useState(0);

  // Edit user
  const [editUser, setEditUser] = useState(false);
  const [viewUser, setViewUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const navigate = useNavigate();
  const { auth } = useAuth();

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const DELETE_URL = "/api/v1/users/";
  const onDelete = async (id) => {
    setBackdropOpen(true);
    const controller = new AbortController();
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    try {
      const response = await axiosPrivate.delete(DELETE_URL + id, {
        signal: controller.signal,
      });
      var message = response.data.message;
      setNotify({
        isOpen: true,
        message: message,
        type: "success",
      });
      setSetRefreshUsers(new Date());
      setBackdropOpen(false);
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      setBackdropOpen(false);
      setConfirmDialog({
        isOpen: true,
        title: "Failed To Delete User",
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: () => {
          onDelete(id);
        },
      });
    }
  };

  const RESEND_DEFAULT_PASSWORD_URL = "/api/v1/users/resend_default_password/";
  const resendDefaultPassword = async (id) => {
    //const controller = new AbortController();
    setBackdropOpen(true);

    try {
      const response = await axiosPrivate.post(
        RESEND_DEFAULT_PASSWORD_URL + id,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      var message = response.data.message;
      setBackdropOpen(false);
      setNotify({
        isOpen: true,
        message: message,
        type: "success",
      });
    } catch (error) {
      setBackdropOpen(false);
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      setConfirmDialog({
        isOpen: true,
        title: "Failed To Resend Default Password",
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: () => {
          onDelete(id);
        },
      });
    }
  };

  useEffect(() => {
    if (!isFetchingUsers) {
      setIsFetchingUsers(true);
    }
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(USERS_URL, {
          signal: controller.signal,
        });
        isMounted && setUsers(response.data.data);
        setIsFetchingUsers(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setIsFetchingUsers(false);
      }
    };

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [refreshUsers]);

  const columns = useMemo(
    () => [
      { field: "id", headerName: "Id", width: 60 },
      {
        field: "firstName",
        headerName: "First Name",
        width: 115,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "lastName",
        headerName: "Last Name",
        width: 115,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "email",
        headerName: "Email",
        width: 200,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "phone",
        headerName: "Phone",
        width: 140,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "roles",
        headerName: "Role(s)",
        align: "center",
        headerAlign: "center",
        width: 110,
        renderCell: function (params) {
          const roles = params.row.roles;
          return roles?.map((elem) => elem.name).join(",");
        },
      },
      {
        field: "emailVerified",
        headerName: "Email Verified",
        width: 140,
        type: "boolean",
        editable: false,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const emailVerified = params.row.emailVerified;
          return emailVerified ? "True" : "False";
        },
      },
      {
        field: "enabled",
        headerName: "Active",
        width: 140,
        type: "boolean",
        editable: false,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const enabled = params.row.enabled;
          return enabled ? "True" : "False";
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        align: "center",
        headerAlign: "center",
        width: 100,
        sortable: false,
        disableClickEventBubbling: true,
        cellClassName: "actions",
        getActions: (params) => {
          const roles = params.row.roles;
          const role = roles[0].name;
          const resendDP = params.row.defaultPasswordUpdated;
          return [
            <LightTooltip title="Edit user">
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={function () {
                  const currentRow = params.row;
                  const selectedUser = JSON.stringify(currentRow, null, 4);
                  setViewUser(false);
                  setEditUser(true);
                  setSelectedUser(selectedUser);
                  return setOpenPopup(true);
                }}
                color="success"
              />
            </LightTooltip>,
            <LightTooltip title="Delete user">
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                color="error"
                onClick={function () {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure you want to delete this user?",
                    subTitle: "You can't undo this operation",
                    action: "Delete",
                    onConfirm: () => {
                      onDelete(params.row.id);
                    },
                  });
                }}
              />
            </LightTooltip>,
            <GridActionsCellItem
              icon={<PersonIcon />}
              label={
                auth.id === params.row.id ? "View My Profile" : "View User"
              }
              onClick={function () {
                if (auth.id === params.row.id) {
                  navigate("/profile", {
                    replace: true,
                  });
                } else {
                  const currentRow = params.row;
                  const selectedUser = JSON.stringify(currentRow, null, 4);
                  setViewUser(true);
                  setEditUser(true);
                  setSelectedUser(selectedUser);
                  return setOpenPopup(true);
                }
              }}
              color="inherit"
              showInMenu
            />,
            <GridActionsCellItem
              icon={<AdminPanelSettingsIcon />}
              label="Change Role(s)"
              onClick={function () {
                alert(`Coming soon`);
              }}
              color="inherit"
              showInMenu
            />,
            ...(role === `DRIVER`
              ? [
                  <GridActionsCellItem
                    icon={<DirectionsBusIcon />}
                    label="View Car"
                    onClick={function () {
                      const driverId = params.row.id;
                      const carId = params.row.car.id;
                      setDriverId(driverId);
                      setCarId(carId);
                      return setOpenDriverCar(true);
                    }}
                    color="inherit"
                    showInMenu
                  />,
                ]
              : []),
            ...(role === `GUARDIAN`
              ? [
                  <GridActionsCellItem
                    icon={<PeopleIcon />}
                    label="View Student(s)"
                    onClick={function () {
                      const id = params.row.id;
                      setGuardianId(id);
                      return setOpenGuardianStudents(true);
                    }}
                    color="inherit"
                    showInMenu
                  />,
                ]
              : []),
            ...(!resendDP
              ? [
                  <GridActionsCellItem
                    icon={<KeyIcon />}
                    label="Resend Password SMS"
                    onClick={function () {
                      const id = params.row.id;
                      setSelectedUserId(id);
                      return setOpenSMSBlacklistPopup(true);
                    }}
                    color="inherit"
                    showInMenu
                  />,
                ]
              : []),
          ];
        },
      },
    ],
    []
  );

  // Close dialog upon successful user add/update
  const closeDialog = (message) => {
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: message,
      type: "success",
    });
    setSetRefreshUsers(new Date());
  };

  // Close sms blacklist dialog and resend SMS
  const closeBlackListDialog = () => {
    setOpenSMSBlacklistPopup(false);
    if (selectedUserId === 0) {
      setConfirmDialog({
        isOpen: true,
        title: "Failed To Resend SMS",
        subTitle: "An error occurred. Please try again later",
        action: "Retry",
        onConfirm: () => {
          setOpenSMSBlacklistPopup(true);
        },
      });
    } else {
      resendDefaultPassword(selectedUserId);
    }
  };

  return isFetchingUsers ? (
    <PageLoader title="Loading users..." />
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box height={40} />

      {users?.length ? (
        <Box
          sx={{
            height: 560,
            width: "100%",
          }}
        >
          <div style={divStyle}>
            <Typography
              variant="h4"
              component="h4"
              sx={{ textAlign: "left", mt: 3, mb: 3 }}
            >
              Manage Users
            </Typography>
            <Button
              size="lg"
              variant="solid"
              color="primary"
              onClick={function () {
                setEditUser(false);
                setSelectedUser(null);
                setOpenPopup(true);
              }}
              startDecorator={<Add />}
            >
              Add User
            </Button>
          </div>
          <Box sx={{ width: 1218, margin: "0 auto 16px", height: 560 }}>
            <RDataGrid columns={columns} rows={users} rowHeight={63} />
          </Box>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <AddPopup
            width="md"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            title={
              viewUser
                ? "View (Edit) User"
                : editUser
                ? "Edit (View) User"
                : "Add User"
            }
          >
            <AddUser
              closeDialog={closeDialog}
              editUser={editUser}
              selectedUser={selectedUser}
            />
          </AddPopup>
          <AddPopup
            width="md"
            openPopup={openSMSBlacklistPopup}
            setOpenPopup={setOpenSMSBlacklistPopup}
            title="Resend Password SMS"
          >
            <SMSBlacklist closeBlackListDialog={closeBlackListDialog} />
          </AddPopup>
          <AddPopup
            width="xl"
            openPopup={openGuardianStudents}
            setOpenPopup={setOpenGuardianStudents}
            title="Guardian Students"
          >
            <TripStudentsDetails source={"guardian"} guardianId={guardianId} />
          </AddPopup>
          <AddPopup
            width="md"
            openPopup={openDriverCar}
            setOpenPopup={setOpenDriverCar}
            title="Driver's Car Details"
          >
            <DriverCar carId={carId} driverId={driverId} />
          </AddPopup>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      ) : (
        <Typography
          variant="h4"
          component="h4"
          sx={{ textAlign: "center", mt: 3, mb: 3 }}
        >
          No users to display
        </Typography>
      )}
    </Box>
  );
};

export default Users;
