import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { Form, Formik } from "formik";
import Notification from "../components/Notification";
import ConfirmDialog from "../components/ConfirmDialog";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { LightTooltip } from "../tooltips/ToolTip";
import Box from "@mui/material/Box";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import dayjs from "dayjs";
import { Typography } from "@mui/material";

const AddPickUpTime = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const { closeDialog, editPickUp, selectedPickUpTime } = props;

  const [time, setTime] = useState("");
  const TIME_FORMAT = "hh:mm A";

  var pickUpTimeInfo = null;
  var existingId = null;
  var station = null;
  var route = null;
  var stationId = 0;
  var tripType = null;
  var tripTypeId = 0;
  var existingTime = null;
  var defaultTime = "";
  if (editPickUp) {
    pickUpTimeInfo = JSON.parse(selectedPickUpTime);
    existingId = pickUpTimeInfo.id;
    station = pickUpTimeInfo.station;
    route = station.route;
    stationId = station.id;
    tripType = pickUpTimeInfo.tripType;
    existingTime = pickUpTimeInfo.time;
    tripTypeId = tripType.id;

    // Parse time
    var todayDate = moment(new Date()).format("YYYY-MM-DD");
    defaultTime = todayDate + " " + existingTime;
  }

  const resetForm = (props) => {
    // clear input fields
    var now = new Date();
    setSelectedStationKey(now);
    setSelectedTripTypeKey(now + "a");
    setSelectedStation(0);
    setSelectedTripType(0);
    setTime("");
    setSelectedTimeKey(new Date() + "b");
    setIsInvalidTime(false);
    setRouteKey(new Date() + "c");
    setSelectedRoute(0);
  };

  const closeForm = (message) => {
    closeDialog(message);
  };

  // Routes
  const [routeOpen, setRouteOpen] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(editPickUp ? route.id : 0);
  const [routesLoading, setRoutesLoading] = useState(
    routeOpen && routes.length === 0
  );
  const [routeEmpty, setRouteEmpty] = useState(false);
  const [routeKey, setRouteKey] = useState("routeKey");
  const ROUTES_URL = "/api/v1/routes";
  const handleRouteChange = (event, value) => {
    setSelectedRoute(value.id);
    setTripTypes(value.tripTypes);
    setRouteEmpty(false);

    var now = new Date();
    setSelectedStationKey(now);
    setSelectedTripTypeKey(now + "a");
    setSelectedStation(0);
    setSelectedTripType(0);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!routesLoading) {
      return undefined;
    }

    const getRoutes = async () => {
      try {
        const response = await axiosPrivate.get(ROUTES_URL, {
          signal: controller.signal,
        });
        isMounted && setRoutes(response.data.data);
        setRoutesLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setRoutesLoading(false);
      }
    };

    getRoutes();

    return () => {
      isMounted = false;
      controller.abort();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [routesLoading]);

  React.useEffect(() => {
    if (!routeOpen) {
      setRoutes([]);
    }
  }, [routeOpen]);

  // Station
  const [stationOpen, setStationOpen] = useState(false);
  const [stations, setStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState(
    editPickUp ? stationId : 0
  );
  //const stationLoading = stationOpen && stations.length === 0;
  const [stationLoading, setStationLoading] = useState(
    stationOpen && stations.length === 0
  );
  const [stationEmpty, setStationEmpty] = useState(false);
  const [selectedTripTypeKey, setSelectedStationKey] = useState("stationKey");
  const STATIONS_URL = "/api/v1/stations/route/";
  const handleStationChange = (event, value) => {
    setSelectedStation(value.id);
    setStationEmpty(false);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!stationLoading) {
      return undefined;
    }

    const getStations = async () => {
      try {
        const response = await axiosPrivate.get(STATIONS_URL + selectedRoute, {
          signal: controller.signal,
        });
        isMounted && setStations(response.data.data);
        setStationLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setStationLoading(false);
      }
    };

    getStations();

    return () => {
      isMounted = false;
      controller.abort();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [stationLoading]);

  React.useEffect(() => {
    if (!stationOpen) {
      setStations([]);
    }
  }, [stationOpen]);

  // Trip-type
  const [tripTypes, setTripTypes] = useState([]);
  const [selectedTripType, setSelectedTripType] = useState(
    editPickUp ? tripTypeId : 0
  );
  const [tripTypesEmpty, setTripTypesEmpty] = useState(false);
  const [selectedStationKey, setSelectedTripTypeKey] = useState("tripTypeKey");
  const handleTriTypesChange = (event, value) => {
    setSelectedTripType(value.id);
    setTripTypesEmpty(false);
  };

  // Time
  const [isInvalidTime, setIsInvalidTime] = useState(false);
  const [selectedTimeKey, setSelectedTimeKey] = useState("timeKey");

  const CREATE_PICK_TIME = "/api/v1/pickuptimes/save";
  const UPDATE_PICK_TIME = "/api/v1/pickuptimes/";
  const onSubmit = async (values, props) => {
    if (selectedRoute === 0) {
      // No route picked
      setRouteEmpty(selectedRoute === 0);
      return;
    }

    if (selectedStation === 0) {
      // No station picked
      setStationEmpty(selectedStation === 0);
      return;
    }

    if (selectedTripType === 0) {
      // No trip-type picked
      setTripTypesEmpty(selectedTripType === 0);
      return;
    }

    const isValidTime = moment(time, TIME_FORMAT, true).isValid();
    if (!isValidTime) {
      // Invalid time selected
      setIsInvalidTime(true);
      return;
    }

    props.setSubmitting(true);

    // Common
    const pickUpTimeData = {
      stationId: selectedStation,
      tripTypeId: selectedTripType,
      time: time,
    };

    try {
      const response = editPickUp
        ? await axiosPrivate.put(
            UPDATE_PICK_TIME + existingId,
            pickUpTimeData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
        : await axiosPrivate.post(CREATE_PICK_TIME, pickUpTimeData, {
            headers: {
              "Content-Type": "application/json",
            },
          });

      console.log(response.data.message);
      props.setSubmitting(false);
      resetForm(props);
      closeForm(response.data.message);
      console.log(6);
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      const errorTitle = editPickUp
        ? "Failed To Update Pick-Up Time"
        : "Failed To Create Pick-Up Time";
      setConfirmDialog({
        isOpen: true,
        title: errorTitle,
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const initialValues = {
    stationId: 0,
    tripTypeId: 0,
    time: "",
  };

  return (
    <Grid>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {(props) => (
          <Form>
            <Stack spacing={2} mt={3} direction="column">
              <Autocomplete
                id="routeId"
                fullWidth
                placeholder="Select route"
                label="Route"
                defaultValue={route}
                onChange={handleRouteChange}
                key={routeKey}
                open={routeOpen}
                onOpen={() => {
                  setRoutesLoading(true);
                  setRouteOpen(true);
                }}
                onClose={() => {
                  setRoutesLoading(false);
                  setRouteOpen(false);
                }}
                isOptionEqualToValue={(route, value) =>
                  route.name === value.name
                }
                getOptionLabel={(route) => route.name}
                options={routes}
                loading={routesLoading}
                loadingText="Loading routes..."
                noOptionsText="No routes"
                renderOption={(props, route) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {route.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Route"
                    name="routeId"
                    placeholder="Select route"
                    error={routeEmpty}
                    helperText={routeEmpty ? "Route required" : ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {routesLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />

              <Autocomplete
                id="stationId"
                placeholder="Select station"
                label="Station"
                fullWidth
                defaultValue={station}
                onChange={handleStationChange}
                key={selectedStationKey}
                open={stationOpen}
                onOpen={() => {
                  setStationLoading(true);
                  setStationOpen(true);
                }}
                onClose={() => {
                  setStationLoading(false);
                  setStationOpen(false);
                }}
                isOptionEqualToValue={(station, value) =>
                  station.name === value.name
                }
                getOptionLabel={(station) => station.name}
                options={stations}
                loading={stationLoading}
                loadingText="Loading stations..."
                noOptionsText="No stations"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Station"
                    name="stationId"
                    placeholder="Select station"
                    error={stationEmpty}
                    helperText={stationEmpty ? "Station required" : ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {stationLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />

              <Autocomplete
                id="tripTypeId"
                placeholder="Select trip-type"
                label="Trip-type"
                defaultValue={tripType}
                onChange={handleTriTypesChange}
                key={selectedTripTypeKey}
                fullWidth
                isOptionEqualToValue={(tripType, value) =>
                  tripType.name === value.name
                }
                getOptionLabel={(tripType) => tripType.name}
                options={tripTypes}
                renderOption={(props, tripType) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {tripType.name} at {tripType.dayTime.time}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Trip-type"
                    name="tripTypeId"
                    placeholder="Select trip-type"
                    error={tripTypesEmpty}
                    helperText={tripTypesEmpty ? "Trip-type required" : ""}
                  />
                )}
              />

              {/* <Autocomplete
                id="tripTypeId"
                placeholder="Select trip-type"
                label="Trip-type"
                fullWidth
                defaultValue={tripType}
                onChange={handleTriTypesChange}
                key={selectedTripTypeKey}
                open={tripTypeOpen}
                onOpen={() => {
                  setTripTypeOpen(true);
                }}
                onClose={() => {
                  setTripTypeOpen(false);
                }}
                isOptionEqualToValue={(tripType, value) =>
                  tripType.name === value.name
                }
                getOptionLabel={(tripType) => tripType.name}
                options={tripTypes}
                loading={tripTypesLoading}
                renderOption={(props, tripType) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {tripType.name} at {tripType.dayTime.time}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Trip-type"
                    name="tripTypeId"
                    placeholder="Select trip-type"
                    error={tripTypesEmpty}
                    helperText={tripTypesEmpty ? "Trip-type required" : ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {tripTypesLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              /> */}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Pick-up/Drop-off time"
                  name="time"
                  id="time"
                  defaultValue={editPickUp ? dayjs(defaultTime) : null}
                  key={selectedTimeKey}
                  onChange={(date) => {
                    var time = date?.format(TIME_FORMAT);
                    setTime(time);
                    setIsInvalidTime(false);
                  }}
                  {...(isInvalidTime ? { error: true } : {})}
                />
              </LocalizationProvider>
            </Stack>

            {isInvalidTime && (
              <Typography
                color="error"
                sx={{ textAlign: "left", mt: 0, mb: 2 }}
              >
                Select a valid time
              </Typography>
            )}

            <Stack spacing={2} mt={3} direction="row" justifyContent="flex-end">
              <LightTooltip title="Clear form">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 8,
                    width: 120,
                    backgroundColor: "#808080",
                    padding: "7px 14px",
                    fontSize: "14px",
                    color: "white",
                    ":hover": {
                      bgcolor: "#696969",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    resetForm(props);
                  }}
                >
                  Reset
                </Button>
              </LightTooltip>

              <LightTooltip title="Submit details">
                <Button
                  loading={props.isSubmitting}
                  loadingPosition="start"
                  disabled={props.isSubmitting}
                  type="submit"
                  sx={{
                    width: 120,
                  }}
                  variant="solid"
                >
                  {props.isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </LightTooltip>
            </Stack>
          </Form>
        )}
      </Formik>
      {/* </Paper> */}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
};

export default AddPickUpTime;
