import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

const StationLocation = () => {
  const containerStyle = {
    width: "1000px",
    height: "1000px",
  };

  const center = {
    lat: -1.286389,
    lng: 36.817223,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBEq8Kvs6t_JVXy83eQdiVIS7pG7217xoI",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <h1>Loading...</h1>
  );
};

export default StationLocation;
