import { React } from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function RDataGrid(props) {
  const { columns, rows, rowHeight, columnVisibilityModel } = props;

  const datagridSx = {
    borderRadius: 2,
    /* "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .MuiDataGrid-row": {
        "&:nth-child(2n)": {
          backgroundColor: "rgba(165, 215, 232, .07)",
          padding: 0,
        },
      },
    }, */
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "rgba(67, 176, 42, .1)",
      color: "rgba(0, 43, 91)",
      fontSize: 18,
      fontWeight: "bold",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "rgba(165, 215, 232, .07)",
    },
  };

  return (
    <DataGrid
      columns={columns}
      rowHeight={rowHeight}
      rows={rows}
      getRowId={(row) => row.id}
      autoPageSize
      sx={datagridSx}
      columnVisibilityModel={columnVisibilityModel}
      pagination
      {...rows}
      getRowSpacing={(params) => ({
        top: params.isFirstVisible ? 0 : 0,
        bottom: params.isLastVisible ? 0 : 0,
      })}
    />
  );
}
