import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Notification from "../components/Notification";
import ConfirmDialog from "../components/ConfirmDialog";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { LightTooltip } from "../tooltips/ToolTip";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

const AddUser = (props) => {
  const { closeDialog, editUser, selectedUser } = props;

  const GUARDIAN = "GUARDIAN";
  const DRIVER = "DRIVER";
  const DUMMY_PASS = "**************";
  var userInfo = null;
  var userId = 0;
  var role = null;
  var station = null;
  var roleName = null;
  var route = null;
  var car = null;
  var stationId = 0;
  var routeId = 0;
  var carId = 0;
  var locked = false;
  var enabled = false;
  var emailVerified = false;
  var defaultPasswordUpdated = false;
  var agreedToTerms = false;
  var loggedOut = false;
  var accountExpired = false;
  var credentialsExpired = false;
  if (editUser) {
    userInfo = JSON.parse(selectedUser);
    userId = userInfo.id;
    role = userInfo.roles[0];
    roleName = role.name;
    locked = userInfo.locked;
    enabled = userInfo.enabled;
    emailVerified = userInfo.emailVerified;
    defaultPasswordUpdated = userInfo.defaultPasswordUpdated;
    agreedToTerms = userInfo.agreedToTerms;
    loggedOut = userInfo.loggedOut;
    accountExpired = userInfo.accountNonExpired;
    credentialsExpired = userInfo.credentialsNonExpired;
    if (roleName === GUARDIAN) {
      station = userInfo.station;
      stationId = station.id;
      route = station.route;
      routeId = route.id;
    }
    if (roleName === DRIVER) {
      route = userInfo.route;
      routeId = route.id;
      car = userInfo.car;
      carId = car.id;
    }
  }

  const [accLocked, setLocked] = React.useState(locked);
  const [accEnabled, setEnabled] = React.useState(enabled);
  const handleLockedChange = (event) => {
    setLocked(event.target.checked);
  };
  const handleEnabledChange = (event) => {
    setEnabled(event.target.checked);
  };

  const axiosPrivate = useAxiosPrivate();

  const resetForm = (props) => {
    // clear input fields
    props.resetForm();
    setSelectedRole("");
    setSelectedRoleKey(new Date());
    setSelectedRoute("");
    setSelectedStation("");
    setSelectedCar("");
  };

  const closeForm = (message) => {
    closeDialog(message);
  };

  // Roles
  const [rolesOpen, setRolesOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [roleLoading, setRoleLoading] = useState(
    rolesOpen && roles.length === 0
  );
  const [selectedRole, setSelectedRole] = useState(editUser ? role.name : null);
  const [selectedRoleKey, setSelectedRoleKey] = useState("roleKey");
  const [roleEmpty, setRoleEmpty] = useState(false);
  const ROLES_URL = "/api/v1/roles";
  const handleRoleChange = (event, value) => {
    setSelectedRole(value.name);
    setRoleEmpty(false);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!roleLoading) {
      return undefined;
    }

    const getRoles = async () => {
      try {
        const response = await axiosPrivate.get(ROLES_URL, {
          signal: controller.signal,
        });
        isMounted && setRoles(response.data.data);
        setRoleLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setRoleLoading(false);
      }
    };

    getRoles();

    return () => {
      isMounted = false;
      controller.abort();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [roleLoading]);

  React.useEffect(() => {
    if (!rolesOpen) {
      setRoles([]);
    }
  }, [rolesOpen]);

  // Routes
  const [routeOpen, setRouteOpen] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(
    editUser && (roleName === GUARDIAN || roleName === DRIVER) ? routeId : 0
  );
  const [routeLoading, setRouteLoading] = useState(
    routeOpen && routes.length === 0
  );
  const [routeEmpty, setRouteEmpty] = useState(false);
  const ROUTES_URL = "/api/v1/routes";
  const handleRouteChange = (event, value) => {
    setSelectedRoute(value.id);
    setRouteEmpty(false);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!routeLoading) {
      return undefined;
    }

    const getRoutes = async () => {
      try {
        const response = await axiosPrivate.get(ROUTES_URL, {
          signal: controller.signal,
        });
        isMounted && setRoutes(response.data.data);
        setRouteLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setRouteLoading(false);
      }
    };

    getRoutes();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [routeLoading]);

  React.useEffect(() => {
    if (!routeOpen) {
      setRoutes([]);
    }
  }, [routeOpen]);

  // Stations
  const [stationOpen, setStationOpen] = useState(false);
  const [stations, setStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState(
    editUser && roleName === GUARDIAN ? stationId : 0
  );
  const [stationLoading, setStationLoading] = useState(
    stationOpen && stations.length === 0
  );
  const [stationEmpty, setStationEmpty] = useState(false);
  const STATIONS_URL = "/api/v1/stations/route/";
  const handleStationChange = (event, value) => {
    setSelectedStation(value.id);
    setStationEmpty(false);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!stationLoading) {
      return undefined;
    }

    const getStations = async () => {
      try {
        const response = await axiosPrivate.get(STATIONS_URL + selectedRoute, {
          signal: controller.signal,
        });
        isMounted && setStations(response.data.data);
        setStationLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setStationLoading(false);
      }
    };

    getStations();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [stationLoading]);

  React.useEffect(() => {
    if (!stationOpen) {
      setStations([]);
    }
  }, [stationOpen]);

  // Cars
  const [carOpen, setCarOpen] = useState(false);
  const [cars, setCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState(
    editUser && roleName === DRIVER ? carId : 0
  );
  const [carLoading, setCarLoading] = useState(carOpen && cars.length === 0);
  const [carEmpty, setCarEmpty] = useState(false);
  const CARS_URL = "/api/v1/cars";
  const handleCarChange = (event, value) => {
    setSelectedCar(value.id);
    setCarEmpty(false);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!carLoading) {
      return undefined;
    }

    const getCars = async () => {
      try {
        const response = await axiosPrivate.get(CARS_URL, {
          signal: controller.signal,
        });
        isMounted && setCars(response.data.data);
        setCarLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setCarLoading(false);
      }
    };

    getCars();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [carLoading]);

  React.useEffect(() => {
    if (!carOpen) {
      setCars([]);
    }
  }, [carOpen]);

  // Common
  const initialValues = editUser
    ? {
        editUser: editUser,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        phone: userInfo.phone,
        stationId: stationId,
        routeId: routeId,
        carId: carId,
        roleName: roleName,
      }
    : {
        editUser: false,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        stationId: 0,
        routeId: 0,
        carId: 0,
        roleName: "",
      };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Please enter valid email").required("Required"),
    phone: Yup.string()
      .required("Required")
      .matches(/^[0-9]\d{9}$/, {
        message: "Please enter valid phone number",
        excludeEmptyString: false,
      }),
    editUser: Yup.boolean(),
    /*     password: Yup.string().when("editUser", {
      is: () => false,
      then: () =>
        Yup.string()
          .required("Required")
          .min(
            8,
            "Password must contain 8 or more characters with at least one of each: Uppercase, Lowercase, Number and Special character"
          )
          .minLowercase(1, "Password must contain at least 1 Lower case letter")
          .minUppercase(1, "Password must contain at least 1 Upper case letter")
          .minNumbers(1, "Password must contain at least 1 Number")
          .minSymbols(1, "Password must contain at least 1 Special character"),
      otherwise: () => Yup.string(),
    }), */
  });

  const CREATE_USER_URL = "/api/v1/users/save";
  //const UPDATE_USER_URL = "/api/v1/users/update";
  const UPDATE_USER_URL = "/api/v1/users/";
  const onSubmit = async (values, props) => {
    // No Role picked
    if (selectedRole === null) {
      setRoleEmpty(selectedRole === null);
      return;
    }

    // GUARDIAN or DRIVER selected
    if (selectedRole === DRIVER || selectedRole === GUARDIAN) {
      if (selectedRoute === 0) {
        // ...but no route
        setRouteEmpty(selectedRoute === 0);
        return;
      }
      if (selectedRole === GUARDIAN && selectedStation === 0) {
        // ...but no station
        setStationEmpty(selectedStation === 0);
        return;
      }
      if (selectedRole === DRIVER && selectedCar === 0) {
        // ...but no car
        setCarEmpty(selectedCar === 0);
        return;
      }
    }

    props.setSubmitting(true);

    let userData = editUser
      ? selectedRole === "ADMIN" || selectedRole === "MANAGER"
        ? {
            firstName: values.firstName,
            lastName: values.lastName,
            roleName: selectedRole,
          }
        : selectedRole === "DRIVER"
        ? {
            firstName: values.firstName,
            lastName: values.lastName,
            roleName: selectedRole,
            routeId: selectedRoute,
            locked: accLocked,
            enabled: accEnabled,
          }
        : {
            firstName: values.firstName,
            lastName: values.lastName,
            roleName: selectedRole,
            locked: accLocked,
            enabled: accEnabled,
          }
      : {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          roleName: selectedRole,
          routeId: selectedRoute,
          stationId: selectedStation,
          carId: selectedCar,
        };

    try {
      const response = editUser
        ? await axiosPrivate.put(UPDATE_USER_URL + userId, userData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
        : await axiosPrivate.post(CREATE_USER_URL, userData, {
            headers: {
              "Content-Type": "application/json",
            },
          });

      //console.log(response.data.message);
      props.setSubmitting(false);
      resetForm(props);
      closeForm(response.data.message);
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      const errorTitle = editUser
        ? "Failed To Update User"
        : "Failed To Create User";
      setConfirmDialog({
        isOpen: true,
        title: errorTitle,
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  return (
    <Grid>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Stack spacing={2} mb={2} direction="row">
              <Stack direction="column">
                <Stack spacing={2} mt={3} direction="row">
                  <Field
                    as={TextField}
                    label="First Name"
                    name="firstName"
                    placeholder="Enter first name"
                    fullWidth
                    required
                    helperText={<ErrorMessage name="firstName" />}
                  />
                  <Field
                    as={TextField}
                    label="Last Name"
                    name="lastName"
                    placeholder="Enter last name"
                    fullWidth
                    required
                    helperText={<ErrorMessage name="lastName" />}
                  />
                </Stack>
                <Stack spacing={2} mt={3} direction="row">
                  <Field
                    as={TextField}
                    label="Email"
                    name="email"
                    placeholder="Enter email"
                    disabled={editUser}
                    type="email"
                    fullWidth
                    required
                    helperText={<ErrorMessage name="email" />}
                  />
                  <Field
                    as={TextField}
                    label="Phone"
                    name="phone"
                    placeholder="Enter Phone "
                    disabled={editUser}
                    fullWidth
                    required
                    helperText={<ErrorMessage name="phone" />}
                  />
                </Stack>
                <Stack spacing={2} mt={3} direction="row">
                  <Field
                    as={TextField}
                    label="Password"
                    name="password"
                    placeholder="Enter password"
                    defaultValue={DUMMY_PASS}
                    disabled={true}
                    fullWidth
                    required
                    helperText={<ErrorMessage name="password" />}
                  />

                  <Autocomplete
                    id="roleName"
                    fullWidth
                    defaultValue={role}
                    disabled={editUser}
                    onChange={handleRoleChange}
                    key={selectedRoleKey}
                    open={rolesOpen}
                    onOpen={() => {
                      setRoleLoading(true);
                      setRolesOpen(true);
                    }}
                    onClose={() => {
                      setRoleLoading(false);
                      setRolesOpen(false);
                    }}
                    isOptionEqualToValue={(role, value) =>
                      role.name === value.name
                    }
                    getOptionLabel={(role) => (role.name ? role.name : "")}
                    options={roles}
                    loading={roleLoading}
                    loadingText="Loading roles..."
                    noOptionsText="No roles"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Role"
                        name="roleName"
                        error={roleEmpty}
                        helperText={roleEmpty ? "Role required" : ""}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {roleLoading ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack spacing={2} mt={3} direction="row">
                  {(selectedRole === DRIVER || selectedRole === GUARDIAN) && (
                    <Autocomplete
                      id="routeId"
                      fullWidth
                      defaultValue={route}
                      onChange={handleRouteChange}
                      open={routeOpen}
                      onOpen={() => {
                        setRouteLoading(true);
                        setRouteOpen(true);
                      }}
                      onClose={() => {
                        setRouteLoading(false);
                        setRouteOpen(false);
                      }}
                      isOptionEqualToValue={(route, value) =>
                        route.name === value.name
                      }
                      getOptionLabel={(route) => route.name}
                      options={routes}
                      loading={routeLoading}
                      loadingText="Loading routes..."
                      noOptionsText="No routes"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Route"
                          name="routeId"
                          error={routeEmpty}
                          helperText={routeEmpty ? "Route required" : ""}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {routeLoading ? (
                                  <CircularProgress color="primary" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  )}

                  {selectedRole === DRIVER && (
                    <Autocomplete
                      id="carId"
                      fullWidth
                      defaultValue={car}
                      onChange={handleCarChange}
                      open={carOpen}
                      disabled={editUser}
                      onOpen={() => {
                        setCarLoading(true);
                        setCarOpen(true);
                      }}
                      onClose={() => {
                        setCarLoading(false);
                        setCarOpen(false);
                      }}
                      isOptionEqualToValue={(car, value) =>
                        car.name === value.name
                      }
                      getOptionLabel={(car) => car.name}
                      options={cars}
                      loading={carLoading}
                      loadingText="Loading cars..."
                      noOptionsText="No cars"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Car"
                          name="carId"
                          error={carEmpty}
                          helperText={carEmpty ? "Car required" : ""}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {carLoading ? (
                                  <CircularProgress color="primary" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  )}

                  {selectedRole === GUARDIAN && (
                    <Autocomplete
                      id="stationId"
                      fullWidth
                      defaultValue={station}
                      onChange={handleStationChange}
                      open={stationOpen}
                      disabled={editUser}
                      onOpen={() => {
                        setStationLoading(true);
                        setStationOpen(true);
                      }}
                      onClose={() => {
                        setStationLoading(false);
                        setStationOpen(false);
                      }}
                      isOptionEqualToValue={(station, value) =>
                        station.name === value.name
                      }
                      getOptionLabel={(station) => station.name}
                      options={stations}
                      loading={stationLoading}
                      loadingText="Loading stations..."
                      noOptionsText="No stations"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Station"
                          name="stationId"
                          error={stationEmpty}
                          helperText={stationEmpty ? "Car required" : ""}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {stationLoading ? (
                                  <CircularProgress color="primary" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                </Stack>
              </Stack>

              {editUser &&
                (selectedRole === DRIVER || selectedRole === GUARDIAN) && (
                  <Stack direction="column" pl={2}>
                    <Stack mt={2.5} direction="column">
                      <Stack spacing={2} direction="row">
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={false}
                              checked={accLocked}
                              onChange={handleLockedChange}
                            />
                          }
                          label="Account Locked"
                        />

                        <FormControlLabel
                          control={
                            <Switch
                              disabled={false}
                              checked={accEnabled}
                              onChange={handleEnabledChange}
                            />
                          }
                          label="Account Enabled"
                        />
                      </Stack>

                      <Stack spacing={6} mt={6} direction="row">
                        <FormControlLabel
                          control={
                            <Switch disabled={true} checked={emailVerified} />
                          }
                          label="Email verified"
                        />

                        <FormControlLabel
                          control={
                            <Switch
                              disabled={true}
                              checked={defaultPasswordUpdated}
                            />
                          }
                          label="Default password updated"
                        />
                      </Stack>
                      <Stack spacing={2} mt={6} direction="row">
                        <FormControlLabel
                          control={
                            <Switch disabled={true} checked={agreedToTerms} />
                          }
                          label="Agreed to terms"
                        />

                        <FormControlLabel
                          control={
                            <Switch disabled={true} checked={loggedOut} />
                          }
                          label="Logged out"
                        />
                      </Stack>
                      <Stack spacing={2} mt={6} direction="row">
                        <FormControlLabel
                          control={
                            <Switch disabled={true} checked={!accountExpired} />
                          }
                          label="Account expired"
                        />

                        <FormControlLabel
                          control={
                            <Switch
                              disabled={true}
                              checked={!credentialsExpired}
                            />
                          }
                          label="Credentials expired"
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
            </Stack>

            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <LightTooltip title="Clear form">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 8,
                    width: 120,
                    backgroundColor: "#808080",
                    padding: "7px 14px",
                    fontSize: "14px",
                    color: "white",
                    ":hover": {
                      bgcolor: "#696969",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    resetForm(props);
                  }}
                >
                  Reset
                </Button>
              </LightTooltip>

              <LightTooltip title="Submit details">
                <Button
                  loading={props.isSubmitting}
                  loadingPosition="start"
                  disabled={props.isSubmitting}
                  type="submit"
                  sx={{
                    width: 120,
                  }}
                  variant="solid"
                >
                  {props.isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </LightTooltip>
            </Stack>
          </Form>
        )}
      </Formik>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
};

export default AddUser;
