import { useState } from "react";
import * as React from "react";
import { Grid, Autocomplete, TextField, Divider } from "@mui/material";
import { Form, Formik } from "formik";
import Box from "@mui/material/Box";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Typography from "@mui/material/Typography";
import Button from "@mui/joy/Button";
import PageLoader from "./reusables/PageLoader";
import Stack from "@mui/joy/Stack";
import { LightTooltip } from "../tooltips/ToolTip";
import CircularProgress from "@mui/material/CircularProgress";
import Notification from "../components/Notification";
import ConfirmDialog from "../components/ConfirmDialog";

function DriverCar(props) {
  const { driverId } = props;

  const [name, setName] = useState("");
  const [regNo, setRegNo] = useState("");
  const [sittingCapacity, setSittingCapacity] = useState("");
  const [driverName, setDriverName] = useState("");
  const [refreshProfile, setSetRefreshProfile] = useState(null);
  const [isFetchingProfile, setIsFetchingProfile] = useState(true);
  const [hasNoCar, setHasNoCar] = useState(false);
  const [defaultCar, setDefaultCar] = useState(null);
  const [selectedCarId, setSelectedCarId] = useState(0);
  const [carEmpty, setCarEmpty] = useState(false);
  const [carOpen, setCarOpen] = useState(false);
  const [cars, setCars] = useState([]);
  const [carLoading, setCarLoading] = useState(carOpen && cars.length === 0);
  const [showCarChange, setShowCarChange] = useState(false);

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const axiosPrivate = useAxiosPrivate();

  const onCarChange = (event, value) => {
    setSelectedCarId(value.id);
    setCarEmpty(false);
  };

  // Fetch user profile
  const USER_URL = "api/v1/users/";
  React.useEffect(() => {
    if (!isFetchingProfile) {
      setIsFetchingProfile(true);
    }
    //let isMounted = true;
    const controller = new AbortController();

    const getDriverCar = async () => {
      try {
        const response = await axiosPrivate.get(`${USER_URL}${driverId}`, {
          signal: controller.signal,
        });
        const car = response.data.data.car;
        if (car === null) {
          setHasNoCar(true);
          return undefined;
        }
        const cCar = JSON.stringify(car, null, 4);
        const mCar = JSON.parse(cCar);
        setDefaultCar(mCar);
        setName(mCar.name);
        setRegNo(mCar.regNo);
        setDriverName(
          `${response.data.data.firstName} ${response.data.data.lastName}`
        );
        setSittingCapacity(mCar.sittingCapacity);
        setIsFetchingProfile(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setIsFetchingProfile(false);
      }
    };

    getDriverCar();

    return () => {
      //isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [refreshProfile]);

  // Fetch cars
  const CARS_URL = "/api/v1/cars";
  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!carLoading) {
      return undefined;
    }

    const getCars = async () => {
      try {
        const response = await axiosPrivate.get(CARS_URL, {
          signal: controller.signal,
        });
        isMounted && setCars(response.data.data);
        setCarLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setCarLoading(false);
      }
    };

    getCars();

    return () => {
      isMounted = false;
      controller.abort();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [carLoading]);

  React.useEffect(() => {
    if (!carOpen) {
      setCars([]);
    }
  }, [carOpen]);

  // Submit car change
  const CHANGE_CAR_URL = "/api/v1/users/assign/car";
  const onSubmit = async (values, props) => {
    // No car picked
    if (selectedCarId === 0) {
      setCarEmpty(true);
      return;
    }

    props.setSubmitting(true);

    let data = {
      carId: selectedCarId,
      driverId: driverId,
    };

    try {
      const response = await axiosPrivate.put(CHANGE_CAR_URL, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      //console.log(response.data.message);
      const message = response.data.message;
      setNotify({
        isOpen: true,
        message: message,
        type: "success",
      });
      props.setSubmitting(false);
      setSetRefreshProfile(new Date());
      setShowCarChange(false);
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      const errorTitle = "Failed To Change Car";
      setConfirmDialog({
        isOpen: true,
        title: errorTitle,
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
      props.setSubmitting(false);
    }
  };

  const initialValues = {
    carId: 0,
    driverId: 0,
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  return isFetchingProfile ? (
    <PageLoader title="Loading driver car..." />
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      {hasNoCar ? (
        <div style={divStyle}>
          <Typography
            variant="h5"
            component="h5"
            sx={{ textAlign: "center", mt: 3, mb: 3 }}
          >
            Driver has no car
          </Typography>
        </div>
      ) : (
        <Grid>
          <Stack
            spacing={2}
            mb={2}
            direction="column"
            sx={{ mt: 0, minWidth: 320 }}
          >
            <Typography
              sx={{ textAlign: "left", mt: 1, mb: 0 }}
              gutterBottom
              variant="body2"
            >
              <strong>Driver Name:</strong> {driverName}
            </Typography>
            <Typography
              sx={{ textAlign: "left", mt: 3, mb: 3 }}
              gutterBottom
              variant="body2"
            >
              <strong>Car Name:</strong> {name}
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ textAlign: "left", mt: 3, mb: 3 }}
            >
              <strong>Car Reg. No:</strong> {regNo}
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ textAlign: "left", mt: 3, mb: 3 }}
            >
              <strong>Car Sitting Capacity:</strong> {sittingCapacity}
            </Typography>
          </Stack>

          {!showCarChange && (
            <Stack direction="row" justifyContent="flex-end">
              <LightTooltip title="Change driver's car">
                <Button
                  sx={{
                    width: 120,
                  }}
                  variant="solid"
                  onClick={() => {
                    setShowCarChange(true);
                  }}
                >
                  Change Car
                </Button>
              </LightTooltip>
            </Stack>
          )}

          {showCarChange && (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={onSubmit}
            >
              {(props) => (
                <Form>
                  <Stack spacing={2} mt={4} direction="column">
                    <Divider>Select The Car</Divider>
                    <Autocomplete
                      id="carId"
                      fullWidth
                      placeholder="Select car"
                      label="Car"
                      defaultValue={defaultCar}
                      onChange={onCarChange}
                      open={carOpen}
                      onOpen={() => {
                        setCarLoading(true);
                        setCarOpen(true);
                      }}
                      onClose={() => {
                        setCarLoading(false);
                        setCarOpen(false);
                      }}
                      isOptionEqualToValue={(car, value) =>
                        `${car.regNo} - ${car.name}` ===
                        `${value.regNo} - ${value.name}`
                      }
                      getOptionDisabled={(car) => car.id === defaultCar.id}
                      getOptionLabel={(car) => `${car.regNo} - ${car.name}`}
                      options={cars}
                      loading={carLoading}
                      loadingText="Loading cars..."
                      noOptionsText="No cars"
                      renderOption={(props, car) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {car.regNo} - {car.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Car"
                          name="carId"
                          placeholder="Select car"
                          error={carEmpty}
                          helperText={carEmpty ? "Car required" : ""}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {carLoading ? (
                                  <CircularProgress color="primary" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Stack>

                  <Stack
                    spacing={2}
                    mt={3}
                    direction="row"
                    justifyContent="flex-end"
                  >
                    <LightTooltip title="Clear form">
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: 8,
                          width: 120,
                          backgroundColor: "#808080",
                          padding: "7px 14px",
                          fontSize: "14px",
                          color: "white",
                          ":hover": {
                            bgcolor: "#696969",
                            color: "white",
                          },
                        }}
                        onClick={() => {
                          setShowCarChange(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </LightTooltip>

                    <LightTooltip title="Submit details">
                      <Button
                        loading={props.isSubmitting}
                        loadingPosition="start"
                        disabled={props.isSubmitting}
                        type="submit"
                        sx={{
                          width: 120,
                        }}
                        variant="solid"
                      >
                        {props.isSubmitting ? "Submitting" : "Submit"}
                      </Button>
                    </LightTooltip>
                  </Stack>
                </Form>
              )}
            </Formik>
          )}
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </Grid>
      )}
    </Box>
  );
}

export default DriverCar;
