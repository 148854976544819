import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import Notification from "../components/Notification";
import ConfirmDialog from "../components/ConfirmDialog";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { LightTooltip } from "../tooltips/ToolTip";

const AddTripTypeToRoute = (props) => {
  const { closeDialog, removeTripType, selectedRoute } = props;
  //console.log("selected route --> " + selectedRoute);

  var routeInfo = JSON.parse(selectedRoute);
  var routeId = routeInfo.id;
  var routeName = routeInfo.name;
  var existingTripTypes = routeInfo.tripTypes;
  var existingTripTypeIds = [];
  for (const eTripType of existingTripTypes) {
    existingTripTypeIds.push(eTripType.id);
  }

  /* if (editRoute) {
    routeInfo = JSON.parse(selectedRoute);
    routeId = routeInfo.id;
    routeName = routeInfo.name;
    existingTripTypes = routeInfo.tripTypes;
    for (const eTripType of existingTripTypes) {
      existingTripTypeIds.push(eTripType.id);
    }
  } */

  const axiosPrivate = useAxiosPrivate();

  const resetForm = (props) => {
    // clear input fields
    //props.resetForm();
    setSelectedTripTypeKey(new Date());
  };

  const closeForm = (message) => {
    closeDialog(message);
  };

  // Trip-types
  const [tripTypeOpen, setTripTypeOpen] = useState(false);
  const [tripTypes, setTripTypes] = useState([]);
  const [tripTypeLoading, setTripTypeLoading] = useState(
    tripTypeOpen && tripTypes.length === 0
  );
  const [selectedTripTypeId, setSelectedTripTypeId] = useState(0);
  const [selectedTripTypeKey, setSelectedTripTypeKey] = useState("routeKey");
  const [tripTypeEmpty, setTripTypeEmpty] = useState(false);

  const handleTripTypeChange = (event, tripType) => {
    setSelectedTripTypeId(tripType.id);
    setTripTypeEmpty(false);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!tripTypeLoading) {
      return undefined;
    }

    const TRIP_TYPES_URL = "/api/v1/triptypes";
    const getTripTypes = async () => {
      try {
        const response = await axiosPrivate.get(TRIP_TYPES_URL, {
          signal: controller.signal,
        });

        var allTripTypes = response.data.data;
        var newTripTypes = [];

        for (const aTripType of allTripTypes) {
          if (!existingTripTypeIds.includes(aTripType.id)) {
            newTripTypes.push(aTripType);
          }
        }

        isMounted && removeTripType
          ? setTripTypes(existingTripTypes)
          : setTripTypes(newTripTypes);
        setTripTypeLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setTripTypeLoading(false);
      }
    };

    getTripTypes();

    return () => {
      isMounted = false;
      controller.abort();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [tripTypeLoading]);

  React.useEffect(() => {
    if (!tripTypeOpen) {
      setTripTypes([]);
    }
  }, [tripTypeOpen]);

  const initialValues = {
    name: routeName,
  };

  const ADD_TRIP_TYPE_TO_ROUTE = "/api/v1/triptypes/add_to_route/";
  const REMOVE_TRIP_TYPE_TO_ROUTE = "/api/v1/triptypes/remove_from_route/";
  const onSubmit = async (values, props) => {
    // No trip type picked
    if (selectedTripTypeId === 0) {
      setTripTypeEmpty(true);
      return;
    }

    props.setSubmitting(true);

    let data = {
      tripTypeId: selectedTripTypeId,
    };

    console.log(selectedTripTypeId);

    try {
      const response = await axiosPrivate.post(
        (removeTripType ? REMOVE_TRIP_TYPE_TO_ROUTE : ADD_TRIP_TYPE_TO_ROUTE) +
          routeId,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      props.setSubmitting(false);
      resetForm(props);
      closeForm(response.data.message);
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      const errorTitle = removeTripType
        ? "Failed To Remove Trip-Type"
        : "Failed To Add Trip-Type";
      setConfirmDialog({
        isOpen: true,
        title: errorTitle,
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  return (
    <Grid>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form>
            <Stack spacing={2} mt={3} direction="column">
              <Field
                as={TextField}
                label="Name"
                name="name"
                placeholder="Enter route name"
                disabled={true}
                fullWidth
                required
                helperText={<ErrorMessage name="name" />}
              />

              <Autocomplete
                id="tripType"
                fullWidth
                label="Trip-Type"
                placeholder="Select trip-type"
                onChange={handleTripTypeChange}
                isOptionEqualToValue={(tripType, value) =>
                  tripType.name === value.name
                }
                getOptionLabel={(tripType) => tripType.name}
                key={selectedTripTypeKey}
                open={tripTypeOpen}
                onOpen={() => {
                  setTripTypeLoading(true);
                  setTripTypeOpen(true);
                }}
                onClose={() => {
                  setTripTypeLoading(false);
                  setTripTypeOpen(false);
                }}
                options={tripTypes}
                loading={tripTypeLoading}
                loadingText="Loading trip-types..."
                noOptionsText={
                  removeTripType ? "No existing trip-type" : "No new trip-type"
                }
                sx={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Trip-Type"
                    name="tripType"
                    placeholder="Select trip-type"
                    error={tripTypeEmpty}
                    helperText={tripTypeEmpty ? "Trip-type required" : ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {tripTypeLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Stack>

            <Stack spacing={2} mt={3} direction="row" justifyContent="flex-end">
              <LightTooltip title="Clear form">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 8,
                    width: 120,
                    backgroundColor: "#808080",
                    padding: "7px 14px",
                    fontSize: "14px",
                    color: "white",
                    ":hover": {
                      bgcolor: "#696969",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    resetForm(props);
                  }}
                >
                  Reset
                </Button>
              </LightTooltip>

              <LightTooltip title="Submit details">
                <Button
                  loading={props.isSubmitting}
                  loadingPosition="start"
                  disabled={props.isSubmitting}
                  type="submit"
                  sx={{
                    width: 120,
                  }}
                  variant="solid"
                >
                  {removeTripType
                    ? props.isSubmitting
                      ? "Removing"
                      : "Remove"
                    : props.isSubmitting
                    ? "Adding"
                    : "Add"}
                </Button>
              </LightTooltip>
            </Stack>
          </Form>
        )}
      </Formik>
      {/* </Paper> */}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
};

export default AddTripTypeToRoute;
