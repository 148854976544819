import { React, useState, useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import RDataGrid from "./reusables/RDataGrid";
import PageLoader from "./reusables/PageLoader";
import moment from "moment";
import { date_format, time_format } from "../constants/AppConstants";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { LightTooltip } from "../tooltips/ToolTip";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import TripStudentsDetails from "./TripStudentsDetails";
import AddPopup from "../popups/AddPopup";

const TripStudents = () => {
  const [isFetchingTripStudents, setIsFetchingTripStudents] = useState(true);
  const [tripStudents, setTripStudents] = useState([]);
  const TRIP_STUDENTS_URL = "api/v1/tripstudents";
  const axiosPrivate = useAxiosPrivate();
  const [openPopup, setOpenPopup] = useState(false);
  const [tripId, setTripId] = useState(0);

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  useEffect(() => {
    if (!isFetchingTripStudents) {
      setIsFetchingTripStudents(true);
    }

    let isMounted = true;
    const controller = new AbortController();

    const getTripStudents = async () => {
      try {
        const response = await axiosPrivate.get(TRIP_STUDENTS_URL, {
          signal: controller.signal,
        });
        isMounted && setTripStudents(response.data.data);
        setIsFetchingTripStudents(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setIsFetchingTripStudents(false);
      }
    };

    getTripStudents();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const columns = useMemo(
    () => [
      { field: "id", headerName: "Id", width: 60 },
      {
        field: "routeName",
        headerName: "Route",
        width: 300,
        align: "left",
        headerAlign: "left",
        renderCell: function (params) {
          const routeName = params.row.routeName;
          return routeName == null ? "-" : routeName;
        },
      },
      {
        field: "tripId",
        headerName: "Trip Id",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "tripName",
        headerName: "Trip",
        align: "center",
        headerAlign: "center",
        width: 200,
        renderCell: function (params) {
          const tripName = params.row.tripName;
          return tripName == null ? "-" : tripName;
        },
      },
      {
        field: "isPickUp",
        headerName: "Trip-Nature",
        width: 200,
        type: "boolean",
        editable: false,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const isPickUp = params.row.isPickUp;
          return isPickUp ? "Pick-up" : "Drop-off";
        },
      },
      {
        field: "stationName",
        headerName: "Station",
        align: "center",
        headerAlign: "center",
        width: 200,
        renderCell: function (params) {
          const stationName = params.row.stationName;
          return stationName == null ? "-" : stationName;
        },
      },
      {
        field: "date",
        headerName: "Date",
        align: "center",
        headerAlign: "center",
        width: 200,
        renderCell: function (params) {
          const timestamp = params.row.date;
          var date =
            timestamp == null ? "-" : moment(timestamp).format(date_format);
          return date;
        },
      },
      {
        field: "time",
        headerName: "Time",
        align: "center",
        headerAlign: "center",
        width: 160,
        renderCell: function (params) {
          const timestamp = params.row.date;
          var time =
            timestamp == null ? "-" : moment(timestamp).format(time_format);
          return time;
        },
      },
      {
        field: "regNos",
        headerName: "Total Students",
        align: "center",
        headerAlign: "center",
        width: 160,
        renderCell: function (params) {
          const regNos = params.row.regNos;
          return regNos.length;
          //return regNos?.map((elem) => elem).join(",");
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "View Students",
        align: "center",
        headerAlign: "center",
        width: 150,
        sortable: false,
        disableClickEventBubbling: true,
        cellClassName: "actions",
        getActions: (params) => {
          return [
            <LightTooltip title="View Students">
              <GridActionsCellItem
                icon={<FormatListBulletedIcon />}
                label="View Students"
                className="textPrimary"
                onClick={function () {
                  const tripId = params.row.id;
                  setTripId(tripId);
                  return setOpenPopup(true);
                }}
                color="success"
              />
            </LightTooltip>,
          ];
        },
      },
    ],
    []
  );

  return isFetchingTripStudents ? (
    <PageLoader title="Loading trip students..." />
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box height={40} />

      {tripStudents?.length ? (
        <Box
          sx={{
            height: 560,
            width: "100%",
          }}
        >
          <div style={divStyle}>
            <Typography
              variant="h4"
              component="h4"
              sx={{ textAlign: "left", mt: 3, mb: 3 }}
            >
              Trip Students
            </Typography>
          </div>
          <Box sx={{ width: 1218, margin: "0 auto 16px", height: 560 }}>
            <RDataGrid columns={columns} rows={tripStudents} rowHeight={63} />
          </Box>
          <AddPopup
            width="xl"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            title="Trip Students"
          >
            <TripStudentsDetails source={"trip_students"} tripId={tripId} />
          </AddPopup>
        </Box>
      ) : (
        <div style={divStyle}>
          <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: "center", mt: 3, mb: 3 }}
          >
            No trip students to display
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default TripStudents;
