import { React, useState, useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import RDataGrid from "./reusables/RDataGrid";
import PageLoader from "./reusables/PageLoader";
import moment from "moment";
import Add from "@mui/icons-material/Add";
import Button from "@mui/joy/Button";
import { date_format, time_format } from "../constants/AppConstants";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ConfirmDialog from "./ConfirmDialog";
import Notification from "./Notification";
import CreateNotification from "../forms/CreateNotification";
import AddPopup from "../popups/AddPopup";

const Notifications = () => {
  const [isFetchingNotifications, setIsFetchingNotifications] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const NOTIFICATION_URL = "api/v1/notification/";
  const axiosPrivate = useAxiosPrivate();
  const [openPopup, setOpenPopup] = useState(false);
  const [refreshNotifications, setSetRefreshNotifications] = useState(null);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    userId: false,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const [notificationType, setNotificationType] = useState("topic");

  const handleChange = (event) => {
    setNotificationType(event.target.value);
    setColumnVisibilityModel(
      event.target.value === "topic" ? { userId: false } : { userId: true }
    );
  };

  useEffect(() => {
    if (!isFetchingNotifications) {
      setIsFetchingNotifications(true);
    }

    let isMounted = true;
    const controller = new AbortController();

    const getTrips = async () => {
      try {
        const url = NOTIFICATION_URL + notificationType;
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });
        isMounted && setNotifications(response.data.data);
        setIsFetchingNotifications(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setIsFetchingNotifications(false);
      }
    };

    getTrips();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [notificationType, refreshNotifications]);

  const columns = useMemo(
    () => [
      {
        field: "userId",
        headerName: "User ID",
        align: "center",
        headerAlign: "center",
        hideable: false,
        hide: true,
        filterable: false,
        width: 100,
      },
      {
        field: "date",
        headerName: "Date",
        hide: true,
        filterable: false,
        width: 170,
        renderCell: function (params) {
          const timestamp = params.row.timeStamp;
          var date = moment(timestamp).format(date_format);
          return date;
        },
      },
      {
        field: "time",
        headerName: "Time",
        width: 120,
        renderCell: function (params) {
          const timestamp = params.row.timeStamp;
          var time = moment(timestamp).format(time_format);
          return time;
        },
      },
      {
        field: "title",
        headerName: "Title",
        width: 200,
      },
      {
        field: "body",
        headerName: "Body",
        width: 500,
      },
    ],
    []
  );

  // Close dialog upon successful notification creation
  const closeDialog = (message) => {
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: message,
      type: "success",
    });
    setSetRefreshNotifications(new Date());
  };

  return isFetchingNotifications ? (
    <PageLoader title="Loading sent Notifications..." />
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box height={40} />

      {notifications?.length ? (
        <Box
          sx={{
            height: 560,
            width: "100%",
          }}
        >
          <div style={divStyle}>
            <Typography
              variant="h4"
              component="h4"
              sx={{ textAlign: "left", mt: 3, mb: 3 }}
            >
              Sent Notifications
            </Typography>

            <FormControl
              sx={{
                width: 120,
              }}
            >
              <InputLabel id="notification-type-label">
                Filter By Type
              </InputLabel>
              <Select
                labelId="notification-type-label"
                id="notification-type"
                value={notificationType}
                label="Filter By Type"
                onChange={handleChange}
              >
                <MenuItem value={"topic"}>Group</MenuItem>
                <MenuItem value={"device"}>Individual</MenuItem>
              </Select>
            </FormControl>

            <Button
              size="lg"
              variant="solid"
              color="primary"
              onClick={function () {
                setOpenPopup(true);
              }}
              startDecorator={<Add />}
            >
              New Notitification
            </Button>
          </div>

          <Box sx={{ width: 1218, margin: "0 auto 16px", height: 560 }}>
            <RDataGrid
              columns={columns}
              rows={notifications}
              rowHeight={63}
              columnVisibilityModel={columnVisibilityModel}
            />
          </Box>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <AddPopup
            width="md"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            title="Create Notification"
          >
            <CreateNotification closeDialog={closeDialog} />
          </AddPopup>
        </Box>
      ) : (
        <div style={divStyle}>
          <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: "center", mt: 3, mb: 3 }}
          >
            No notifications to display
          </Typography>

          <FormControl
            sx={{
              width: 120,
            }}
          >
            <InputLabel id="notification-type-label">Filter By Type</InputLabel>
            <Select
              labelId="notification-type-label"
              id="notification-type"
              value={notificationType}
              label="Filter By Type"
              onChange={handleChange}
            >
              <MenuItem value={"topic"}>Group</MenuItem>
              <MenuItem value={"device"}>Individual</MenuItem>
            </Select>
          </FormControl>

          <Button
            size="lg"
            variant="solid"
            color="primary"
            onClick={function () {
              setOpenPopup(true);
            }}
            startDecorator={<Add />}
          >
            New Notitification
          </Button>

          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <AddPopup
            width="md"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            title="Create Notification"
          >
            <CreateNotification closeDialog={closeDialog} />
          </AddPopup>
        </div>
      )}
    </Box>
  );
};

export default Notifications;
