import * as React from "react";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import HomeIcon from "@mui/icons-material/Home";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RouteIcon from "@mui/icons-material/Route";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import GroupIcon from "@mui/icons-material/Group";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Badge from "@mui/material/Badge";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SmsIcon from "@mui/icons-material/Sms";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Users from "./components/Users";
import Home from "./components/Home";
import DayTimes from "./components/DayTimes";
import PickupTimes from "./components/PickupTimes";
import MRoutes from "./components/MRoutes";
import Stations from "./components/Stations";
import Cars from "./components/Cars";
import Students from "./components/Students";
import Trips from "./components/Trips";
import TripStudents from "./components/TripStudents";
import TripTypes from "./components/TripTypes";
import Roles from "./components/Roles";
import States from "./components/States";
import Transfers from "./components/Transfers";
import Faqs from "./components/Faqs";
import Profile from "./components/Profile";
import SentSMS from "./components/SentSMS";
//import useLogout from "./hooks/useLogout";
import {
  Routes,
  useLocation,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import useAxiosPrivate from "./hooks/useAxiosPrivate";
import useAuth from "./hooks/useAuth";
//import background from "./login_bg.jpg";
import ChangePassword from "./forms/ChangePassword";
import Notification from "./components/Notification";
import ConfirmDialog from "./components/ConfirmDialog";
import AddPopup from "./popups/AddPopup";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Notifications from "./components/Notifications";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const moreItems = ["Profile", "Change Password", "Logout"];

export default function Sidenav() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const contentStyle = {
    flexGrow: 1,
    padding: 24,
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const { setAuth } = useAuth();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const LOGOUT = "/api/v1/users/logout/";

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleNotificationsClicked = () => {
    //alert("Coming soon");
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItemClicked = (event, value) => {
    handleProfileMenuClose();
    //console.log("Clicked", value);
    //alert(`${value} coming soon`);
    if (`${value}` === `Logout`) {
      //signOut();
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure you want to Logout?",
        subTitle: "Your current session will be terminated",
        action: "Continue",
        onConfirm: () => {
          logoutRequest();
        },
      });
    }

    if (`${value}` === `Profile`) {
      //setMenudata("Profile");
      //console.log("profile");
      navigate("/profile", {
        replace: true,
      });
    }

    if (`${value}` === `Change Password`) {
      setOpenPopup(true);
    }
  };
  const menuId = "primary-search-account-menu";

  /*  const handleDrawerOpen = () => {
    setOpen(true);
  }; */

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logoutRequest = async () => {
    setBackdropOpen(true);
    try {
      const response = await axiosPrivate.put(`${LOGOUT}${auth.id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response);
      setBackdropOpen(false);
      logOut();
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      const errorTitle = "Failed To Logout";
      setConfirmDialog({
        isOpen: true,
        title: errorTitle,
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: () => {
          logoutRequest();
        },
      });
      setBackdropOpen(false);
    }
  };

  // Close dialog upon successful profile update
  const closeDialog = (message) => {
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: message,
      type: "success",
    });
    setTimeout(() => {
      logOut();
    }, 2000);
  };

  const logOut = () => {
    /* navigate("/login", {
      replace: true,
    }); */
    setAuth({});
    navigate("/login", {
      replace: true,
    });
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          elevation={4}
          sx={{ backgroundColor: "#43B02A", color: "#ffffff" }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setOpen(!open);
              }}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
            <Box ml={1} mt={1} mr={2}>
              {" "}
              <img src="./logo512.png" height={30} alt="App Icon" />
            </Box>
            <Typography variant="h6" noWrap component="div">
              School Transport Management System
            </Typography>
            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Tooltip title="Notifications">
                <IconButton
                  size="large"
                  aria-label="show new notifications"
                  color="inherit"
                  onClick={handleNotificationsClicked}
                >
                  <Badge badgeContent={1} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title="More">
                {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton> */}
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleProfileMenuClose}
              >
                {moreItems.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={(e) => menuItemClicked(e, setting)}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: "#36454F",
            },
          }}
          /* sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              backgroundImage: `url(${background})`,
              position: "absolute",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              "&:before": {
                position: "absolute",
                width: "100%",
                height: "100%",
                content: '""',
                display: "block",
              },
            },
          }} */
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List
            sx={{
              // selected and (selected + hover) states
              "&& .Mui-selected, && .Mui-selected:hover": {
                bgcolor: "rgba(67, 176, 42, .9)",
                "&, & .MuiListItemIcon-root": {
                  color: "white",
                },
              },
              // hover states
              "& .MuiListItemButton-root:hover": {
                bgcolor: "rgba(67, 176, 42, .5)",
                "&, & .MuiListItemIcon-root": {
                  color: "rgba(67, 176, 42)",
                },
                cursor: "pointer",
              },
              color: "white",
            }}
          >
            {[
              {
                text: "Dashboard",
                to: "/",
                icon: <HomeIcon />,
              },
              {
                text: "Users",
                to: "/users",
                icon: <PeopleIcon />,
              },
              {
                text: "Day Times",
                to: "/day-times",
                icon: <CalendarTodayIcon />,
              },
              {
                text: "Trip Types",
                to: "/trip-types",
                icon: <TextFieldsIcon />,
              },
              {
                text: "Routes",
                to: "/routes",
                icon: <RouteIcon />,
              },
              {
                text: "Stations",
                to: "/stations",
                icon: <LocationCityIcon />,
              },
              {
                text: "Pick-up/Drop-off Times",
                to: "/pick-up-drop-off-times",
                icon: <AccessTimeIcon />,
              },
              {
                text: "Cars",
                to: "/cars",
                icon: <DirectionsBusIcon />,
              },
              {
                text: "Students",
                to: "/students",
                icon: <GroupIcon />,
              },
              {
                text: "Trips",
                to: "/trips",
                icon: <TripOriginIcon />,
              },
              /* {
                text: "Trip States",
                to: "/trip-states",
                icon: <ColorLensIcon />,
              }, */
              {
                text: "Trip Students",
                to: "/trip-students",
                icon: <PeopleAltIcon />,
              },
              {
                text: "Roles",
                to: "/roles",
                icon: <GroupWorkIcon />,
              },
              {
                text: "States",
                to: "/states",
                icon: <HourglassEmptyIcon />,
              },
              {
                text: "SMS",
                to: "/sent-sms",
                icon: <SmsIcon />,
              },
              {
                text: "Notifications",
                to: "/notifications",
                icon: <SmsIcon />,
              },
              {
                text: "Transfers",
                to: "/transfers",
                icon: <MoveDownIcon />,
              },
            ].map((obj, index) => (
              <ListItem
                key={index}
                disablePadding
                to={obj.to}
                component={Link}
                sx={{
                  display: "block",
                  color: "white",
                }}
                //onClick={() => setMenudata(obj.text)}
              >
                <ListItemButton
                  selected={pathname === obj.to}
                  //onClick={(event) => handleListItemClick(event, index)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "white",
                    }}
                  >
                    {obj.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={obj.text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List
            sx={{
              // selected and (selected + hover) states
              "&& .Mui-selected, && .Mui-selected:hover": {
                bgcolor: "rgba(67, 176, 42, .9)",
                "&, & .MuiListItemIcon-root": {
                  color: "white",
                },
              },
              // hover states
              "& .MuiListItemButton-root:hover": {
                bgcolor: "rgba(67, 176, 42, .5)",
                "&, & .MuiListItemIcon-root": {
                  color: "rgba(67, 176, 42)",
                },
                cursor: "pointer",
              },
              color: "white",
            }}
          >
            {[
              {
                text: "Profile",
                to: "/profile",
                icon: <AccountCircle />,
              },
              {
                text: "FAQs",
                to: "/faqs",
                icon: <QuestionAnswerIcon />,
              },
              /* {
                text: "Settings",
                icon: <SettingsIcon />,
              },
              {
                text: "FAQs",
                icon: <QuestionAnswerIcon />,
              },
              {
                text: "Help",
                icon: <HelpIcon />,
              },
              {
                text: "Feedback",
                icon: <FeedbackIcon />,
              }, */
            ].map((obj, index) => (
              <ListItem
                key={index}
                disablePadding
                to={obj.to}
                component={Link}
                sx={{
                  display: "block",
                  color: "white",
                }}
                //onClick={() => setMenudata(obj.text)}
              >
                <ListItemButton
                  selected={pathname === obj.to}
                  //onClick={(event) => handleListItemClick(event, index + 100)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "white",
                    }}
                  >
                    {obj.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={obj.text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>

        <main style={contentStyle}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/users" element={<Users />} />
            <Route path="/day-times" element={<DayTimes />} />
            <Route path="/trip-types" element={<TripTypes />} />
            <Route path="/routes" element={<MRoutes />} />
            <Route path="/stations" element={<Stations />} />
            <Route path="/pick-up-drop-off-times" element={<PickupTimes />} />
            <Route path="/cars" element={<Cars />} />
            <Route path="/students" element={<Students />} />
            <Route path="/trips" element={<Trips />} />
            {/* <Route path="/trip-states" element={<TripStates />} /> */}
            <Route path="/trip-students" element={<TripStudents />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/states" element={<States />} />
            <Route path="/sent-sms" element={<SentSMS />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/transfers" element={<Transfers />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/faqs" element={<Faqs />} />
          </Routes>
        </main>

        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <Notification notify={notify} setNotify={setNotify} />
        <AddPopup
          width="md"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          title="Change Password"
        >
          <ChangePassword closeDialog={closeDialog} />
        </AddPopup>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
}
