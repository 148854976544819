import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import IconButton from "@mui/joy/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { LightTooltip } from "../tooltips/ToolTip";

export default function AddUserPopup(props) {
  const { title, children, width, openPopup, setOpenPopup } = props;

  return (
    <Dialog
      open={openPopup}
      maxWidth={width}
      PaperProps={{
        sx: {
          padding: 2,
          position: "absolute",
          top: 5,
        },
      }}
    >
      <DialogTitle style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <LightTooltip title="Close Form">
            <IconButton
              variant="soft"
              color="danger"
              onClick={() => {
                setOpenPopup(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </LightTooltip>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
