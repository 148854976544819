import Axios from "../api/Axios";
import useAuth from "./useAuth";
import { useLocation, useNavigate } from "react-router-dom";

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const { auth } = useAuth();
  const REFRESH_URL = "api/v1/token/refresh";
  const navigate = useNavigate();
  const location = useLocation();

  const refresh = async () => {
    //console.log("refresh called....");

    let refreshToken = `${auth.refreshToken}`;
    let msisdn = `${auth.username}`;

    if (refreshToken === `undefined`) {
      if (localStorage.getItem("rT")) {
        refreshToken = localStorage.getItem("rT");
      }
      if (localStorage.getItem("uN")) {
        msisdn = localStorage.getItem("uN");
      }
    }

    try {
      const response = await Axios.get(REFRESH_URL, {
        headers: {
          Authorization: "Bearer " + refreshToken,
          "x-msisdn": msisdn,
        },
      });

      setAuth((prev) => {
        const roleObjects = response.data.data.roles;

        var roles = [];
        for (var i = 0; i < roleObjects.length; i++) {
          roles.push(roleObjects[i].id);
        }

        return {
          ...prev,
          id: response.data.data.id,
          username: msisdn,
          roles: roles,
          accessToken: response.data.data.access_token,
          refreshToken: response.data.data.refresh_token,
        };
      });
      return response.data.data.access_token;
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorDate = error.response.data;
        errorMessage = errorDate.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      console.log(errorMessage);
      if (errorMessage.includes("The Token has expired")) {
        // Access token expired
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };

  return refresh;
};

export default useRefreshToken;
