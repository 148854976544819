import { React, useState, useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import RDataGrid from "./reusables/RDataGrid";
import PageLoader from "./reusables/PageLoader";

const TripStudentsDetails = (props) => {
  const { source, tripId, guardianId } = props;
  const [isFetchingStudents, setIsFetchingStudents] = useState(true);
  const [students, setStudents] = useState([]);
  const TRIP_STUDENTS_URL = "api/v1/tripstudents/details/";
  const GUARDIAN_STUDENTS_URL = "api/v1/students/guardian/";
  const axiosPrivate = useAxiosPrivate();

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  useEffect(() => {
    if (!isFetchingStudents) {
      setIsFetchingStudents(true);
    }

    let isMounted = true;
    const controller = new AbortController();

    const getStudents = async () => {
      const url =
        source === "trip_students"
          ? TRIP_STUDENTS_URL + tripId
          : GUARDIAN_STUDENTS_URL + guardianId;
      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });
        isMounted && setStudents(response.data.data);
        setIsFetchingStudents(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setIsFetchingStudents(false);
      }
    };

    getStudents();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const columns = useMemo(
    () => [
      {
        field: "regNo",
        headerName: "Reg No",
        width: 120,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "name",
        headerName: "Name",
        width: 150,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const student = params.row;
          const name = `${student.firstName} ${student.lastName}`;
          return name;
        },
      },
      {
        field: "guardian_name",
        headerName: "Guardian Name",
        width: 200,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const guardian = params.row.guardian;
          const name = `${guardian.firstName} ${guardian.lastName}`;
          return name;
        },
      },
      {
        field: "guardian_phone",
        headerName: "Guardian Phone",
        width: 200,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const guardian = params.row.guardian;
          return guardian.phone;
        },
      },
      {
        field: "guardian_email",
        headerName: "Guardian Email",
        width: 230,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const guardian = params.row.guardian;
          return guardian.email;
        },
      },
      {
        field: "route",
        headerName: "Route",
        width: 230,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const route = params.row.station.route.name;
          return route;
        },
      },
      {
        field: "station",
        headerName: "Station",
        width: 160,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const station = params.row.station.name;
          return station;
        },
      },
      {
        field: "pick_up_trip",
        headerName: "Pick-Up Trip",
        width: 160,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const pick_up_trip = params.row.pickupTrip.name;
          return pick_up_trip;
        },
      },
      {
        field: "pickupTime",
        headerName: "Pick-Up Time",
        width: 160,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "return_trip",
        headerName: "Return Trip",
        width: 180,
        align: "center",
        headerAlign: "center",
        renderCell: function (params) {
          const return_trip = params.row.returnTrip.name;
          return return_trip;
        },
      },
      {
        field: "returnTime",
        headerName: "Drop-Off Time",
        width: 160,
        align: "center",
        headerAlign: "center",
      },
    ],
    []
  );

  return isFetchingStudents ? (
    <PageLoader title="Loading students..." />
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      {students?.length ? (
        <Box
          sx={{
            height: 560,
            width: "100%",
          }}
        >
          <Box sx={{ width: 1218, margin: "0 auto 16px", height: 560 }}>
            <RDataGrid columns={columns} rows={students} rowHeight={63} />
          </Box>
        </Box>
      ) : (
        <div style={divStyle}>
          <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: "center", mt: 3, mb: 3 }}
          >
            No students found
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default TripStudentsDetails;
