import { React, useState, useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import RDataGrid from "./reusables/RDataGrid";
import PageLoader from "./reusables/PageLoader";

const States = () => {
  const [isFetchingStates, setIsFetchingStates] = useState(true);
  const [roles, setRoles] = useState([]);
  const STATES_URL = "api/v1/status";
  const axiosPrivate = useAxiosPrivate();

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  useEffect(() => {
    if (!isFetchingStates) {
      setIsFetchingStates(true);
    }

    let isMounted = true;
    const controller = new AbortController();

    const getRoles = async () => {
      try {
        const response = await axiosPrivate.get(STATES_URL, {
          signal: controller.signal,
        });
        isMounted && setRoles(response.data.data);
        setIsFetchingStates(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setIsFetchingStates(false);
      }
    };

    getRoles();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "Id",
        width: 60,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "name",
        headerName: "Name",
        width: 200,
        align: "center",
        headerAlign: "center",
      },
    ],
    []
  );

  return isFetchingStates ? (
    <PageLoader title="Loading states..." />
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box height={40} />

      {roles?.length ? (
        <Box
          sx={{
            height: 560,
            width: "100%",
          }}
        >
          <div style={divStyle}>
            <Typography
              variant="h4"
              component="h4"
              sx={{ textAlign: "left", mt: 3, mb: 3 }}
            >
              States For Trips
            </Typography>
          </div>
          <Box sx={{ width: 300, height: 520 }}>
            <RDataGrid columns={columns} rows={roles} rowHeight={63} />
          </Box>
        </Box>
      ) : (
        <div style={divStyle}>
          <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: "center", mt: 3, mb: 3 }}
          >
            No states to display
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default States;
