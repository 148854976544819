import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Notification from "../components/Notification";
import ConfirmDialog from "../components/ConfirmDialog";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { LightTooltip } from "../tooltips/ToolTip";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddRoute = (props) => {
  const { closeDialog, editRoute, selectedRoute } = props;
  //console.log("selected route --> " + selectedRoute);

  var routeInfo = null;
  var routeId = 0;
  var routeName = null;
  var existingTripTypes = [];
  var existingTripTypeIds = [];
  if (editRoute) {
    routeInfo = JSON.parse(selectedRoute);
    routeId = routeInfo.id;
    routeName = routeInfo.name;
    existingTripTypes = routeInfo.tripTypes;

    for (const tripType of existingTripTypes) {
      existingTripTypeIds.push(tripType.id);
    }
  }

  const axiosPrivate = useAxiosPrivate();

  const resetForm = (props) => {
    // clear input fields
    props.resetForm();
    setSelectedTripTypeKey(new Date());
  };

  const closeForm = (message) => {
    closeDialog(message);
  };

  // Routes
  const [tripTypeOpen, setTripTypeOpen] = useState(false);
  const [tripTypes, setTripTypes] = useState([]);
  const [tripTypeLoading, setTripTypeLoading] = useState(
    tripTypeOpen && tripTypes.length === 0
  );
  const [selectedTripTypeIds, setSelectedTripTypeIds] = useState(
    editRoute ? existingTripTypeIds : []
  );
  const [selectedTripTypeKey, setSelectedTripTypeKey] = useState("routeKey");
  const [tripTypesEmpty, setTripTypesEmpty] = useState(false);
  const TRIP_TYPES_URL = "/api/v1/triptypes";
  const handleTripTypeChange = (event, tripTypes) => {
    var selectedTripTypesIds = [];
    for (const tripType of tripTypes) {
      selectedTripTypesIds.push(tripType.id);
    }
    setSelectedTripTypeIds(selectedTripTypesIds);
    console.log(selectedTripTypesIds);
    setTripTypesEmpty(false);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!tripTypeLoading) {
      return undefined;
    }

    const getTripTypes = async () => {
      try {
        const response = await axiosPrivate.get(TRIP_TYPES_URL, {
          signal: controller.signal,
        });
        isMounted && setTripTypes(response.data.data);
        setTripTypeLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setTripTypeLoading(false);
      }
    };

    getTripTypes();

    return () => {
      isMounted = false;
      controller.abort();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [tripTypeLoading]);

  React.useEffect(() => {
    if (!tripTypeOpen) {
      setTripTypes([]);
    }
  }, [tripTypeOpen]);

  const initialValues = editRoute
    ? {
        name: routeName,
        tripTypeIds: selectedTripTypeIds,
      }
    : {
        name: "",
        tripTypeIds: [],
      };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const CREATE_ROUTE_URL = "/api/v1/routes/save";
  const UPDATE_ROUTE_URL = "/api/v1/routes/";
  const onSubmit = async (values, props) => {
    // No trip types picked
    if (selectedTripTypeIds.length === 0) {
      setTripTypesEmpty(true);
      return;
    }

    props.setSubmitting(true);

    let routeData = {
      name: values.name,
      tripTypeIds: selectedTripTypeIds,
    };

    console.log(selectedTripTypeIds);
    console.log(values.name);

    try {
      const response = editRoute
        ? await axiosPrivate.put(UPDATE_ROUTE_URL + routeId, routeData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
        : await axiosPrivate.post(CREATE_ROUTE_URL, routeData, {
            headers: {
              "Content-Type": "application/json",
            },
          });

      console.log(response.data.message);
      props.setSubmitting(false);
      resetForm(props);
      closeForm(response.data.message);
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      const errorTitle = editRoute
        ? "Failed To Update Route"
        : "Failed To Create Route";
      setConfirmDialog({
        isOpen: true,
        title: errorTitle,
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  return (
    <Grid>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Stack spacing={2} mt={3} direction="column">
              <Field
                as={TextField}
                label="Name"
                name="name"
                placeholder="Enter route name"
                fullWidth
                required
                helperText={<ErrorMessage name="name" />}
              />

              <Autocomplete
                multiple
                id="tripType"
                fullWidth
                defaultValue={existingTripTypes}
                onChange={handleTripTypeChange}
                key={selectedTripTypeKey}
                open={tripTypeOpen}
                disabled={editRoute}
                onOpen={() => {
                  setTripTypeLoading(true);
                  setTripTypeOpen(true);
                }}
                onClose={() => {
                  setTripTypeLoading(false);
                  setTripTypeOpen(false);
                }}
                getOptionLabel={(tripType) => tripType.name}
                options={tripTypes}
                disableCloseOnSelect
                loading={tripTypeLoading}
                loadingText="Loading trip-types..."
                noOptionsText="No trip-types"
                renderOption={(props, tripType, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {tripType.name}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Trip-Types"
                    name="tripType"
                    placeholder="Select trip-type"
                    error={tripTypesEmpty}
                    helperText={tripTypesEmpty ? "Trip-Type required" : ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {tripTypeLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Stack>

            <Stack spacing={2} mt={3} direction="row" justifyContent="flex-end">
              <LightTooltip title="Clear form">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 8,
                    width: 120,
                    backgroundColor: "#808080",
                    padding: "7px 14px",
                    fontSize: "14px",
                    color: "white",
                    ":hover": {
                      bgcolor: "#696969",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    resetForm(props);
                  }}
                >
                  Reset
                </Button>
              </LightTooltip>

              <LightTooltip title="Submit details">
                <Button
                  loading={props.isSubmitting}
                  loadingPosition="start"
                  disabled={props.isSubmitting}
                  type="submit"
                  sx={{
                    width: 120,
                  }}
                  variant="solid"
                >
                  {props.isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </LightTooltip>
            </Stack>
          </Form>
        )}
      </Formik>
      {/* </Paper> */}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
};

export default AddRoute;
