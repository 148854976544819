import React from "react";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { Box, Typography, Grid, CardActionArea } from "@mui/material";

function StatisticsCard(props) {
  const {
    color,
    title,
    count,
    icon,
    percentageColor,
    percentageAmount,
    percentageLabel,
    mOnClick,
  } = props;

  return (
    <Card style={{ borderRadius: 10 }}>
      <Box
        variant="gradient"
        position="absolute"
        bgcolor={color}
        color={color === "light" ? "dark" : "white"}
        coloredShadow={color}
        borderRadius="xl"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="4rem"
        height="4rem"
        mt={-2}
        ml={2}
        style={{ borderRadius: 10 }}
      >
        <Icon fontSize="medium" color="inherit">
          {icon}
        </Icon>
      </Box>
      <CardActionArea onClick={mOnClick}>
        <Box display="flex" justifyContent="space-between" pt={1} px={2}>
          <Box height={10}></Box>
          <Box textAlign="right" lineHeight={1.25}>
            <Typography
              variant="button"
              fontWeight="light"
              color="text"
              textTransform="capitalize"
            >
              {title}
            </Typography>
            <Typography variant="h4">{count}</Typography>
          </Box>
        </Box>

        <Divider
          sx={{
            mt: 2,
          }}
        />
        <Box pb={2} px={2} pt={2}>
          <Typography
            component="p"
            variant="button"
            color="text"
            display="flex"
            fontFamily="Monospace"
            textTransform="lowercase"
          >
            <Typography
              component="span"
              variant="button"
              fontWeight="light"
              color={percentageColor}
            >
              {percentageAmount}
            </Typography>
            &nbsp;{percentageLabel}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default StatisticsCard;
