import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Notification from "../components/Notification";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { LightTooltip } from "../tooltips/ToolTip";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmDialog from "../components/ConfirmDialog";

function TransferGuardianToStation() {
  var [pickupTrips, setPickupTrips] = useState([]);
  var [returnTrips, setReturnTrips] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const resetForm = (props) => {
    // clear input fields
    const now = new Date();
    props.resetForm();

    setRouteKey(now);
    setStationKey(now + "a");
    setPickupTripKey(now + "b");
    setReturnTripKey(now + "c");
    setGuardianKey(now + "d");

    setSelectedGuardian(0);
    setSelectedRoute(0);
    setSelectedStation(0);
    setSelectedPickupTrip(0);
    setSelectedReturnTrip(0);
  };

  // Guardians
  const [guardianOpen, setGuardianOpen] = useState(false);
  const [guardians, setGuardians] = useState([]);
  const [selectedGuardian, setSelectedGuardian] = useState(0);
  const [guardianLoading, setGuardianLoading] = useState(
    guardianOpen && guardians.length === 0
  );
  const [guardianEmpty, setGuardianEmpty] = useState(false);
  const [guardianKey, setGuardianKey] = useState("guardianKey");
  const GUARDIANS_URL = "/api/v1/users/role/3";
  const onGuardianChange = (event, value) => {
    setSelectedGuardian(value.id);
    setGuardianEmpty(false);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!guardianLoading) {
      return undefined;
    }

    const getGuardians = async () => {
      try {
        const response = await axiosPrivate.get(GUARDIANS_URL, {
          signal: controller.signal,
        });
        isMounted && setGuardians(response.data.data);
        setGuardianLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setGuardianLoading(false);
      }
    };

    getGuardians();

    return () => {
      isMounted = false;
      controller.abort();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [guardianLoading]);

  React.useEffect(() => {
    if (!guardianOpen) {
      setGuardians([]);
    }
  }, [guardianOpen]);

  // Routes
  const [routeOpen, setRouteOpen] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(0);
  const [routeLoading, setRouteLoading] = useState(
    routeOpen && routes.length === 0
  );
  const [routeEmpty, setRouteEmpty] = useState(false);
  const [routeKey, setRouteKey] = useState("routeKey");
  const ROUTES_URL = "/api/v1/routes";
  const handleRouteChange = (event, value) => {
    setSelectedRoute(value.id);
    setPickupTrips(value.tripTypes);
    setReturnTrips(value.tripTypes);
    setRouteEmpty(false);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!routeLoading) {
      return undefined;
    }

    const getRoutes = async () => {
      try {
        const response = await axiosPrivate.get(ROUTES_URL, {
          signal: controller.signal,
        });
        isMounted && setRoutes(response.data.data);
        setRouteLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setRouteLoading(false);
      }
    };

    getRoutes();

    return () => {
      isMounted = false;
      controller.abort();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [routeLoading]);

  React.useEffect(() => {
    if (!routeOpen) {
      setRoutes([]);
    }
  }, [routeOpen]);

  // Station
  const [stationOpen, setStationOpen] = useState(false);
  const [stations, setStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState(0);
  const [stationLoading, setStationLoading] = useState(
    stationOpen && stations.length === 0
  );
  const [stationEmpty, setStationEmpty] = useState(false);
  const [stationKey, setStationKey] = useState("stationKey");
  const STATIONS_URL = `/api/v1/stations/route/${selectedRoute}`;
  const handleStationChange = (event, value) => {
    setSelectedStation(value.id);
    setStationEmpty(false);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!stationLoading) {
      return undefined;
    }

    const getStations = async () => {
      try {
        const response = await axiosPrivate.get(STATIONS_URL, {
          signal: controller.signal,
        });
        isMounted && setStations(response.data.data);
        setStationLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setStationLoading(false);
      }
    };

    getStations();

    return () => {
      isMounted = false;
      controller.abort();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [stationLoading]);

  React.useEffect(() => {
    if (!stationOpen) {
      setStations([]);
    }
  }, [stationOpen]);

  // Pickup Trip
  const [selectedPickupTrip, setSelectedPickupTrip] = useState(0);
  const [pickupTripEmpty, setPickupTripEmpty] = useState(false);
  const [pickupTripKey, setPickupTripKey] = useState("pickupTripKey");
  const onPickupTripChange = (event, value) => {
    setSelectedPickupTrip(value.id);
    setPickupTripEmpty(false);
  };

  // Return Trip
  const [selectedReturnTrip, setSelectedReturnTrip] = useState(0);
  const [returnTripEmpty, setReturnTripEmpty] = useState(false);
  const [returnTripKey, setReturnTripKey] = useState("returnTripKey");
  const onReturnTripChange = (event, value) => {
    setSelectedReturnTrip(value.id);
    setReturnTripEmpty(false);
  };

  // Common
  const initialValues = {
    //firstName: "",
  };

  const validationSchema = Yup.object().shape({
    //firstName: Yup.string().required("Required"),
  });

  const TRANSFER = `/api/v1/transfers/guardian/station/${selectedGuardian}`;
  const onSubmit = async (values, props) => {
    // No route picked
    if (selectedRoute === 0) {
      setRouteEmpty(true);
      return;
    }

    // No station picked
    if (selectedStation === 0) {
      setStationEmpty(true);
      return;
    }

    // No pick-up trip picked
    if (selectedPickupTrip === 0) {
      setPickupTripEmpty(true);
      return;
    }

    // No return trip picked
    if (selectedReturnTrip === 0) {
      setReturnTripEmpty(true);
      return;
    }

    props.setSubmitting(true);

    let guardianData = {
      stationId: selectedStation,
      pickupTripId: selectedPickupTrip,
      returnTripId: selectedReturnTrip,
    };

    console.log(guardianData);

    try {
      const response = await axiosPrivate.put(TRANSFER, guardianData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response.data.message);
      props.setSubmitting(false);
      resetForm(props);
      setNotify({
        isOpen: true,
        message: response.data.message,
        type: "success",
      });
      //closeForm(response.data.message);
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      const errorTitle = "Failed To Transfer Guardian to Another Station";
      setConfirmDialog({
        isOpen: true,
        title: errorTitle,
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  return (
    <Grid>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Stack spacing={2} mt={3} direction="column">
              <Autocomplete
                id="guardianId"
                fullWidth
                sx={{ width: 400 }}
                placeholder="Select guardian"
                label="Guardian"
                onChange={onGuardianChange}
                key={guardianKey}
                open={guardianOpen}
                onOpen={() => {
                  setGuardianLoading(true);
                  setGuardianOpen(true);
                }}
                onClose={() => {
                  setGuardianLoading(false);
                  setGuardianOpen(false);
                }}
                isOptionEqualToValue={
                  (guardian, value) =>
                    `${guardian.firstName} ${guardian.lastName} - ${guardian.phone}` ===
                    `${value.firstName} ${value.lastName} - ${value.phone}`
                  /* const guardian1 = `${guardian.firstName} ${guardian.lastName} - ${guardian.phone}`;
                  const guardian2 = `${value.firstName} ${value.lastName} - ${value.phone}`;
                  guardian1 === guardian2; */
                }
                getOptionLabel={(guardian) =>
                  `${guardian.firstName} ${guardian.lastName}`
                }
                options={guardians}
                loading={guardianLoading}
                loadingText="Loading guardians..."
                noOptionsText="No guardians"
                renderOption={(props, guardian) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {guardian.firstName} {guardian.lastName} - {guardian.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Guardian"
                    name="guardianId"
                    placeholder="Select guardian"
                    error={guardianEmpty}
                    helperText={guardianEmpty ? "Guardian required" : ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {guardianLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />

              <Autocomplete
                id="routeId"
                fullWidth
                sx={{ width: 400 }}
                placeholder="Select route"
                onChange={handleRouteChange}
                key={routeKey}
                open={routeOpen}
                onOpen={() => {
                  setRouteLoading(true);
                  setRouteOpen(true);
                }}
                onClose={() => {
                  setRouteLoading(false);
                  setRouteOpen(false);
                }}
                isOptionEqualToValue={(route, value) =>
                  route.name === value.name
                }
                getOptionLabel={(route) => route.name}
                options={routes}
                loading={routeLoading}
                loadingText="Loading routes..."
                noOptionsText="No routes"
                renderOption={(props, route) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {route.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Route"
                    name="routeId"
                    placeholder="Select route"
                    error={routeEmpty}
                    helperText={routeEmpty ? "Route required" : ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {routeLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />

              <Autocomplete
                id="stationId"
                placeholder="Select station"
                label="Station"
                fullWidth
                sx={{ width: 400 }}
                onChange={handleStationChange}
                key={stationKey}
                open={stationOpen}
                onOpen={() => {
                  setStationLoading(true);
                  setStationOpen(true);
                }}
                onClose={() => {
                  setStationLoading(false);
                  setStationOpen(false);
                }}
                isOptionEqualToValue={(station, value) =>
                  station.name === value.name
                }
                getOptionLabel={(station) => station.name}
                options={stations}
                loading={stationLoading}
                loadingText="Loading stations..."
                noOptionsText="No stations"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Station"
                    name="stationId"
                    placeholder="Select station"
                    error={stationEmpty}
                    helperText={stationEmpty ? "Station required" : ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {stationLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />

              <Autocomplete
                disablePortal
                id="pickupTripId"
                label="Pick-Up Trip"
                placeholder="Select pick-up trip"
                onChange={onPickupTripChange}
                isOptionEqualToValue={(pTrip, value) =>
                  pTrip.name === value.name
                }
                getOptionLabel={(pTrip) => pTrip.name}
                getOptionDisabled={(pTrip) => !pTrip.isPickUp}
                key={pickupTripKey}
                fullWidth
                options={pickupTrips}
                sx={{ width: 400 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pick-Up Trip"
                    name="pickupTripId"
                    placeholder="Select pick-up trip"
                    error={pickupTripEmpty}
                    helperText={pickupTripEmpty ? "Pick-up trip required" : ""}
                  />
                )}
              />

              <Autocomplete
                disablePortal
                id="returnTripId"
                label="Return Trip"
                placeholder="Select return trip"
                onChange={onReturnTripChange}
                isOptionEqualToValue={(pTrip, value) =>
                  pTrip.name === value.name
                }
                getOptionLabel={(pTrip) => pTrip.name}
                getOptionDisabled={(pTrip) => pTrip.isPickUp}
                key={returnTripKey}
                fullWidth
                options={returnTrips}
                sx={{ width: 400 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Return Trip"
                    name="returnTripId"
                    placeholder="Select return trip"
                    error={returnTripEmpty}
                    helperText={returnTripEmpty ? "Return trip required" : ""}
                  />
                )}
              />
            </Stack>

            <Stack spacing={2} mt={3} direction="row" justifyContent="flex-end">
              <LightTooltip title="Clear form">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 8,
                    width: 120,
                    backgroundColor: "#808080",
                    padding: "7px 14px",
                    fontSize: "14px",
                    color: "white",
                    ":hover": {
                      bgcolor: "#696969",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    resetForm(props);
                  }}
                >
                  Reset
                </Button>
              </LightTooltip>

              <LightTooltip title="Submit details">
                <Button
                  loading={props.isSubmitting}
                  loadingPosition="start"
                  disabled={props.isSubmitting}
                  type="submit"
                  sx={{
                    width: 120,
                  }}
                  variant="solid"
                >
                  {props.isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </LightTooltip>
            </Stack>
          </Form>
        )}
      </Formik>
      {/* </Paper> */}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
}

export default TransferGuardianToStation;
