import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Notification from "../components/Notification";
import ConfirmDialog from "../components/ConfirmDialog";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { LightTooltip } from "../tooltips/ToolTip";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const AddStudent = (props) => {
  const { closeDialog, editStudent, selectedStudent } = props;
  var [pickupTrips, setPickupTrips] = useState([]);
  var [returnTrips, setReturnTrips] = useState([]);
  //console.log("selected student --> " + selectedStudent);

  var studentInfo = null;
  var studentId = 0;
  var firstName = "";
  var lastName = "";
  var regNo = "";
  var guardian = null;
  var guardianId = 0;
  var pickupTrip = null;
  var pickupTripId = 0;
  var returnTrip = null;
  var returnTripId = 0;
  if (editStudent) {
    studentInfo = JSON.parse(selectedStudent);
    studentId = studentInfo.id;
    firstName = studentInfo.firstName;
    lastName = studentInfo.lastName;
    regNo = studentInfo.regNo;
    guardian = studentInfo.guardian;
    guardianId = guardian.id;
    pickupTrip = studentInfo.pickupTrip;
    pickupTripId = pickupTrip.id;
    returnTrip = studentInfo.returnTrip;
    returnTripId = returnTrip.id;
    pickupTrips = studentInfo.station.route.tripTypes;
    returnTrips = studentInfo.station.route.tripTypes;
  }

  const axiosPrivate = useAxiosPrivate();

  const resetForm = (props) => {
    // clear input fields
    const now = new Date();
    props.resetForm();
    setGuardianKey(now);
    setPickupTripKey(now + "a");
    setReturnTripKey(now + "b");
    setSelectedGuardian(0);
    setSelectedPickupTrip(0);
    setSelectedReturnTrip(0);
  };

  const closeForm = (message) => {
    closeDialog(message);
  };

  // Guardians
  const [guardianOpen, setGuardianOpen] = useState(false);
  const [guardians, setGuardians] = useState([]);
  const [selectedGuardian, setSelectedGuardian] = useState(
    editStudent ? guardianId : 0
  );
  //const guardianLoading = guardianOpen && guardians.length === 0;

  const [guardianLoading, setGuardianLoading] = useState(
    guardianOpen && guardians.length === 0
  );

  const [guardianEmpty, setGuardianEmpty] = useState(false);
  const [guardianKey, setGuardianKey] = useState("guardianKey");
  const GUARDIANS_URL = "/api/v1/users/role/3";
  const onGuardianChange = (event, value) => {
    setSelectedGuardian(value.id);
    setPickupTrips(value.station.route.tripTypes);
    setReturnTrips(value.station.route.tripTypes);
    setGuardianEmpty(false);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!guardianLoading) {
      return undefined;
    }

    const getGuardians = async () => {
      try {
        const response = await axiosPrivate.get(GUARDIANS_URL, {
          signal: controller.signal,
        });
        isMounted && setGuardians(response.data.data);
        setGuardianLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setGuardianLoading(false);
      }
    };

    getGuardians();

    return () => {
      isMounted = false;
      controller.abort();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [guardianLoading]);

  React.useEffect(() => {
    if (!guardianOpen) {
      setGuardians([]);
    }
  }, [guardianOpen]);

  // Pickup Trip
  //const [pickupTrips, setPickupTrips] = useState([]);
  const [selectedPickupTrip, setSelectedPickupTrip] = useState(
    editStudent ? pickupTripId : 0
  );
  const [pickupTripEmpty, setPickupTripEmpty] = useState(false);
  const [pickupTripKey, setPickupTripKey] = useState("pickupTripKey");
  const onPickupTripChange = (event, value) => {
    setSelectedPickupTrip(value.id);
    setPickupTripEmpty(false);
  };

  // Return Trip
  //const [returnTrips, setReturnTrips] = useState([]);
  const [selectedReturnTrip, setSelectedReturnTrip] = useState(
    editStudent ? returnTripId : 0
  );
  const [returnTripEmpty, setReturnTripEmpty] = useState(false);
  const [returnTripKey, setReturnTripKey] = useState("returnTripKey");
  const onReturnTripChange = (event, value) => {
    setSelectedReturnTrip(value.id);
    setReturnTripEmpty(false);
  };

  // Common
  const initialValues = editStudent
    ? {
        firstName: firstName,
        lastName: lastName,
        regNo: regNo,
        guardianId: guardianId,
        pickupTripId: pickupTripId,
        returnTripId: returnTripId,
      }
    : {
        firstName: "",
        lastName: "",
        regNo: "",
        guardianId: 0,
        pickupTripId: 0,
        returnTripId: 0,
      };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    regNo: Yup.string().required("Required"),
  });

  const CREATE_STUDENT_URL = "/api/v1/students/save";
  const UPDATE_STUDENT_URL = "/api/v1/students/";
  const onSubmit = async (values, props) => {
    // No guardian picked
    if (selectedGuardian === 0) {
      setGuardianEmpty(true);
      return;
    }

    // No pick-up trip picked
    if (selectedPickupTrip === 0) {
      setPickupTripEmpty(true);
      return;
    }

    // No return trip picked
    if (selectedReturnTrip === 0) {
      setReturnTripEmpty(true);
      return;
    }

    props.setSubmitting(true);

    let studentData = editStudent
      ? {
          firstName: values.firstName,
          lastName: values.lastName,
          pickupTripId: selectedPickupTrip,
          returnTripId: selectedReturnTrip,
        }
      : {
          firstName: values.firstName,
          lastName: values.lastName,
          regNo: values.regNo,
          guardianId: selectedGuardian,
          pickupTripId: selectedPickupTrip,
          returnTripId: selectedReturnTrip,
        };

    console.log(studentData);

    try {
      const response = editStudent
        ? await axiosPrivate.put(UPDATE_STUDENT_URL + studentId, studentData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
        : await axiosPrivate.post(CREATE_STUDENT_URL, studentData, {
            headers: {
              "Content-Type": "application/json",
            },
          });

      console.log(response.data.message);
      props.setSubmitting(false);
      resetForm(props);
      closeForm(response.data.message);
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      const errorTitle = editStudent
        ? "Failed To Update Student"
        : "Failed To Create Student";
      setConfirmDialog({
        isOpen: true,
        title: errorTitle,
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  return (
    <Grid>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Stack spacing={2} mt={3} direction="column">
              <Field
                as={TextField}
                label="First Name"
                name="firstName"
                placeholder="Enter first name"
                fullWidth
                required
                helperText={<ErrorMessage name="firstName" />}
              />
              <Field
                as={TextField}
                label="Last Name"
                name="lastName"
                placeholder="Enter last name"
                fullWidth
                required
                helperText={<ErrorMessage name="lastName" />}
              />
              <Field
                as={TextField}
                label="Reg Number"
                name="regNo"
                placeholder="Enter registration number"
                fullWidth
                required
                disabled={editStudent}
                helperText={<ErrorMessage name="regNo" />}
              />

              <Autocomplete
                id="guardianId"
                fullWidth
                placeholder="Select guardian"
                label="Guardian"
                defaultValue={guardian}
                onChange={onGuardianChange}
                disabled={editStudent}
                key={guardianKey}
                open={guardianOpen}
                onOpen={() => {
                  setGuardianLoading(true);
                  setGuardianOpen(true);
                }}
                onClose={() => {
                  setGuardianLoading(false);
                  setGuardianOpen(false);
                }}
                isOptionEqualToValue={(guardian, value) =>
                  `${guardian.firstName} ${guardian.lastName} - ${guardian.phone}` ===
                  `${value.firstName} ${value.lastName} - ${value.phone}`
                }
                getOptionLabel={(guardian) =>
                  `${guardian.firstName} ${guardian.lastName}`
                }
                options={guardians}
                loading={guardianLoading}
                loadingText="Loading guardians..."
                noOptionsText="No guardians"
                renderOption={(props, guardian) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {guardian.firstName} {guardian.lastName} - {guardian.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Guardian"
                    name="guardianId"
                    placeholder="Select guardian"
                    error={guardianEmpty}
                    helperText={guardianEmpty ? "Guardian required" : ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {guardianLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />

              <Autocomplete
                disablePortal
                id="pickupTripId"
                label="Pick-Up Trip"
                placeholder="Select pick-up trip"
                defaultValue={pickupTrip}
                onChange={onPickupTripChange}
                isOptionEqualToValue={(pTrip, value) =>
                  pTrip.name === value.name
                }
                getOptionLabel={(pTrip) => pTrip.name}
                getOptionDisabled={(pTrip) => !pTrip.isPickUp}
                key={pickupTripKey}
                fullWidth
                options={pickupTrips}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pick-Up Trip"
                    name="pickupTripId"
                    placeholder="Select pick-up trip"
                    error={pickupTripEmpty}
                    helperText={pickupTripEmpty ? "Pick-up trip required" : ""}
                  />
                )}
              />

              <Autocomplete
                disablePortal
                id="returnTripId"
                label="Return Trip"
                placeholder="Select return trip"
                defaultValue={returnTrip}
                onChange={onReturnTripChange}
                isOptionEqualToValue={(pTrip, value) =>
                  pTrip.name === value.name
                }
                getOptionLabel={(pTrip) => pTrip.name}
                getOptionDisabled={(pTrip) => pTrip.isPickUp}
                key={returnTripKey}
                fullWidth
                options={returnTrips}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Return Trip"
                    name="returnTripId"
                    placeholder="Select return trip"
                    error={returnTripEmpty}
                    helperText={returnTripEmpty ? "Return trip required" : ""}
                  />
                )}
              />
            </Stack>

            <Stack spacing={2} mt={3} direction="row" justifyContent="flex-end">
              <LightTooltip title="Clear form">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 8,
                    width: 120,
                    backgroundColor: "#808080",
                    padding: "7px 14px",
                    fontSize: "14px",
                    color: "white",
                    ":hover": {
                      bgcolor: "#696969",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    resetForm(props);
                  }}
                >
                  Reset
                </Button>
              </LightTooltip>

              <LightTooltip title="Submit details">
                <Button
                  loading={props.isSubmitting}
                  loadingPosition="start"
                  disabled={props.isSubmitting}
                  type="submit"
                  sx={{
                    width: 120,
                  }}
                  variant="solid"
                >
                  {props.isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </LightTooltip>
            </Stack>
          </Form>
        )}
      </Formik>
      {/* </Paper> */}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
};

export default AddStudent;
