import React from "react";
import "../../styles.css";

const Header = () => {
  return (
    <header>
      <h1>Frequently Asked Question(s)</h1>
    </header>
  );
};

export default Header;
