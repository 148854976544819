import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Notification from "../components/Notification";
import ConfirmDialog from "../components/ConfirmDialog";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { LightTooltip } from "../tooltips/ToolTip";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import storage from "../firebase/firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CreateNotification = (props) => {
  const { closeDialog } = props;
  const [guardians, setGuardians] = useState([]);
  const [file, setFile] = useState("");
  var fileUrl = "";
  //const [percent, setPercent] = useState(0);
  const [percent, setPercent] = useState("");
  const axiosPrivate = useAxiosPrivate();

  // Handles input change event and updates state
  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const resetForm = (props) => {
    // clear input fields
    props.resetForm();
    setGuardianKey(new Date());
    setSelectedGuardians([]);
    setGuardianEmpty(false);
  };

  const closeForm = (message) => {
    closeDialog(message);
  };

  // Guardians
  const [guardianOpen, setGuardianOpen] = useState(false);
  const [selectedGuardians, setSelectedGuardians] = useState([]);

  const [guardianLoading, setGuardianLoading] = useState(
    guardianOpen && guardians.length === 0
  );

  const [guardianEmpty, setGuardianEmpty] = useState(false);
  const [guardianKey, setGuardianKey] = useState("guardianKey");
  const GUARDIANS_URL = "/api/v1/users/role/3";
  const onGuardianChange = (event, value) => {
    setSelectedGuardians(value);
    setGuardianEmpty(value.length < 1);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!guardianLoading) {
      return undefined;
    }

    const getGuardians = async () => {
      try {
        const response = await axiosPrivate.get(GUARDIANS_URL, {
          signal: controller.signal,
        });
        isMounted && setGuardians(response.data.data);
        setGuardianLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setGuardianLoading(false);
      }
    };

    getGuardians();

    return () => {
      isMounted = false;
      controller.abort();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [guardianLoading]);

  React.useEffect(() => {
    if (!guardianOpen) {
      setGuardians([]);
    }
  }, [guardianOpen]);

  // Common
  const initialValues = {
    title: "",
    message: "",
    guardianIds: [],
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
  });

  function onSubmit(values, props) {
    // No guardian picked
    if (selectedGuardians.length < 1) {
      setGuardianEmpty(true);
      return;
    }

    props.setSubmitting(true);

    if (!file) {
      // No file selected, just post the notification
      sendNotification(values, props);
    } else {
      // Upload the file first
      const storageRef = ref(storage, `/shule_app/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          setPercent(`Please wait. Uploading file first...${percent}% done`);
        },
        (err) => {
          props.setSubmitting(false);
          console.log(`Error - ${err}`);

          const errorTitle = "Failed To Upload File";
          setConfirmDialog({
            isOpen: true,
            title: errorTitle,
            subTitle: err.message,
            action: "Retry",
            onConfirm: { onSubmit },
          });
        },
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            fileUrl = url;
            // We have the URL, send the notification
            sendNotification(values, props);
          });
        }
      );
    }
  }

  const CREATE_NOTIFICATION = "/api/v1/notification/devices/send";
  const sendNotification = async (values, props) => {
    var guardianIds = [];
    const length = selectedGuardians.length;

    for (let i = 0; i < length; i++) {
      guardianIds.push(selectedGuardians[i].id);
    }

    /* let notificationData = {
      usersIds: guardianIds,
      title: values.title,
      body: values.message,
      clickAction: "OPEN_NOTIFICATIONS_FRAGMENT", // Hard-coded, please sync with backend
      fileName: file.name,
      fileUrl: fileUrl,
    }; */

    let notificationData = !file
      ? {
          // No file selected, just post the notification
          usersIds: guardianIds,
          title: values.title,
          body: values.message,
          clickAction: "OPEN_NOTIFICATIONS_FRAGMENT", // Hard-coded, please sync with backend
        }
      : {
          usersIds: guardianIds,
          title: values.title,
          body: values.message,
          clickAction: "OPEN_NOTIFICATIONS_FRAGMENT", // Hard-coded, please sync with backend
          fileName: file.name,
          fileUrl: fileUrl,
        };

    console.log(notificationData);

    try {
      const response = await axiosPrivate.post(
        CREATE_NOTIFICATION,
        notificationData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data.message);
      props.setSubmitting(false);
      resetForm(props);
      closeForm(response.data.message);
    } catch (error) {
      props.setSubmitting(false);
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      const errorTitle = "Failed To Send Notification";
      setConfirmDialog({
        isOpen: true,
        title: errorTitle,
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  return (
    <Grid>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Stack spacing={2} mt={3} direction="column">
              <Field
                as={TextField}
                label="Title"
                name="title"
                rows={4}
                placeholder="Enter the title"
                inputProps={{ maxLength: 60 }}
                style={{ width: 500 }}
                required
                helperText={<ErrorMessage name="title" />}
              />

              <Field
                as={TextField}
                label="Message"
                name="message"
                multiline
                inputProps={{ maxLength: 400 }}
                rows={5}
                placeholder="Enter the message"
                style={{ width: 500 }}
                required
                helperText={<ErrorMessage name="message" />}
              />

              <Autocomplete
                id="guardianIds"
                multiple
                limitTags={2}
                style={{ width: 500 }}
                label="Guardian(s)"
                placeholder="Select guardian(s)"
                onChange={onGuardianChange}
                key={guardianKey}
                open={guardianOpen}
                onOpen={() => {
                  setGuardianLoading(true);
                  setGuardianOpen(true);
                }}
                onClose={() => {
                  setGuardianLoading(false);
                  setGuardianOpen(false);
                }}
                isOptionEqualToValue={(guardian, value) =>
                  `${guardian.firstName} ${guardian.lastName} - ${guardian.phone}` ===
                  `${value.firstName} ${value.lastName} - ${value.phone}`
                }
                getOptionLabel={(guardian) =>
                  `${guardian.firstName} ${guardian.lastName}`
                }
                options={guardians}
                loading={guardianLoading}
                loadingText="Loading guardians..."
                noOptionsText="No guardians"
                disableCloseOnSelect
                renderOption={(props, guardian, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {guardian.firstName} {guardian.lastName} - {guardian.phone}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Guardian(s)"
                    placeholder="Select guardian(s)"
                    name="guardianIds"
                    error={guardianEmpty}
                    helperText={guardianEmpty ? "Guardian(s) required" : ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {guardianLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />

              <div>
                <input
                  type="file"
                  accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .png, .png, .jpg, .jpeg, .pdf, .pptx, .xlsx"
                  onChange={handleChange}
                />
              </div>
              <Typography
                variant="string"
                gutterBottom
                sx={{ textAlign: "left", mt: 3, mb: 3, color: "#43B02A" }}
              >
                <strong>{percent}</strong>
              </Typography>
            </Stack>

            <Stack spacing={2} mt={3} direction="row" justifyContent="flex-end">
              <LightTooltip title="Clear form">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 8,
                    width: 120,
                    backgroundColor: "#808080",
                    padding: "7px 14px",
                    fontSize: "14px",
                    color: "white",
                    ":hover": {
                      bgcolor: "#696969",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    resetForm(props);
                  }}
                >
                  Reset
                </Button>
              </LightTooltip>
              <LightTooltip title="Send notification">
                <Button
                  loading={props.isSubmitting}
                  loadingPosition="start"
                  disabled={props.isSubmitting}
                  type="submit"
                  sx={{
                    width: 120,
                  }}
                  variant="solid"
                >
                  {props.isSubmitting ? "Sending" : "Send"}
                </Button>
              </LightTooltip>
            </Stack>
          </Form>
        )}
      </Formik>
      {/* </Paper> */}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
};

export default CreateNotification;
