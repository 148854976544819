import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Notification from "../components/Notification";
import ConfirmDialog from "../components/ConfirmDialog";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { LightTooltip } from "../tooltips/ToolTip";
import Box from "@mui/material/Box";

const AddTripType = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const { closeDialog, editTripType, selectedTripType } = props;

  var tripTypeInfo = null;
  var name = null;
  var dayTime = null;
  var tripTypeId = 0;
  var dayTimeId = 0;
  var isPickUp = null;
  if (editTripType) {
    tripTypeInfo = JSON.parse(selectedTripType);
    name = tripTypeInfo.name;
    tripTypeId = tripTypeInfo.id;
    dayTime = tripTypeInfo.dayTime;
    dayTimeId = dayTime.id;
    isPickUp = tripTypeInfo.isPickUp ? options[0] : options[1];
  }

  const resetForm = (props) => {
    // clear input fields
    var now = new Date();
    props.resetForm();
    setSelectedDayTimeKey(now);
    setSelectedDayTime(0);
    setSelectedPickUpOptionKey(now + "a");
    setSelectedPickUpOption(null);
  };

  const closeForm = (message) => {
    closeDialog(message);
  };

  // Day times
  const [dayTimeOpen, setDayTimeOpen] = useState(false);
  const [dayTimes, setDayTimes] = useState([]);
  const [selectedDayTime, setSelectedDayTime] = useState(
    editTripType ? dayTimeId : 0
  );

  const [dayTimeLoading, setDayTimeLoading] = useState(
    dayTimeOpen && dayTimes.length === 0
  );
  const [dayTimeEmpty, setDayTimeEmpty] = useState(false);
  const [selectedDayTimeKey, setSelectedDayTimeKey] = useState("dayTimeKey");
  const DAY_TIMES_URL = "/api/v1/daytimes";
  const handleDayTimeChange = (event, value) => {
    setSelectedDayTime(value.id);
    setDayTimeEmpty(false);
  };

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (!dayTimeLoading) {
      return undefined;
    }

    const getDayTimes = async () => {
      try {
        const response = await axiosPrivate.get(DAY_TIMES_URL, {
          signal: controller.signal,
        });
        isMounted && setDayTimes(response.data.data);
        setDayTimeLoading(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setDayTimeLoading(false);
      }
    };

    getDayTimes();

    return () => {
      isMounted = false;
      controller.abort();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [dayTimeLoading]);

  React.useEffect(() => {
    if (!dayTimeOpen) {
      setDayTimes([]);
    }
  }, [dayTimeOpen]);

  // Is PickUp
  const [selectedPickUpOption, setSelectedPickUpOption] = useState(
    editTripType ? isPickUp.label : null
  );
  const [pickUpOptionsEmpty, setPickUpOptionEmpty] = useState(false);
  const [selectedPickUpOptionKey, setSelectedPickUpOptionKey] =
    useState("pickUpOptionKey");
  const handlePickUpOptionChange = (event, value) => {
    const selected = value === null ? null : value.label;
    setSelectedPickUpOption(selected);
    setPickUpOptionEmpty(false);
  };

  const initialValues = editTripType
    ? {
        name: name,
        dayTimeId: dayTimeId,
        isPickUp: isPickUp,
      }
    : {
        name: "",
        dayTimeId: 0,
        isPickUp: "",
      };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const CREATE_TRIP_TYPE = "/api/v1/triptypes/save";
  const UPDATE_TRIP_TYPE = "/api/v1/triptypes/";
  const onSubmit = async (values, props) => {
    if (selectedDayTime === 0) {
      // No daytime picked
      setDayTimeEmpty(selectedDayTime === 0);
      return;
    }

    // Is Picked not selected
    if (selectedPickUpOption === null) {
      setPickUpOptionEmpty(selectedPickUpOption === null);
      return;
    }

    props.setSubmitting(true);

    // Common
    const tripTypeData = {
      name: values.name,
      dayTimeId: selectedDayTime,
      isPickUp: selectedPickUpOption === "Pick-up",
    };

    try {
      const response = editTripType
        ? await axiosPrivate.put(UPDATE_TRIP_TYPE + tripTypeId, tripTypeData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
        : await axiosPrivate.post(CREATE_TRIP_TYPE, tripTypeData, {
            headers: {
              "Content-Type": "application/json",
            },
          });

      console.log(response.data.message);
      props.setSubmitting(false);
      resetForm(props);
      closeForm(response.data.message);
    } catch (error) {
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      const errorTitle = editTripType
        ? "Failed To Update Trip-Type"
        : "Failed To Create Trip-Type";
      setConfirmDialog({
        isOpen: true,
        title: errorTitle,
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  return (
    <Grid>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Stack spacing={2} mt={3} direction="column">
              <Field
                as={TextField}
                label="Trip-Type Name"
                name="name"
                placeholder="Enter Trip-Type name"
                fullWidth
                required
                helperText={<ErrorMessage name="name" />}
              />

              <Autocomplete
                id="dayTimeId"
                fullWidth
                defaultValue={dayTime}
                onChange={handleDayTimeChange}
                key={selectedDayTimeKey}
                open={dayTimeOpen}
                onOpen={() => {
                  setDayTimeLoading(true);
                  setDayTimeOpen(true);
                }}
                onClose={() => {
                  setDayTimeLoading(false);
                  setDayTimeOpen(false);
                }}
                isOptionEqualToValue={(dayTime, value) =>
                  dayTime.name === value.name
                }
                getOptionLabel={(dayTime) => dayTime.name}
                options={dayTimes}
                loading={dayTimeLoading}
                loadingText="Loading trip-types..."
                noOptionsText="No trip-types"
                renderOption={(props, dayTime) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {dayTime.name} at {dayTime.time}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Day-Time"
                    name="dayTimeId"
                    error={dayTimeEmpty}
                    helperText={dayTimeEmpty ? "Day-Time required" : ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {dayTimeLoading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />

              <Autocomplete
                disablePortal
                id="isPickUp"
                defaultValue={isPickUp}
                onChange={handlePickUpOptionChange}
                key={selectedPickUpOptionKey}
                fullWidth
                options={options}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nature of trip"
                    placeholder="Nature of trip"
                    error={pickUpOptionsEmpty}
                    helperText={
                      pickUpOptionsEmpty ? "Nature of Trip required" : ""
                    }
                  />
                )}
              />
            </Stack>

            <Stack spacing={2} mt={3} direction="row" justifyContent="flex-end">
              <LightTooltip title="Clear form">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 8,
                    width: 120,
                    backgroundColor: "#808080",
                    padding: "7px 14px",
                    fontSize: "14px",
                    color: "white",
                    ":hover": {
                      bgcolor: "#696969",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    resetForm(props);
                  }}
                >
                  Reset
                </Button>
              </LightTooltip>

              <LightTooltip title="Submit details">
                <Button
                  loading={props.isSubmitting}
                  loadingPosition="start"
                  disabled={props.isSubmitting}
                  type="submit"
                  sx={{
                    width: 120,
                  }}
                  variant="solid"
                >
                  {props.isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </LightTooltip>
            </Stack>
          </Form>
        )}
      </Formik>
      {/* </Paper> */}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
};

export default AddTripType;

const options = [{ label: "Pick-up" }, { label: "Drop-off" }];
