import { React, useState, useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import RDataGrid from "./reusables/RDataGrid";
import PageLoader from "./reusables/PageLoader";

const CarDrivers = (props) => {
  const { carId } = props;
  const [isFetchingDrivers, setIsFetchingDrivers] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const CAR_DRIVERS_URL = "api/v1/cars/drivers/";
  const axiosPrivate = useAxiosPrivate();

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  useEffect(() => {
    if (!isFetchingDrivers) {
      setIsFetchingDrivers(true);
    }

    let isMounted = true;
    const controller = new AbortController();

    const getDrivers = async () => {
      try {
        const response = await axiosPrivate.get(CAR_DRIVERS_URL + carId, {
          signal: controller.signal,
        });
        isMounted && setDrivers(response.data.data);
        setIsFetchingDrivers(false);
      } catch (error) {
        var errorMessage = "";
        if (error.response) {
          // The client was given an error response (5xx, 4xx)
          const errorData = error.response.data;
          errorMessage = errorData.message;
        } else if (error.request) {
          // The client never received a response, and the request was never left
          errorMessage = "No server response";
        } else {
          // Anything else
          errorMessage = "Unexpected error occurred";
        }
        console.log(errorMessage);
        setIsFetchingDrivers(false);
      }
    };

    getDrivers();

    return () => {
      isMounted = false;
      controller.abort();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const columns = useMemo(
    () => [
      { field: "id", headerName: "Id", width: 60 },
      {
        field: "name",
        headerName: "Name",
        width: 150,
        renderCell: function (params) {
          const driver = params.row;
          const name = `${driver.firstName} ${driver.lastName}`;
          return name;
        },
      },
      {
        field: "email",
        headerName: "Email",
        width: 200,
      },
      {
        field: "phone",
        headerName: "Phone",
        width: 140,
      },
      {
        field: "route",
        headerName: "Route",
        width: 230,
        renderCell: function (params) {
          const route = params.row.route.name;
          return route;
        },
      },
    ],
    []
  );

  return isFetchingDrivers ? (
    <PageLoader title="Loading drivers..." />
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      {drivers?.length ? (
        <Box
          sx={{
            height: 560,
            width: "100%",
          }}
        >
          <Box sx={{ width: 798, margin: "0 auto 16px", height: 560 }}>
            <RDataGrid columns={columns} rows={drivers} rowHeight={63} />
          </Box>
        </Box>
      ) : (
        <div style={divStyle}>
          <Typography
            variant="h6"
            component="h6"
            sx={{ textAlign: "center", mt: 3, mb: 3 }}
          >
            No drivers found
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default CarDrivers;
