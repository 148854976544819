import { Avatar, Grid, Paper, TextField, Typography } from "@mui/material";
import Button from "@mui/joy/Button";
import { React, useEffect, useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Axios from "../api/Axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Notification from "./Notification";
import ConfirmDialog from "./ConfirmDialog";
import useAuth from "../hooks/useAuth";
import background from "../login_bg.jpg";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Login = () => {
  const { setAuth, persist, setPersist } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const paperStyle = {
    padding: 20,
    height: "70vh",
    width: 280,
    margin: "20px auto",
  };
  const imgStyle = {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
  };

  const avatarStyle = { backgroundColor: "green" };
  const buttonStyle = { margin: "16px 0" };
  const usernameStyle = { margin: "10px 0" };
  const passwordStyle = { margin: "20px 0" };
  const typographyStyle = { margin: "10px 0" };
  const titleStyle = { color: "#8d4004" };
  const initialValues = {
    username: "",
    password: "",
  };
  const validationSchema = Yup.object().shape({
    // username: Yup.string().email('Please enter valid email').required('Required'),
    username: Yup.string()
      .required("Required")
      .matches(/^[0-9]\d{9}$/, {
        message: "Please enter valid phone number",
        excludeEmptyString: false,
      }),
    password: Yup.string().required("Required"),
  });
  const LOGIN_URL = "/api/v1/login";
  const onSubmit = async (values, props) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    const username = values.username;
    const password = values.password;

    try {
      const response = await Axios.post(
        LOGIN_URL,
        { username, password },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      //console.log(from);
      //console.log(response.data.message);
      const id = response.data.data.user.id;
      const accessToken = response.data.data.access_token;
      const refreshToken = response.data.data.refresh_token;
      const roleObjects = response.data.data.user.roles;

      var roles = [];
      for (var i = 0; i < roleObjects.length; i++) {
        roles.push(roleObjects[i].id);
      }

      //console.log(roles);

      setAuth({ id, username, password, roles, accessToken, refreshToken });

      // clear input fields
      props.resetForm();
      props.setSubmitting(false);

      // Navigate home
      // navigate('sidenav')

      navigate(from, { replace: true });

      /* setNotify({
                isOpen: true,
                message: response.data.message,
                type: 'success'
            }) */
    } catch (error) {
      //console.log(from);
      var errorMessage = "";
      if (error.response) {
        // The client was given an error response (5xx, 4xx)
        const errorData = error.response.data;
        const statusCode = errorData.httpStatusCode;
        if (statusCode === 412) {
          navigate("/new-password", {
            replace: true,
            state: {
              password: password,
              username: username,
            },
          });
        }
        errorMessage = errorData.message;
      } else if (error.request) {
        // The client never received a response, and the request was never left
        errorMessage = "No server response";
      } else {
        // Anything else
        errorMessage = "Unexpected error occurred";
      }
      setConfirmDialog({
        isOpen: true,
        title: "Login Failed",
        subTitle: errorMessage,
        action: "Retry",
        onConfirm: { onSubmit },
      });
    }
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  return (
    <>
      <div style={imgStyle}>
        <Grid container justify="center">
          <Paper elevation={10} style={paperStyle}>
            <Grid align="center">
              <Avatar style={avatarStyle}>
                <LockOutlinedIcon></LockOutlinedIcon>
              </Avatar>
              <h3 style={titleStyle}>School Transport System</h3>
              <h2>Sign In</h2>
            </Grid>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(props) => (
                <Form>
                  <Field
                    as={TextField}
                    label="Phone number"
                    name="username"
                    placeholder="E.g. 0722..."
                    style={usernameStyle}
                    fullWidth
                    required
                    helperText={<ErrorMessage name="username" />}
                  />

                  <Field
                    as={TextField}
                    label="Password"
                    name="password"
                    placeholder="Enter password"
                    style={passwordStyle}
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    required
                    helperText={<ErrorMessage name="password" />}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <FormControlLabel
                    control={<Checkbox name="persist" checked={persist} />}
                    name="persist"
                    onChange={togglePersist}
                    label="Trust This Device"
                  />

                  <Button
                    type="submit"
                    color="primary"
                    fullWidth
                    loading={props.isSubmitting}
                    loadingPosition="start"
                    variant="solid"
                    style={buttonStyle}
                  >
                    {props.isSubmitting ? "Processing" : "Sign In"}
                  </Button>
                </Form>
              )}
            </Formik>
            <Typography align="center" style={typographyStyle}>
              {/* Endpoint to route to Forgot password component */}
              <Link to="/otp">Forgot password?</Link>
            </Typography>
          </Paper>

          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </Grid>
      </div>
    </>
  );
};

export default Login;
