import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBzKt0chVg7-qwoA_I_bIYsV9YI1mXaNKA",
  authDomain: "tester-61999.firebaseapp.com",
  databaseURL: "https://tester-61999.firebaseio.com",
  projectId: "tester-61999",
  storageBucket: "tester-61999.appspot.com",
  messagingSenderId: "996196221528",
  appId: "1:996196221528:web:db2f754232e69c4e2f002d",
  measurementId: "G-MSX8WL8FTN",
};

// Initialize Firebase Stirage
const firebaseApp = initializeApp(firebaseConfig);
const storage = getStorage(firebaseApp);
export default storage;
